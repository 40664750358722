import Popover from '@material-ui/core/Popover';
import { useState, useEffect } from 'react';
import css from './style.module.scss';

export default function LongPressTitleHandler() {
  const [target, setTarget] = useState<HTMLElement>(null);

  useEffect(() => {
    let timeout;
    const mouseDown = e => {
      const labelledItem = e.target.closest('[title]');

      if (!labelledItem) {
        return;
      }

      timeout = setTimeout(() => {
        setTarget(labelledItem);
      }, 500);
    };

    const mouseUp = () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setTarget(null);
      timeout = null;
    };

    document.addEventListener('touchstart', mouseDown, { passive: true });
    document.addEventListener('touchend', mouseUp, { passive: true });

    return () => {
      document.removeEventListener('touchstart', mouseDown, { passive: true });
      document.removeEventListener('touchend', mouseUp, { passive: true });
    };
  }, []);

  if (target == null) {
    return null;
  }

  return (
    <Popover
      aria-hidden="true"
      id="title-popover"
      className={css.popover}
      classes={{
        paper: css.paper,
      }}
      open
      anchorEl={target}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={() => setTarget(null)}
      disableRestoreFocus
    >
      {target.title}
    </Popover>
  );
}
