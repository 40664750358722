import { CombinedError } from '@urql/core';
import type { ErrorInfo } from 'react';
import { defineMessage } from 'react-intl';
import { InternalServerError } from '../../api/util';
import type { Nullish } from '../../shared-library/types';
import { mailto } from '../../utils/url';

const crashEmailTitle = defineMessage({ defaultMessage: 'Error encountered on MyMedicoach' });

export const SUPPORT_EMAIL = 'jestevens@mymedicoach.com';

export type TCrashReportData = {
  reportId?: Nullish<string>,
  error?: Nullish<Error>,
  errorInfo?: Nullish<ErrorInfo>,
  systemInfo: string,
};

export function generateCrashReport({ reportId, error, errorInfo, systemInfo }: TCrashReportData) {
  const reportIds: string[] = [];
  if (reportId) {
    reportIds.push(`${reportId} (client)`);
  }

  if (error instanceof InternalServerError && error.reportId) {
    reportIds.push(`${error.reportId} (server)`);
  }

  if (error instanceof CombinedError) {
    for (const errorPart of error.graphQLErrors) {
      if (errorPart.originalError?.internalErrorId) {
        reportIds.push(`${errorPart.originalError.internalErrorId} (server)`);
      }
    }
  }

  return `${reportIds.length > 0 ? `ID: ${reportIds.join(', ')}` : ''}

${error ? stringifyError(error) : ''}
${errorInfo ? `Info:${errorInfo.componentStack}` : ''}
${systemInfo}`.trim();
}

export function generateSupportEmail(intl, options: TCrashReportData) {
  let subject = `${intl.formatMessage(crashEmailTitle)}`;
  if (options.reportId) {
    subject += ` (${options.reportId})`;
  }

  const body = `

---
${generateCrashReport(options)}`;

  return mailto(SUPPORT_EMAIL, { subject, body });
}

function stringifyError(error: Error) {
  return `${error.message}
${error.stack ?? ''}`.trim();
}
