/**
 * @module pwa
 *
 * This module handles the logic necessary for progressive web apps
 * such as displaying the install prompt.
 */
import { useEffect, useState } from 'react';
import { createListener } from '../utils/utils';

let appInstallEvent = null;
const { on: onAppCanBeInstalled, off: offAppCanBeInstalled, dispatch } = createListener();

/**
 * Install event handlers to detect when the app will be allowed to be installed
 * by the user-agent.
 *
 * @returns success
 */
export function hookPwa() {
  if (typeof window === 'undefined' || !window.addEventListener) {
    return false;
  }

  window.addEventListener('beforeinstallprompt', e => {
    appInstallEvent = e;

    dispatch(true);

    // remove hook once user has made its choice
    appInstallEvent.userChoice.then(() => {
      appInstallEvent = null;
      dispatch(false);
    });

    // don't let the browser decide to prompt for app install
    e.preventDefault();

    return false;
  });

  return true;
}

/**
 * returns whether the app can currently be installed.
 *
 * @returns Whether the app is installable
 */
export function canAppBeInstalled(): boolean {
  return Boolean(appInstallEvent);
}

/**
 * Request the user-agent to prompt the install banner to the user.
 *
 * @return A promise that resolves with the user choice
 * Throws if canAppBeInstalled returns false
 */
export async function promptAppInstallRequest(): Promise<string> {
  if (!canAppBeInstalled()) {
    throw new Error('Cannot prompt app install');
  }

  return appInstallEvent.prompt();
}

export function useCanAppBeInstalled() {
  const [canBeInstalled, setCanBeInstalled] = useState(canAppBeInstalled());

  useEffect(() => {
    return onAppCanBeInstalled(newValue => {
      setCanBeInstalled(newValue);
    });
  }, []);

  return canBeInstalled;
}

export { onAppCanBeInstalled, offAppCanBeInstalled };
