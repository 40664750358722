import type { History as RouterHistory } from 'history';
import bind from 'lodash-decorators/bind';
import type { ErrorInfo, ReactNode } from 'react';
import * as React from 'react';
import { PureComponent } from 'react';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import type { TViewerFragment } from '../../api/graphql-typings.js';
import { getRaven } from '../../services/error-reporting';
import { ExerciseCategory } from '../../shared-library/exercise-categories';
import { RightAlignedActions } from '../Actions/right-aligned-actions.js';
import { ContactSupportButton, CrashReport } from '../ApiErrorDisplay';
import Button2 from '../Button2';
import { BodyColor } from '../Layout/body-color.js';
import messages from './messages';
import styles from './styles.module.scss';

type Props = {
  children: ReactNode,
  viewer: TViewerFragment | null,
  history: RouterHistory,
  intl: IntlShape,
};

type State = {
  hasError: boolean,
  errorReportId: string | null,
  crashReport: null | {
    error: Error,
    errorInfo: ErrorInfo,
  },
};

class CrashHandler extends PureComponent<Props, State> {

  state: State = {
    hasError: false,
    errorReportId: null,
    crashReport: null,
  };

  componentDidCatch(error, errorInfo) {
    console.error('React App crashed');
    console.error(error);
    console.error(errorInfo);

    this.setState({ hasError: true });

    console.info('Attempting to send crash report');
    try {
      const Sentry = getRaven();

      if (!Sentry) {
        this.setState({
          crashReport: { error, errorInfo },
        });

        return;
      }

      const user = this.props.viewer;

      Sentry.withScope(scope => {
        if (user) {
          scope.setUser({
            email: user.email,
            username: user.slug,
            id: user.id,
          });
        }

        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ errorReportId: eventId });
      });
    } catch (error_) {
      console.error(error_);
    }
  }

  @bind()
  resetApp() {
    this.props.history.push('/');
    window.location.reload();
  }

  renderCrash() {
    const crashId = this.state.errorReportId;

    return (
      <div className={styles.crash}>
        <BodyColor background="color-dark" theme={ExerciseCategory.physiotherapy} weight={1} />
        <div>
          <h1><FormattedMessage {...messages.title} /></h1>
          <p>
            <FormattedMessage {...messages.sorry} values={{ br: <br /> }} />
          </p>

          <div className={styles.reportIdGroup}>
            {crashId == null ? (
              <>
                <p className={styles.reportId}>
                  <FormattedMessage
                    {...messages.sentryLoadError}
                    values={{
                      br: <br />,
                    }}
                  />
                </p>

                <CrashReport
                  error={this.state.crashReport?.error}
                  errorInfo={this.state.crashReport?.errorInfo}
                  reportId={this.state.errorReportId}
                  includeViewer={this.props.viewer || false}
                />
                <RightAlignedActions spaced>
                  <ContactSupportButton
                    variant="outlined"
                    size="large"
                    error={this.state.crashReport?.error}
                    errorInfo={this.state.crashReport?.errorInfo}
                    reportId={this.state.errorReportId}
                    includeViewer={this.props.viewer || false}
                  />
                  <Button2 onClick={this.resetApp} variant="outlined" size="large">
                    <FormattedMessage {...messages.retryBtn} />
                  </Button2>
                </RightAlignedActions>
              </>
            ) : (
              <>
                <label className={styles.reportId}>
                  <FormattedMessage
                    {...messages.reportId}
                    values={{
                      reportId: <input type="text" readOnly value={crashId} />,
                    }}
                  />
                </label>
                <p className={styles.supportInfo}><FormattedMessage {...messages.infoSupport} /></p>

                <RightAlignedActions spaced>
                  <Button2 onClick={this.resetApp} variant="outlined" size="large">
                    <FormattedMessage {...messages.retryBtn} />
                  </Button2>
                  <ContactSupportButton
                    variant="outlined"
                    size="large"
                    error={this.state.crashReport?.error}
                    errorInfo={this.state.crashReport?.errorInfo}
                    reportId={this.state.errorReportId}
                    includeViewer={this.props.viewer || false}
                  />
                </RightAlignedActions>
              </>
            )}
          </div>

        </div>
      </div>
    );
  }

  render() {
    return this.state.hasError ? this.renderCrash() : this.props.children;
  }
}

export default withRouter(injectIntl(CrashHandler));
