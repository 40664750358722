import classnames from 'classnames';
import * as React from 'react';
import { RightAlignedActions } from '../Actions/right-aligned-actions.js';
import { Card } from '../Card';
import css from './styles.module.scss';

type Props = {
  className?: string,
  children: React.ReactNode,
  actions?: React.ReactNode,
  inset?: boolean,
  variant?: 'light' | 'dark',
};

export default function NoData(props: Props) {

  return (
    <div
      className={classnames(props.className, css.emptyContainer, {
        [css.light]: props.variant === 'light',
      })}
    >
      <Card>
        <div className={css.wysiwyg}>
          {props.children}
        </div>
        {props.inset && props.actions && (
          <RightAlignedActions spaced>
            {props.actions}
          </RightAlignedActions>
        )}
      </Card>
      {!props.inset ? React.Children.map(props.actions, child => {
        return <div className={css.action}>{child}</div>;
      }) : null}
    </div>
  );
}
