import classnames from 'classnames';
import PropTypes from 'prop-types';
import css from './styles.module.scss';

type Props = {
  className?: string,
  size?: number,
  inheritColor?: boolean,
};

export default function Loading(props: Props) {

  return (
    <div className={classnames(props.className, css.container, props.inheritColor && css.inheritColor)}>
      <svg className={css.spinner} width={`${props.size}px`} height={`${props.size}px`} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle className={css.path} fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
  );
}

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Loading.defaultProps = {
  size: 64,
};
