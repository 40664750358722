import type { ReactNode } from 'react';
import {
  createContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useHistory } from 'react-router-dom';
import type { TViewerFragment } from '../api/graphql-typings.js';
import { useViewerQuery } from '../api/graphql-typings.js';
import { getAuthToken, onTokenChange } from '../api/token';
import { isLoadedUrql } from '../api/urql/urql-utils.js';
import { onAuthError } from '../api/util';
import { assert } from '../shared-library/assert';
import { createTrappedCallable } from '../utils/dev-utils';
import { LOGOUT_PATH } from '../views/Logout/logout.loadable';

export type IViewerContext = {
  token: string | null,
  viewer: TViewerFragment | null,
  isSignedIn: boolean,
  loading: boolean,
  refreshing: boolean,
  signOut(): void,
  refresh(): Promise<TViewerFragment | null>,
  error?: Error,
};

export const ViewerContext = createContext<IViewerContext>(createTrappedCallable('ViewerProvider'));

export function useAuthToken() {
  const [token, setToken] = useState(getAuthToken());

  useEffect(() => {
    return onTokenChange(() => {
      setToken(getAuthToken());
    });
  });

  return token;
}

type Props = {
  onViewerChange(viewer: TViewerFragment | null): void,
  children: ReactNode,
};

export function ViewerProvider(props: Props) {
  const token = useAuthToken();

  // urql does not reset all queries when its client is changed.
  // this forces the viewer context to be reset. It also resets the entire tree, which is unfortunate.
  return <KeyedViewerProvider key={token ?? ''} {...props} token={token} />;
}

function KeyedViewerProvider(props: Props & { token: string | null }) {
  const { token } = props;
  const viewerUrql = useViewerQuery();

  const { revalidate, error, fetching } = viewerUrql;
  const viewer = viewerUrql.data?.user ?? null;
  const liveViewerRef = useRef<TViewerFragment | null>(viewer);
  liveViewerRef.current = viewer;

  const loaded = isLoadedUrql(viewerUrql);

  const history = useHistory();
  const signOut = useCallback(() => {
    history.push(LOGOUT_PATH);
  }, [history]);

  useEffect(() => {
    return onAuthError(() => signOut());
  }, [signOut]);

  const onViewerChange = props.onViewerChange;
  useEffect(() => {
    onViewerChange(viewer ?? null);
  }, [viewer, onViewerChange]);

  const context = useMemo(() => {

    const out: IViewerContext = {
      token,
      viewer,
      isSignedIn: token != null && viewer != null,
      signOut,
      async refresh(): Promise<TViewerFragment | null> {
        await revalidate();

        return liveViewerRef.current ?? null;
      },
      refreshing: fetching,
      loading: token != null && !loaded,
      error,
    };

    return out;
  }, [token, viewer, signOut, revalidate, loaded, error, fetching]);

  return (
    <ViewerContext.Provider value={context}>
      {props.children}
    </ViewerContext.Provider>
  );
}

export function useViewer(): IViewerContext {
  return useContext(ViewerContext);
}

type TEnsuredViewerContext = Omit<IViewerContext, 'token' | 'viewer' | 'isSignedIn'> & {
  token: string,
  viewer: TViewerFragment,
  isSignedIn: true,
};

export function useViewerEnsured(): TEnsuredViewerContext {
  const context = useContext(ViewerContext);

  assert(context.viewer != null);
  assert(context.token != null);

  // @ts-expect-error
  return context;
}
