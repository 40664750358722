import classnames from 'classnames';
import type { LocationDescriptor } from 'history';
import * as React from 'react';
import AnyLink from '../../../navigation-lib/any-link';
import type { Action } from '../../ActionMenu';
import ButtonArea from '../../Form/ButtonArea';
import MoreMenu from '../../MoreMenu';
import css from './styles.module.scss';

type Props = {
  className?: string,
  element?: React.ElementType,
  title: React.ReactNode,
  subtitle?: React.ReactNode,
  children?: React.ReactNode,
  leftIcon?: React.ReactNode,
  leftIconText?: React.ReactNode,
  rightIcon?: React.ReactNode,
  href?: LocationDescriptor,
  hrefComponent?: React.ElementType,
  replace?: boolean,
  actions?: Action[] | null,
  onClick?(e: React.SyntheticEvent): void,
  active?: boolean,
  badge?: number,
  invertedColorScheme?: boolean,

  /**
   * The list item touches the border of the screen
   * Setting this to true will add extra padding on devices that require it
   * such as the iphone X due to its notch.
   */
  touchesScreenBorder?: boolean,
};

export default function ListItem(rawProps: Props) {
  const {
    touchesScreenBorder,
    className,
    title,
    subtitle,
    leftIcon,
    leftIconText,
    rightIcon,
    href,
    hrefComponent = AnyLink,
    replace,
    element: Element = 'li',
    actions,
    onClick,
    children,
    active,
    invertedColorScheme,
    ...props
  } = rawProps;

  const Wrapper = (href || onClick) ? ButtonArea : 'div';
  const InteractiveComponent = href ? hrefComponent
    : onClick ? 'button'
    : undefined;

  const rightIconWrapper = rightIcon ? <span className={css.icon}>{rightIcon}</span> : null;

  const hasActions = actions && actions.length > 0;

  return (
    <Element
      {...props}
      className={classnames(
        className,
        css.listItem,
        {
          [css.touchesScreenBorder]: touchesScreenBorder,
          [css.active]: active,
          [css.invertedScheme]: invertedColorScheme,
          [css.hasActions]: hasActions,
        },
      )}
    >
      <Wrapper
        className={css.interactiveWrapper}
        component={InteractiveComponent}
        to={href}
        onClick={onClick}
        replace={replace}
      >
        {leftIcon && (
          <span className={classnames(css.icon, css.iconLeft)}>
            {leftIcon}
            {leftIconText && (
              <p className={css.iconText}>{leftIconText}</p>
            )}
          </span>
        )}
        <div className={css.text}>
          {title && (
            <p className={css.title}>
              {title}
              {subtitle && (
                <small className={css.subtitle}>{subtitle}</small>
              )}
            </p>
          )}
          {children}
        </div>
        {rightIconWrapper}
      </Wrapper>
      {actions && actions.length > 0 && (
        <div className={css.actionSection}>
          <MoreMenu actions={actions} className={css.moreBtn} />
        </div>
      )}
    </Element>
  );
}

ListItem.defaultProps = {
  element: 'li',
};
