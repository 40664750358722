import type { ComponentProps, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForceRefresh } from '../../hooks/use-force-refresh';
import { createTrappedCallable } from '../../utils/dev-utils';

type Props = {
  children: ReactNode,
};

type TUndo = () => void;

type TWeightedHelmetContext = (val: HelmetProps) => TUndo;

const WeightedHelmetContext = createContext<TWeightedHelmetContext>(createTrappedCallable());

export function WeightedHelmetProvider(props: Props) {
  const forceRefresh = useForceRefresh();
  const helmets = useRef<Set<HelmetProps>>(new Set());

  const add = useCallback(val => {
    helmets.current.add(val);
    forceRefresh();

    return () => {
      helmets.current.delete(val);
      forceRefresh();
    };
  }, [forceRefresh]);

  // higher weight last, so they override previous values
  const out = [...helmets.current.values()].sort(helmetComparator);

  return (
    <WeightedHelmetContext.Provider value={add}>
      {out.map((val, i) => <Helmet key={i} {...val} />)}
      {props.children}
    </WeightedHelmetContext.Provider>
  );
}

function helmetComparator(a: HelmetProps, b: HelmetProps) {
  return a.weight - b.weight;
}

type HelmetProps = ComponentProps<typeof Helmet> & { weight: number };

export function WeightedHelmet(props: HelmetProps) {
  const add = useContext(WeightedHelmetContext);

  useEffect(() => {
    return add(props);
  }, [add, props]);

  return null;
}
