import isPlainObject from 'lodash/isPlainObject';
import { toArray } from '../shared-library/array-utils';

type ObjKey = number | string | symbol;

export function immutSetVal(object: any, keyPath: ObjKey | ObjKey[], newValue: any | ((oldValue: any) => any)) {
  keyPath = toArray(keyPath);

  if (keyPath.length === 0) {
    if (typeof newValue === 'function') {
      return newValue(object);
    }

    return newValue;
  }

  const isArray = Array.isArray(object);

  if (!isArray && !isPlainObject(object)) {
    throw new Error('immutSetVal: first argument must be an object or an array');
  }

  const [key, ...subKeys] = keyPath;

  if (isArray) {
    if (typeof key !== 'number') {
      throw new TypeError('immutSetValue: first argument is an array but key in keyPath is not a number');
    }

    const clone = [...object];

    clone[key] = immutSetVal(clone[key], subKeys, newValue);

    return clone;
  }

  return {
    ...object,
    [key]: immutSetVal(object[key], subKeys, newValue),
  };
}
