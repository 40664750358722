import type { SwrOptions } from './swr/swr';
import { useQuery } from './swr/swr';
import type { TRestUser } from './users';
import {
  callApi,
  getDataOrThrow,
} from './util';

export async function invitePatientForConversation(email: string): Promise<TRestUser> {
  return getDataOrThrow(callApi.post('/conversations', {
    body: { email },
  }));
}

export type TBadgesBody = { unreadConversations: number };

export function useBadgeCounts(viewerAuthToken: string, swrOptions?: SwrOptions<TBadgesBody>) {
  return useQuery<TBadgesBody, Error>({
    key: ['/badges', viewerAuthToken],
    options: swrOptions,
  });
}
