import type { ForwardedRef, MutableRefObject, PropsWithChildren, ReactElement, ReactNode } from 'react';
import React from 'react';
import type { MessageDescriptor } from 'react-intl';
import { isObject } from '../shared-library/object-utils';

/**
 * @link {https://reactjs.org/docs/higher-order-components.html#convention-wrap-the-display-name-for-easy-debugging}
 */
export function getComponentName(component: React.ComponentType<any>): string {
  return component.displayName || component.name || 'Component';
}

export function nl2br(...nodes: ReactNode[]): ReactNode[] {
  const newNodes: ReactNode[] = [];

  let i = 0;
  for (const node of nodes) {
    if (typeof node !== 'string') {
      newNodes.push(node);
      continue;
    }

    for (const line of node.split('\n')) {
      newNodes.push(
        line,
        <br key={`br${i++}`} />,
      );
    }

    // remove last BR
    newNodes.pop();
  }

  return newNodes;
}

export function isSyntheticEvent(val: any): val is React.SyntheticEvent {
  return Boolean(val.persist && val.currentTarget);
}

export function isMessageDescriptor(input: any): input is MessageDescriptor {
  // @ts-expect-error
  return isObject(input) && input.id != null;
}

export function isMessageDescriptorMap(input: any): input is { [key: string]: MessageDescriptor } {
  if (!isObject(input)) {
    return false;
  }

  return isMessageDescriptor(input[Object.keys(input)[0]]);
}

export function forwardRefEnsure<T, P = {}>(callback: TEnsuredForwardRefRenderFunction<T, P>) {
  return React.forwardRef<T, P>((props, inputRef: ForwardedRef<T>) => {
    if (typeof inputRef !== 'object' && inputRef != null) {
      throw new Error('This component only accepts object refs');
    }

    const alternateRef = React.useRef();

    // @ts-expect-error
    const theRef: MutableRefObject<T> = inputRef || alternateRef;

    return callback(props, theRef);
  });
}

export interface TEnsuredForwardRefRenderFunction<T, P = {}> {
  (props: PropsWithChildren<P>, ref: MutableRefObject<T>): ReactElement | null;
  displayName?: string;
  // explicit rejected with `never` required due to
  // https://github.com/microsoft/TypeScript/issues/36826
  /**
   * defaultProps are not supported on render functions
   */
  defaultProps?: never;
  /**
   * propTypes are not supported on render functions
   */
  propTypes?: never;
}
