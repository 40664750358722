import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import classes from 'classnames';
import type { ComponentProps, ForwardedRef, ReactNode } from 'react';
import { forwardRef } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { Nullish } from '../../../shared-library/types';
import type { Action } from '../../ActionMenu';
import type { BackButtonDestination } from '../../BackButton';
import { BackButton } from '../../BackButton';
import { getSectionClasses } from '../../Layout/body-color.js';
import { ActionList } from '../../MoreMenu';
import { OverflowIndicator } from '../../OverflowIndicator';
import Dialog from '../Dialog';
import css from './style.module.scss';

const messages = defineMessages({
  close: { defaultMessage: 'Close Dialog' },
});

type Props = {
  actions?: Nullish<Action[]>,
  title?: ReactNode,
  subtitle?: ReactNode,
  children?: ReactNode,
  onRequestClose: ComponentProps<typeof Dialog>['onRequestClose'],
  open: ComponentProps<typeof Dialog>['open'],
  backButton?: BackButtonDestination | null,
  contained?: boolean,
  className?: string,
  classes?: {
    backdrop?: string,
    body?: string,
  },
};

const BlueModal = forwardRef((props: Props, ref: ForwardedRef<HTMLDialogElement>) => {
  const intl = useIntl();

  const hasActions = (props.actions?.length ?? 0) > 0;
  const hasBack = Boolean(props.backButton) || props.onRequestClose !== null;

  return (
    <Dialog
      ref={ref}
      open={props.open}
      onRequestClose={props.onRequestClose}
      className={classes(
        css.backdrop,
        props.classes?.backdrop,
        props.onRequestClose === null && css.notClosable,
      )}
      contentsClassName={classes(css.modal, props.className, props.contained && css.contained, getSectionClasses('white'))}
    >
      {(props.title || hasActions || hasBack) && (
        <div
          className={classes(
            css.header,
            props.title && css.hasTitle,
            hasActions && css.hasActions,
            hasBack && css.hasBack,
          )}
        >
          {props.backButton ? (
            <BackButton defaultBackPath={props.backButton} />
          ) : props.onRequestClose !== null ? (
            <IconButton onClick={props.onRequestClose} title={intl.formatMessage(messages.close)}>
              <CloseIcon />
            </IconButton>
          ) : null}

          {props.title && (
            <h1 className={css.title}>
              {props.title}
              {props.subtitle && <small>{props.subtitle}</small>}
            </h1>
          )}

          <ActionList actions={props.actions} className={css.actionsMenu} />
        </div>
      )}
      <div className={classes(css.body, props.title && css.hasTitle, props.classes?.body)}>
        {props.children}
      </div>
    </Dialog>
  );
});

export default BlueModal;

export function BlueModalRestrictedContent(props) {
  return (
    <OverflowIndicator>
      {ref => (
        <div ref={ref} className={css.blueModalRestrictedContent}>
          {props.children}
        </div>
      )}
    </OverflowIndicator>
  );
}
