import classNames from 'classnames';
import type { ReactNode } from 'react';
import { ExerciseCategory } from '../../shared-library/exercise-categories';
import { useIsMultiPageView } from '../Layout/MultiPageView';
import { BodyColor, getSectionClasses } from '../Layout/body-color.js';
import SiteLogo from '../SiteLogo';
import css from './error-display.module.scss';

type Props = {
  children: ReactNode,
};

export function ErrorDisplay(props: Props) {
  const isMultiPageView = useIsMultiPageView();

  const pageColor = isMultiPageView ? 'white' : 'color-dark';

  return (
    <div className={classNames(css.error, getSectionClasses(pageColor, ExerciseCategory.physiotherapy))}>
      <BodyColor background={pageColor} />
      <SiteLogo className={css.errorLogo} style={isMultiPageView ? 'color' : 'white'} />
      <div className={classNames(css.errorContents, getSectionClasses('white', ExerciseCategory.physiotherapy))}>
        {props.children}
      </div>
    </div>
  );
}
