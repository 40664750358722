import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';
import { AppSide } from '../therapist-planning/therapist-planning.path';

export const PATIENT_CALENDAR_PATHNAME = '/p/calendar';
export const THERAPIST_PATIENT_CALENDAR_PATHNAME = '/t/calendar';

export function getPatientCalendarUrl(side: AppSide, user: string | null, activity: string | null) {
  const searchParams = new URLSearchParams();
  if (user) {
    searchParams.set('user', user);
  }

  if (activity) {
    searchParams.set('activity', activity);
  }

  const searchStr = searchParams.toString();
  if (!searchStr) {
    return PATIENT_CALENDAR_PATHNAME;
  }

  const path = side === AppSide.Therapist ? THERAPIST_PATIENT_CALENDAR_PATHNAME : PATIENT_CALENDAR_PATHNAME;

  return `${path}?${searchStr}`;
}

export type TPatientCalendarSearch = {
  activity?: string,
  user?: string,
};

export const PatientCalendarLoadable = loadable(async () => import('./patient-calendar.view'), {
  fallback: <FullPageLoading />,
});
