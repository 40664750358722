import classes from 'classnames';
import type { ReactNode, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import css from './overflow-indicator.module.scss';

const DEFAULT_OVERFLOW_SIDES = Object.freeze({ left: false, right: false, top: false, bottom: false });

type TOverflowSides = {
  /** whether the Element can be scrolled in the left direction */
  left: boolean,
  /** whether the Element can be scrolled in the right direction */
  right: boolean,
  /** whether the Element can be scrolled in the top direction */
  top: boolean,
  /** whether the Element can be scrolled in the bottom direction */
  bottom: boolean,
};

/**
 * Detects in which direction a given element can be scrolled.
 *
 * Use cases: displaying an indicator that there is content in a given direction
 *
 * @param {React.RefObject<any>} manualRef - optional: provide your own RefObject
 * @returns {[React.RefObject<any>, TOverflowSides]}
 */
export function useOverflowDetector(manualRef?: RefObject<any>): [RefObject<any>, TOverflowSides] {
  const fallbackRef = useRef<any>();
  const ref: RefObject<any> = manualRef ?? fallbackRef;
  const [overflowSides, setOverflowSides] = useState<TOverflowSides>(DEFAULT_OVERFLOW_SIDES);

  useEffect(() => {
    const child = ref.current;
    if (!child) {
      return;
    }

    function checkScroll() {
      const top = child.scrollTop > 0;
      const bottom = child.scrollTop < (child.scrollHeight - child.clientHeight);
      const left = child.scrollLeft > 0;
      const right = child.scrollLeft < (child.scrollWidth - child.clientWidth);

      setOverflowSides(old => {
        if (old.top === top && old.bottom === bottom && left === old.left && right === old.right) {
          return old;
        }

        return { top, bottom, right, left };
      });
    }

    checkScroll();

    let timeout;
    let ro: ResizeObserver;
    // sometimes the browser hasn't computed the layout before this function runs
    // so run it again
    if (typeof ResizeObserver !== 'undefined') {
      ro = new ResizeObserver(() => {
        checkScroll();
      });

      ro.observe(child);
    } else {
      timeout = setTimeout(checkScroll, 1000);
    }

    child.addEventListener('scroll', checkScroll, { passive: true });

    // eslint-disable-next-line consistent-return
    return () => {
      if (ro) {
        ro.disconnect();
      }

      clearTimeout(timeout);
      child.removeEventListener('scroll', checkScroll, {});
    };
  }, []);

  return [ref, overflowSides];
}

type TOverflowIndicatorProps = {
  targetRef: RefObject<any>,
  children: ReactNode,
} | {
  targetRef?: undefined | null,
  children(ref: RefObject<any>): ReactNode,
};

/**
 * This component fades to transparent sides that have overflowing content.
 * Useful to indicate to the user that there is more content in this direction.
 *
 * Either provide {targetRef} as a prop, or receive it from this component by passing a function as the child component.
 *
 * @constructor
 */
export function OverflowIndicator(props: TOverflowIndicatorProps) {
  const { targetRef, children } = props;
  const [ref, sides] = useOverflowDetector(targetRef);

  return (
    <div
      className={classes(css.overflowIndicator, {
        [css.top]: sides.top,
        [css.bottom]: sides.bottom,
        [css.right]: sides.right,
        [css.left]: sides.left,
      })}
    >
      {typeof children === 'function' ? children(ref) : children}
    </div>
  );
}
