import IconButton from '@material-ui/core/IconButton';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarnIcon from '@material-ui/icons/Warning';
import classNames from 'classnames';
import type { ReactComponentLike } from 'prop-types';
import type { ReactNode } from 'react';
import { isValidElement } from 'react';
import { useIntl } from 'react-intl';
import { isMessageDescriptor } from '../../utils/react-utils';
import type { Action } from '../ActionMenu';
import { Anchor, ButtonAnchor } from '../wysiwyg/wysiwyg';
import css from './styles.module.scss';

export type TAlertAction = Omit<Action, 'icon'> & {
  icon?: Action['icon'],
};

type Props = {
  type: TAlertTypes,
  children: ReactNode,
  className?: string,
  icon?: ReactComponentLike,
  actions?: TAlertAction[],
  onActionClick?(): any,
  centered?: boolean,
  shrink?: boolean,
};

export const AlertIcons = Object.freeze({
  warn: WarnIcon,
  info: InfoIcon,
  success: SuccessIcon,
  error: ErrorIcon,
});

export type TAlertTypes = 'info' | 'success' | 'warn' | 'error';

export default function Alert(props: Props) {
  const intl = useIntl();

  const Icon = props.icon ?? AlertIcons[props.type];

  return (
    <div
      className={classNames(
        css.alert,
        css[props.type],
        props.className,
        props.centered && css.centered,
        props.shrink && css.shrink,
      )}
    >
      <div className={css.message}>
        {Icon && <Icon className={css.icon} />}
        <div className={css.contents}>
          {props.children}
        </div>
      </div>

      {props.actions && props.actions.length > 0 && (
        <div className={css.actions}>
          {props.actions.map((action, i) => {
            const title = isMessageDescriptor(action.title)
              ? intl.formatMessage(action.title)
              : action.title;

            let icon = action.icon;

            if (icon == null) {
              return (
                <AlertAction href={action.href} onClick={action.onClick} key={action.key ?? i}>
                  {title}
                </AlertAction>
              );
            }

            if (icon != null && !isValidElement(icon)) {
              const IconComp = icon;
              icon = <IconComp />;
            }

            // FIXME: add support for .href
            return (
              <IconButton
                key={action.key ?? i}
                title={title}
                color="inherit"
                onClick={props.onActionClick}
              >
                {icon}
              </IconButton>
            );
          })}
        </div>
      )}
    </div>
  );
}

type TSnackActionProps = {
  children: ReactNode,
  href?: TAlertAction['href'],
  onClick?: TAlertAction['onClick'],
};

function AlertAction(props: TSnackActionProps) {
  if (props.href) {
    return (
      <Anchor to={props.href} onClick={props.onClick} className={css.alertAction}>
        {props.children}
      </Anchor>
    );
  }

  return (
    <ButtonAnchor onClick={props.onClick} className={css.alertAction}>
      {props.children}
    </ButtonAnchor>
  );
}
