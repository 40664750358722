import loadable from '@loadable/component';
import type { LocationDescriptorObject } from 'history';
import type { ComponentProps } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import FullPageLoading from '../../components/Loading/FullPageLoading';
import { useSearchParams } from '../../hooks/use-search-params';
import type { ExerciseCategory } from '../../shared-library/exercise-categories';
import { slugifyCategory } from '../../shared-library/exercise-categories';
import { getPath } from '../../utils/routing-utils';
import { CHALLENGE_PATHNAME, T_CHALLENGE_PROPOSAL_PATH } from '../Exercise/exercise.path';
import { AppSide, THERAPIST_ROUTES_PREFIX } from '../therapist-planning/therapist-planning.path';

export const P_EXERCISE_CATEGORIES_PATH = '/exercise';
export const P_EXERCISE_CATEGORY_CONTENTS_PATH = '/exercise/:categorySlug';
// /exercise/:category/:exercise is the ExerciseView page
export const P_ADD_CHALLENGE_PATH = '/exercise/:categorySlug/:exerciseId/add';

export const T_EXERCISE_CATEGORIES_PATH = THERAPIST_ROUTES_PREFIX + P_EXERCISE_CATEGORIES_PATH;
export const T_EXERCISE_CATEGORY_CONTENTS_PATH = THERAPIST_ROUTES_PREFIX + P_EXERCISE_CATEGORY_CONTENTS_PATH;
export const T_PROPOSE_CHALLENGE_PATH = THERAPIST_ROUTES_PREFIX + P_ADD_CHALLENGE_PATH;
export const T_EDIT_CHALLENGE_PROPOSAL_PATH = `${T_CHALLENGE_PROPOSAL_PATH}/edit`;

export const P_EDIT_CHALLENGE_PATH = `${CHALLENGE_PATHNAME}/edit`;
export const T_PROPOSE_CHALLENGE_EDITION_PATH = THERAPIST_ROUTES_PREFIX + P_EDIT_CHALLENGE_PATH;

export const T_EDIT_CHALLENGE_TEMPLATE_PATH = `${THERAPIST_ROUTES_PREFIX}/ct/:challengeTemplateId/edit`;

export type TAddChallengeSearch = {
  u?: string,
};

export type TReplaceExerciseRouteState = {
  returnWithActivity?: string,
};

export function getAddChallengeUrl(side: AppSide, search?: TAddChallengeSearch): LocationDescriptorObject {
  if (side === AppSide.Patient) {
    return {
      pathname: P_EXERCISE_CATEGORIES_PATH,
    };
  }

  return {
    pathname: T_EXERCISE_CATEGORIES_PATH,
    search: search ? new URLSearchParams(search).toString() : '',
  };
}

export function getExerciseListUrl(side: AppSide, category: ExerciseCategory): string {
  const params = { categorySlug: slugifyCategory(category) };

  if (side === AppSide.Patient) {
    return getPath(P_EXERCISE_CATEGORY_CONTENTS_PATH, params);
  }

  return getPath(T_EXERCISE_CATEGORY_CONTENTS_PATH, params);
}

export function getConfigureChallengeUrl(
  side: AppSide,
  category: ExerciseCategory,
  exerciseId: string,
): string {
  const pathParams = {
    categorySlug: slugifyCategory(category),
    exerciseId,
  };

  if (side === AppSide.Patient) {
    return getPath(P_ADD_CHALLENGE_PATH, pathParams);
  }

  return getPath(T_PROPOSE_CHALLENGE_PATH, pathParams);
}

export const ActivityCategoriesLazy = loadable(async () => import('./SelectCategory/select-category.view'), {
  fallback: <FullPageLoading />,
});

export const ActivitySearchLazy = loadable(async () => import('./SelectExercise/select-exercise.view'), {
  fallback: <FullPageLoading />,
});

const SelectPatientsLazy = loadable(async () => import('./select-patients.view'), {
  fallback: <FullPageLoading />,
});

export const AddChallengeLazy = loadable(async () => import('./Configure/add-challenge.view'), {
  fallback: <FullPageLoading />,
});

export const EditChallengeLazy = loadable(async () => import('./Configure/edit-challenge.view'), {
  fallback: <FullPageLoading />,
});

/**
 * Do not use in routing directly.
 * Use {CreateAddChallengeProposalView} or {UpdateChallengeProposalLazy} instead
 */
export const CreateOrUpdateAddChallengeProposalInternalLazy = loadable(async () => import('./Configure/create-update--add-challenge-proposal.view'), {
  fallback: <FullPageLoading />,
});

export const CreateEditChallengeProposalLazy = loadable(async () => import('./Configure/create-update--edit-challenge-proposal.view'), {
  resolveComponent: components => components.CreateEditChallengeProposalView,
  fallback: <FullPageLoading />,
});

/**
 * Do not use in routing directly.
 * Use {CreateEditChallengeProposalLazy} or {UpdateChallengeProposalLazy} instead
 */
export const UpdateEditChallengeProposalInternalLazy = loadable(async () => import('./Configure/create-update--edit-challenge-proposal.view'), {
  resolveComponent: components => components.UpdateEditChallengeProposalView,
  fallback: <FullPageLoading />,
});

export const UpdateChallengeProposalOrTemplateLazy = loadable(async () => import('./Configure/update-challenge-proposal'), {
  fallback: <FullPageLoading />,
});

export function CreateAddChallengeProposalView(
  props: ComponentProps<typeof CreateOrUpdateAddChallengeProposalInternalLazy>,
) {
  const location = useLocation();
  const search = useSearchParams();

  if (matchPath(location.pathname, P_ADD_CHALLENGE_PATH) || search.get('u') || search.get('p')) {
    return <CreateOrUpdateAddChallengeProposalInternalLazy {...props} />;
  }

  return <SelectPatientsLazy {...props} />;
}
