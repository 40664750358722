import * as React from 'react';
import logoUrl from '../../resources/my-medicoach/logo/mmc_logo.svg';
import blackLogoUrl from '../../resources/my-medicoach/logo/mmc_logo_black.svg';
import whiteLogoUrl from '../../resources/my-medicoach/logo/mmc_logo_white.svg';
import Image from '../Image';

const logoMap = {
  white: whiteLogoUrl,
  black: blackLogoUrl,
  color: logoUrl,
};

type Props = React.ComponentProps<typeof Image> & {
  style?: 'white' | 'black' | 'color',
};

export default function SiteLogo(props: Props) {
  const { style = 'color', ...passDown } = props;

  return <Image alt="MyMedicoach" {...passDown} src={logoMap[style]} />;
}
