import { useEffect, useState } from 'react';

const hasNavigator = typeof navigator === 'object';

export function useIsOnline(defaultValue = true) {
  if (!hasNavigator) {
    return defaultValue;
  }

  // rules-of-hooks does not understand constant conditions
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const callback = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', callback);
    window.addEventListener('offline', callback);

    return () => {
      window.removeEventListener('online', callback);
      window.removeEventListener('offline', callback);
    };
  }, []);

  return isOnline;
}
