import { defineMessage } from 'react-intl';

export const acceptMessage = defineMessage({ defaultMessage: 'Accept' });
export const acceptAllMessage = defineMessage({ defaultMessage: 'Accept all' });
export const declineMessage = defineMessage({ defaultMessage: 'Decline' });

export const learnMoreMessage = defineMessage({ defaultMessage: 'Learn More' });
export const viewInCalendarMessage = defineMessage({ defaultMessage: 'View in calendar' });
export const closeMessage = defineMessage({ defaultMessage: 'Close' });

export const actionsMessage = defineMessage({ defaultMessage: 'Actions' });
export const loadMoreMessage = defineMessage({ defaultMessage: 'View more' });
export const previousMessage = defineMessage({ defaultMessage: 'Previous' });
export const nextMessage = defineMessage({
  defaultMessage: 'Next',
  description: 'Exclusively used to mean "next step" (eg. in a form, in a popup, etc…).',
});
export const submitMessage = defineMessage({ defaultMessage: 'Submit' });
export const saveMessage = defineMessage({ defaultMessage: 'Save' });
export const changesSavedMessage = defineMessage({ defaultMessage: 'Your changes have been saved.' });
export const confirmMessage = defineMessage({ defaultMessage: 'Confirm' });
export const discardUnsavedConfirmMessage = defineMessage({ defaultMessage: 'You have unsaved changes. Do you want to leave without saving?' });
export const cancelMessage = defineMessage({
  defaultMessage: 'Cancel',
  description: 'Typically used as the text of a button that will cancel an action.',
});

export const helpMessage = defineMessage({ defaultMessage: 'Help' });
export const undoMessage = defineMessage({ defaultMessage: 'Undo' });

export const viewMessage = defineMessage({ defaultMessage: 'View' });
export const editMessage = defineMessage({ defaultMessage: 'Edit' });
export const deleteMessage = defineMessage({ defaultMessage: 'Delete' });
export const archiveActionMessage = defineMessage({ defaultMessage: 'Archive', description: 'The action of archiving something' });
export const unarchiveMessage = defineMessage({ defaultMessage: 'Unarchive' });
export const archiveViewMessage = defineMessage({ defaultMessage: 'Archives', description: 'A place where archived contents are stored' });

export const questionnairesMessage = defineMessage({ defaultMessage: 'Questionnaires' });
export const howDoYouFeelMessage = defineMessage({ defaultMessage: 'How do you feel?' });

export const notificationMessage = defineMessage({ defaultMessage: 'Notifications' });
export const notificationAndAlertsMessage = defineMessage({ defaultMessage: 'Notifications & Alerts' });

export const configureAlertsMessage = defineMessage({ defaultMessage: 'Configure alerts' });

export const viewTutorialMessage = defineMessage({ defaultMessage: 'View tutorial' });

export const genericErrorTitleMessage = defineMessage({ defaultMessage: 'Error' });
export const invitedUserMessage = defineMessage({ defaultMessage: 'This user has been invited but has not joined MyMedicoach yet.' });

export const retryMessage = defineMessage({ defaultMessage: 'Retry' });

export const warningConsultTherapistMessage = defineMessage({
  defaultMessage: 'MyMedicoach is not intended to replace a consultation with a therapist.{br}Patients are advised to consult their therapist before using the MyMedicoach app.',
});

export const restrictedToSuperAdminMessage = defineMessage({ defaultMessage: 'This functionality is restricted to MyMedicoach Administrators.' });

export const legalDisclaimerMessage = defineMessage({ defaultMessage: 'https://www.mymedicoach.com/en/disclaimer' });
export const privacyPolicyMessage = defineMessage({ defaultMessage: 'https://www.mymedicoach.com/en/privacy' });

export const findCoachActionMsg = defineMessage({ defaultMessage: 'Contact a new therapist' });
export const addActivityMsg = defineMessage({ defaultMessage: 'Add a new challenge' });
export const invitePatientActionMsg = defineMessage({ defaultMessage: 'Invite a patient' });

export const createCopyMessage = defineMessage({
  defaultMessage: 'Create a copy',
  description: 'Name of the action that creates a copy of an existing resource (file, challenge, etc…)',
});
export const copyOfMessage = defineMessage({
  defaultMessage: 'Copy of {name}',
  description: `When copying a resource, this is the default name of the resulting copy.
eg. file copying "test.txt" produces "Copy of test.txt"

{name} is the name of the resource that is being copied`,
});
