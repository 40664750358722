import { array } from 'prop-types';

function distance(a: number, b: number) {
  return Math.abs(a - b);
}

/**
 *
 * @param val a positive integer
 * @param arr an array of positive integers
 * @returns the value in arr closest to val by computing the absolute distance between them
 * Example :
 * val = 5
 * arr = [1, 4, 10]
 * returns: 4
 */
export function findClosestNumber(val: number, arr: number[]): number {

  if (val < 0) {
    throw new Error('findClosestNumber: Negative values are not allowed');
  }

  // We don't expect the array to be empty
  if (array.length === 0) {
    throw new Error('findClosestNumber: Empty array');
  }

  // If only one value is provided, no need to go further
  if (arr.length === 1) {
    return arr[0];
  }

  let i = 0;
  let closestIndex = 0;
  let closestDistance = distance(arr[i], val);
  for (i; i <= arr.length; i++) {
    if (arr[i] < 0) {
      throw new Error('findClosestNumber: Negative values are not allowed');
    }

    const currentDistance = distance(arr[i], val);
    if (currentDistance < closestDistance) {
      closestIndex = i;
      closestDistance = currentDistance;
    }
  }

  return arr[closestIndex];
}

/**
 * @deprecated because of weird bugs, was throwing undefined when used with big values
 */
export function findClosest(val, arr) {

  if (arr.length <= 1) {
    return arr[0];
  }

  let low = 0;
  let high = arr.length;
  for (let stop = 0; stop <= arr.length; stop++) {
    const i = Math.floor(low + ((high - low) / 2));

    const lower = arr[i];
    const higher = arr[i + 1] || arr[i];

    if (low === high || (val >= lower && val <= higher)) {
      // lower or closest between the two ?
      return lower;
    }

    if (val < lower) {
      high = i;
    } else if (val > higher) {
      low = i + 1;
    }
  }

  throw new Error('Infinite Loop detected.');
}

export function intersperse<T, K = T>(list: T[], separator: K): Array<T | K> {
  const res: Array<T | K> = [];

  let i = 0;
  if (i < list.length) {
    res.push(list[i++]);
  }

  while (i < list.length) {
    res.push(separator, list[i++]);
  }

  return res;
}

export function areSortedArraysEqual(arr1: any[], arr2: any[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < arr1.length; i++) {
    if (!Object.is(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
}
