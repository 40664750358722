import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import isThisMonth from 'date-fns/isThisMonth';
import isThisWeek from 'date-fns/isThisWeek';
import isToday from 'date-fns/isToday';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import type { TPeriod } from '../components/PeriodSelector';
import { toRfc3339DateLocal } from '../shared-library/date-utils';

export const ONE_SECOND_MS = 1000;
export const ONE_MINUTE_MS = ONE_SECOND_MS * 60;
export const ONE_HOUR_MS = 60 * ONE_MINUTE_MS;
export const ONE_DAY_MS = 24 * ONE_HOUR_MS;
export const ONE_WEEK_MS = 7 * ONE_DAY_MS;
export const ONE_MONTH_MS = 31 * ONE_DAY_MS;
export const ONE_YEAR_MS = 365 * ONE_DAY_MS;

export function setEndOfMonthLocal(date: Date): Date {
  date.setMonth(date.getMonth() + 1);

  // day 0 doesn't exist in a month, date will be last day of previous month instead
  date.setDate(0);

  return date;
}

export function getLocalNow() {
  return new Date();
}

/**
 * This function *does not return date as UTC*
 *
 * Instead it returns a Date object whose time as UTC is the same as the local time of the input.
 *
 * This is useful if you need to use the local time but the library you're using is only using UTC
 * and therefore returning the wrong values.
 *
 * Inverse of {@link pretendItsLocal}
 */
export function pretendItsUtc(date: Date) {
  date = new Date(date);
  date.setUTCMinutes(date.getUTCMinutes() - date.getTimezoneOffset());

  return date;
}

/**
 * This function *does not return date as Local Time*
 *
 * Instead it returns a Date object whose time in the local tz is the same as the UTC time of the input.
 *
 * Inverse of {@link pretendItsUtc}
 */
export function pretendItsLocal(date: Date): Date {
  date = new Date(date);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  return date;
}

/**
 * Returns the requested day of week that is after a reference date.
 * (eg. "Tell me what date next friday is")
 *
 * @param date - The reference date.
 * @param requestedDay - The day of the week to find.
 * @returns {Date} The requested day of week that is after the reference date.
 */
export function getFirstWeekdayAfterUTC(date: Date, requestedDay: number): Date {
  const resultDate = new Date(date);

  resultDate.setUTCDate(date.getUTCDate() + (7 + requestedDay - date.getUTCDay()) % 7);

  return resultDate;
}

export enum JsDay {
  Sunday = 0,
  Monday = 1,
}

/**
 * Returns the requested day of week that is before a reference date.
 * (eg. "Tell me what date last friday is")
 *
 * @param date - The reference date.
 * @param requestedDay - The day of the week to find.
 * @returns {Date} The requested day of week that is before the reference date.
 */
export function getFirstWeekdayBeforeUTC(date: Date, requestedDay: number): Date {
  const resultDate = new Date(date);

  resultDate.setUTCDate(date.getUTCDate() - (((7 - requestedDay) + date.getUTCDay()) % 7));

  return resultDate;
}

/**
 * Returns the requested day of week that is before a reference date.
 * (eg. "Tell me what date last friday is")
 *
 * @param date - The reference date.
 * @param requestedDay - The day of the week to find.
 * @returns {Date} The requested day of week that is before the reference date.
 */
export function getFirstWeekdayBefore(date: Date, requestedDay: number): Date {
  const resultDate = new Date(date);

  resultDate.setDate(date.getDate() - (((7 - requestedDay) + date.getDay()) % 7));

  return resultDate;
}

/**
 * Creates a sorting comparator that sorts days based on their order in the week.
 */
export function weekdayComparator() {

  // In the future, this comparator will take an argument to know what day is the first day of the week.

  return function theWeekdayComparator(a: number, b: number) {
    if (a === 0) {
      return 1;
    }

    if (b === 0) {
      return -1;
    }

    return a - b;
  };
}

/**
 * Registers a callback to invoke when the current system date is different from the input parameter.
 *
 * @param {!Date} fromDate - The date used to compare to current date. Time is ignored.
 * @param {!function} callback - The function to invoke when current date is different from `fromDate`
 *
 * @returns {!function} The unsubscribe callback function.
 */
export function onDateChange(callback: () => void, fromDate: Date = new Date()): () => void {

  // we could be doing a setTimeout from now to tomorrow (in ms) but we also need a setInterval
  // to detect system time change. Add the setTimeout if the 5 second interval is too long.
  const interval = setInterval(() => {
    if (!isToday(fromDate)) {
      callback();
      clearInterval(interval);
    }
  }, isToday(fromDate) ? 5000 : 1);

  return () => {
    clearInterval(interval);
  };
}

export function toLocalDateOnly(date: Date) {
  date = new Date(date);

  date.setHours(0, 0, 0, 0);

  return date;
}

export function getSystemTimeZone(): string | null {
  try {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone || null;
  } catch {
    return null;
  }
}

export enum PeriodType {
  Month = 'Month',
  Week = 'Week',
}

export function startOfPeriod(date: Date, period: PeriodType): Date {
  switch (period) {
    case PeriodType.Month:
      return startOfMonth(date);

    case PeriodType.Week:
      return startOfWeek(date, { weekStartsOn: 1 });

    default:
      throw new Error('Unsupported Period');
  }
}

export function endOfPeriod(date: Date, period: PeriodType): Date {
  switch (period) {
    case PeriodType.Month:
      return endOfMonth(date);

    case PeriodType.Week:
      return endOfWeek(date, { weekStartsOn: 1 });

    default:
      throw new Error('Unsupported Period');
  }
}

export function isCurrentPeriod(date: Date, period: PeriodType): boolean {
  switch (period) {
    case PeriodType.Month:
      return isThisMonth(date);

    case PeriodType.Week:
      return isThisWeek(date, { weekStartsOn: 1 });

    default:
      throw new Error('Unsupported Period');
  }
}

export function periodToIsoInterval(period: TPeriod, urlSafe: boolean = false): string {
  const rangeEnd = endOfPeriod(period.start, period.type);

  const delimiter = urlSafe ? '_' : '/';

  return `${toRfc3339DateLocal(period.start)}${delimiter}${toRfc3339DateLocal(rangeEnd)}`;
}

export function clampDate(min: Date | null, val: Date, max: Date | null): Date {
  if (min && val.getTime() < min.getTime()) {
    return min;
  }

  if (max && val.getTime() > max.getTime()) {
    return max;
  }

  return val;
}
