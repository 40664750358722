import type { ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { isOfRole, UserRoles } from '../../api/users';
import { LOGIN_PATHNAME } from '../../views/Login/login.loadable';
import Error404View from '../../views/NotFoundPage/error-404.view';
import { RightAlignedActions } from '../Actions/right-aligned-actions.js';
import Alert from '../Alert';
import ApiErrorMessage from '../ApiErrorMessage';
import Button2 from '../Button2';
import { ErrorDisplay } from '../ErrorDisplay/error-display';
import { BodyColor } from '../Layout/body-color.js';
import FullPageLoading from '../Loading/FullPageLoading';
import { retryMessage } from '../common-messages';
import { useAuthToken, useViewer } from '../viewer-context';
import css from './access-control.module.scss';

type ACR = {
  viewerStatus?: ViewerStatus | null,
  role?: UserRoles | null,
};

type Props = ACR & {
  children: ReactNode,
};

export function AccessControl(props: Props): ReactElement {
  const token = useAuthToken();
  const { viewer } = useViewer();

  const requiresViewer = props.viewerStatus === ViewerStatus.SignedIn || props.role;
  if (requiresViewer && !token) {
    // if (history.location.pathname !== redirectTo) {
    return <Redirect to={LOGIN_PATHNAME} />;
  }

  // wait for viewer to be loaded before displaying the page
  if (requiresViewer && !viewer) {
    return <LoadViewer />;
  }

  if (props.role && !isOfRole(viewer, props.role)) {
    return <Error404View />;
  }

  // @ts-expect-error https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18912
  return props.children;
}

function LoadViewer() {
  const { error, loading, refresh } = useViewer();
  const intl = useIntl();

  if (error) {
    return (
      <ErrorDisplay>
        <h2>{intl.formatMessage({ defaultMessage: 'Could not log you in' })}</h2>

        <p><ApiErrorMessage error={error} /></p>

        <RightAlignedActions spaced>
          <Button2 onClick={refresh} loading={loading}>
            {intl.formatMessage(retryMessage)}
          </Button2>
        </RightAlignedActions>
      </ErrorDisplay>
    );
  }

  return (
    <>
      <BodyColor background="white" />
      <FullPageLoading />
      <Alert type="info" className={css.signInAlert}>
        {intl.formatMessage({ defaultMessage: 'Connecting to MyMedicoach.' })}
      </Alert>
    </>
  );
}

export function withAccessControl(accessControlRules: ACR) {
  return function decorate(WrappedComponent) {
    return function AccessControlComponent(props) {
      return (
        <AccessControl {...accessControlRules}>
          <WrappedComponent {...props} />
        </AccessControl>
      );
    };
  };
}

export enum ViewerStatus {
  SignedIn = 0,
  SignedOut = 1,
}

export const ensureViewer = withAccessControl({ viewerStatus: ViewerStatus.SignedIn });
export const ensureTherapist = withAccessControl({ viewerStatus: ViewerStatus.SignedIn, role: UserRoles.Therapist });
