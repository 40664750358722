import preval from 'preval.macro';

export const SITE_NAME = 'MyMedicoach';

// language=JavaScript
export const LATEST_COMMIT_HASH = preval`
  try {
    module.exports = require('child_process').execSync('git rev-parse HEAD').toString().trim();
  } catch (e) {
    module.exports = '';
  }
`;

// language=JavaScript
export const CANONICAL_ORIGIN = preval`
  let host = process.env.CANONICAL_HOST;

  if (!host) {
    const defaultValue = 'https://app.mymedicoach.com';
    console.error('Please provide CANONICAL_HOST env variable, default set to ' + defaultValue);
    
    return defaultValue;
  }

  if (!/^https?:\\/\\//.test(host)) {
    host = 'https://' + host;
  }

  module.exports = host;
`;
