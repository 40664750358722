import memoizeOne from 'memoize-one';
import { v4 as uuidgen } from 'uuid';
import { useAuthToken } from '../components/viewer-context';
import { uriTag } from '../utils/url';
import type { SwrOptions, SwrRes } from './swr/swr';
import { useQuery } from './swr/swr';
import { callApi, getDataOrThrow } from './util';

export const getPushSubscriptionConfig = memoizeOne(async () => {
  const webpushServerConfig = await getWebPushConfig();

  if (!webpushServerConfig) {
    throw new Error('failed to load web push server config');
  }

  return {
    userVisibleOnly: true,
    applicationServerKey: base64UrlToUint8Array(webpushServerConfig.publicKey),
  };
});

async function getWebPushConfig() {
  return getDataOrThrow(callApi.get('/push-configuration'));
}

function base64UrlToUint8Array(base64UrlData: string) {
  const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
  const base64 = (base64UrlData + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const buffer = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    // i'm not sure where this code comes from and I'm afraid of changing it.
    // eslint-disable-next-line unicorn/prefer-code-point
    buffer[i] = rawData.charCodeAt(i);
  }

  return buffer;
}

// type ServerPushSubscription = {
//
//   // ios-android push:
//   registrationType: 'FCM',
//   registrationId: ?string,
// } | PushSubscriptionJSON);

export async function updatePushSubscription(subscription: PushSubscription): Promise<void> {

  return getDataOrThrow(callApi.post('/push-subscriptions', {
    body: {
      ...serializePushSubscription(subscription),
      deviceId: getDeviceId(),
    },
  }));
}

export async function deletePushSubscription(subscription: PushSubscription, token?: string): Promise<void> {

  return getDataOrThrow(callApi.delete('/push-subscriptions', {
    body: serializePushSubscription(subscription),
    token,
  }));
}

function serializePushSubscription(subscription: PushSubscription) {
  if (!subscription.toJSON) {
    return subscription;
  }

  return subscription.toJSON();
}

export type TPushPreferences = {
  onNewMessage: boolean,
  objectiveReminderAt: string | null,
};

export function usePushPreferences(options?: SwrOptions<TPushPreferences>): SwrRes<TPushPreferences> {
  const token = useAuthToken();

  return useQuery({
    key: [uriTag`/push-preferences/${getDeviceId()}`, token],
    options,
  });
}

export async function updatePushPreferences(preferences: Partial<TPushPreferences>): Promise<void> {

  return getDataOrThrow(callApi.patch(uriTag`/push-preferences/${getDeviceId()}`, {
    body: preferences,
  }));
}

function getDeviceId() {
  let id = getCordovaDeviceId();
  if (id) {
    return id;
  }

  id = localStorage.getItem('deviceId');
  if (!id) {
    id = uuidgen();
    localStorage.setItem('deviceId', id);
  }

  return id;
}

/** Cordova Hook to get actual, stable device ID */
function getCordovaDeviceId() {
  // @ts-expect-error
  if (typeof device !== 'undefined' && device.uuid) {
    // @ts-expect-error
    return device.uuid;
  }

  return null;
}
