import { findClosestNumber } from './arrays';

export enum UnitType {
  STD_LENGTH = 'STD_LENGTH',
  STD_DURATION = 'STD_DURATION',
  STD_WEIGHT = 'STD_WEIGHT',
  STD_MOVEMENT = 'STD_MOVEMENT',
}

export function isMovementUnit(unitType: string): boolean {
  return unitType === UnitType.STD_MOVEMENT;
}

// ================ Steps ================

export function determineSteps(maxValue: number, type: string): number {
  if (isMovementUnit(type)) {
    return 1;
  }

  switch (type) {
    case UnitType.STD_DURATION:
      return determineTimeStep(maxValue);

    case UnitType.STD_WEIGHT:
    case UnitType.STD_LENGTH:
      return determineDistanceStep(maxValue);

    default:
      return 1;
  }
}

const ONE_KILOMETER: number = 1000;
const BASE_STEPS: number[] = [1, 2, 5, 10, 25, 50, 100, 250, 500];
/**
 * Steps allowed for the checkin range selector, when used for exercises with distances measured in meters.
 * Steps are expressed in meters
 * We map from 1 meter to 500 kilometers
 */
const METER_STEPS: number[] = [
  ...BASE_STEPS,
  ...BASE_STEPS.map(step => step * ONE_KILOMETER),
];

function determineDistanceStep(meters) {

  return findClosestNumber(meters / 10, METER_STEPS);
}

const ONE_MINUTE = 60;
const ONE_HOUR = ONE_MINUTE * 60;

const TIME_STEPS = [
  // less than 1 second is impossible.

  1, 5, 10, 20, 30, 45,
  ONE_MINUTE, 5 * ONE_MINUTE, 10 * ONE_MINUTE, 30 * ONE_MINUTE,
  ONE_HOUR, 2 * ONE_HOUR, 4 * ONE_HOUR, 5 * ONE_HOUR,

  // more than this is impossible as the amount is limited to 24 hours.
];

function determineTimeStep(maxValue) {
  const idealStep = maxValue / 6;

  return findClosestNumber(idealStep, TIME_STEPS);
}
