import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import type { LocationDescriptor } from 'history';
import type { ComponentType, ReactElement, SyntheticEvent } from 'react';
import { isValidElement } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import AnyLink from '../../navigation-lib/any-link';
import { isMessageDescriptor } from '../../utils/react-utils';
import css from './styles.module.scss';

export type Action = {
  icon: ReactElement | ComponentType,
  title: string | MessageDescriptor,
  onClick?(e: SyntheticEvent<HTMLButtonElement | HTMLAnchorElement>): void,
  key?: string,
  href?: LocationDescriptor,
  target?: string,
  // TODO: implement
  disabled?: boolean,
  // TODO: implement
  loading?: boolean,
};

type Props = {
  actions: Action[],
  onRequestClose(): void,
  anchorEl: HTMLElement | null,
};

export function ActionMenu(props: Props) {

  const { actions, onRequestClose, anchorEl, ...passDown } = props;
  const intl = useIntl();

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={props.onRequestClose}
      {...passDown}
    >
      {actions.map((action, i) => {

        let icon = action.icon;
        if (icon != null && !isValidElement(icon)) {
          const IconComp = icon;
          icon = <IconComp />;
        }

        return (
          <MenuItem
            className={css.menuItem}
            component={action.href ? AnyLink : null}
            to={action.href}
            key={action.key || i}
            onClick={e => {
              action.onClick && action.onClick(e);
              onRequestClose();
            }}
          >
            {icon && <span className={css.actionIcon}>{icon}</span>}
            {isMessageDescriptor(action.title) ? intl.formatMessage(action.title) : action.title}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
