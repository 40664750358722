import { CombinedError } from '@urql/core';
import { EMPTY_OBJECT } from '../shared-library/object-utils.js';
import { BaseError } from '../utils/base-error.js';
import { createListener, isPromise } from '../utils/utils';
import { HOSTNAME } from './globals';
import { getAuthToken, setAuthToken } from './token';

const { on: onAuthError, off: offAuthError, dispatch: dispatchAuthError } = createListener();

function authErrorOccurred() {
  dispatchAuthError();
}

export { onAuthError, offAuthError };

export function apiRoute(path: string): string {
  return HOSTNAME + path;
}

function flatObjectForEach(object, cb) {
  for (const key of Object.getOwnPropertyNames(object)) {
    const item = object[key];

    if (Array.isArray(item)) {
      for (const subItem of item) {
        cb(key, subItem);
      }
    } else {
      cb(key, item);
    }
  }
}

export type CallApiOpts = {
  signal?: AbortSignal,
};

export type ApiEndpointParams = {
  query?: { [key: string]: any },
};

export type CallApiParameters = {
  body?: any,
  token?: string | null,
  headers?: { [key: string]: string },
} & CallApiOpts & ApiEndpointParams;

export function buildApiEndpoint(path: string, parameters: ApiEndpointParams = EMPTY_OBJECT) {

  const queryParams = parameters.query ?? {};
  const qsBuilder = new URLSearchParams();

  flatObjectForEach(queryParams, (key, item) => {
    const value = stringifyItem(item);
    if (value === void 0) {
      return;
    }

    qsBuilder.append(key, value);
  });

  const hasSearch = path.includes('?');

  return path + (hasSearch ? '&' : '?') + qsBuilder.toString();
}

export async function callApi(
  method: string,
  path: string,
  parameters: CallApiParameters = EMPTY_OBJECT,
): Promise<Response> {

  const headers = new global.Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...parameters.headers,
  });

  const jwt = parameters.token !== void 0 ? parameters.token : getAuthToken();
  if (jwt) {
    headers.set('Authorization', jwt);
  }

  const fetchParameters: RequestInit = {
    method: method.toUpperCase(),
    headers,
    signal: parameters.signal,
  };

  const isFileUpload = parameters.body instanceof File || parameters.body instanceof FormData;
  if (parameters.body && !isFileUpload) {
    fetchParameters.body = JSON.stringify(parameters.body);
  } else {
    fetchParameters.body = parameters.body;
  }

  if (isFileUpload) {
    headers.delete('Content-Type');
  }

  const urlString = apiRoute(path);

  const url = new URL(urlString);
  url.searchParams.set('apiVersion', '6');

  if (parameters.query) {
    flatObjectForEach(parameters.query, (key, item) => {
      const value = stringifyItem(item);
      if (value === void 0) {
        return;
      }

      url.searchParams.append(key, value);
    });
  }

  let response: Response;
  try {
    response = await fetch(url.toString(), fetchParameters);
  } catch (error) {
    if (error.name === 'AbortError') {
      throw error;
    }

    throw new ServerUnreachableError('Server could not be reached', { cause: error });
  }

  if (response.status === 500) {
    let reportId;
    try {
      const body = await response.json();
      reportId = body.error.id;
    } catch {
      /* ignore */
    }

    throw new InternalServerError().withReportId(reportId);
  }

  if (response.headers.has('X-Set-Authorization')) {
    const newAuthToken = response.headers.get('X-Set-Authorization');
    setAuthToken(newAuthToken);
  }

  if (response.status === 401) {
    authErrorOccurred();
  }

  return response;
}

export const ServerUnreachableSymbol = Symbol('server-unreachable');
export const InternalServerErrorSymbol = Symbol('server-unreachable');

export class ServerUnreachableError extends BaseError {
  name = 'ServerUnreachableError';
  code = ServerUnreachableSymbol;
}

export class InternalServerError extends BaseError {
  name = 'InternalServerError';
  reportId: string;

  code = InternalServerErrorSymbol;

  withReportId(id: string): this {
    this.reportId = id;

    return this;
  }
}

export function getErrorCode(error: any): string | symbol | null {
  if (error.code) {
    return error.code;
  }

  if (error instanceof CombinedError && error.networkError) {
    return ServerUnreachableSymbol;
  }

  return null;
}

type CallApiShortHand = (path: string, param?: CallApiParameters) => Promise<Response>;

function buildMethod(httpMethod: string): CallApiShortHand {
  return async function callApiWithMethod(...args) {
    return callApi(httpMethod, ...args);
  };
}

function stringifyItem(item) {
  if (item == null) {
    return item;
  }

  if (item instanceof Date) {
    return item.toISOString();
  }

  return item;
}

callApi.get = buildMethod('get');
callApi.put = buildMethod('put');
callApi.patch = buildMethod('patch');
callApi.post = buildMethod('post');
callApi.delete = buildMethod('delete');

export async function getDataOrThrow(response: Response | Promise<Response>) {
  if (isPromise<Response>(response)) {
    response = await response;
  }

  // TODO: 404 should be thrown as an error for mutations
  if (response.status === 404) {
    return null;
  }

  if (!response.ok) {
    return throwResponse(response);
  }

  return response.json().then(json => {
    if (json.errors) {
      throwGqlErrors(json.errors);
    }

    return json.data;
  }, error => {
    if (error.name === 'AbortError') {
      throw error;
    }

    // catch json parse error just in case the server fucked up.
    console.error('response body parse error');
    console.error(error);

    throw error;
  });
}

function throwGqlErrors(errors) {
  throw new Error(`Request failure: ${errors.map(error => error.message).join('\n')}`);
}

export async function throwResponse(response: Response) {
  const body = await response.text();

  let json;
  try {
    json = JSON.parse(body);
  } catch {
    throw new Error(`Request failure: ${body}`);
  }

  const err = new Error('Request failure');
  Object.assign(err, json.error);

  throw err;
}
