import { assert } from '../shared-library/assert';
import { assertString, filterOutIterator } from './utils';

let labelCount = 0;

export function generateIdentifier(): string {
  return `generic_id_${labelCount++}`;
}

export const CHARCODES = {
  ENTER: 13, // LineFeed is never dispatched, it's always CR on the web.
  ESCAPE: 27,
  SPACE: 32,
};

Object.freeze(CHARCODES);

export function makeHiddenForm(id?: string): null | HTMLFormElement {
  if (typeof document !== 'object' || !document.createElement) {
    return null;
  }

  const fakeForm = document.createElement('form');

  fakeForm.style.height = '0';
  fakeForm.style.width = '0';
  fakeForm.style.opacity = '0';
  fakeForm.id = id || generateIdentifier();
  fakeForm.setAttribute('aria-hidden', 'true');

  return fakeForm;
}

export function getElementWidthWithMargin(element: HTMLElement): number {
  const style = window.getComputedStyle(element);

  return element.offsetWidth + Number.parseInt(style.marginLeft, 10) + Number.parseInt(style.marginRight, 10);
}

/**
 * https://stackoverflow.com/questions/11634770/get-position-offset-of-element-relative-to-a-parent-container
 * @param elem
 * @param otherElem
 * @returns {{left: number, top: number}}
 */
export function getElementRelativeOffset(otherElem: Element, elem: Element) {
  if (elem.ownerDocument !== otherElem.ownerDocument) {
    throw new Error('Cannot get relative position for elements in two different documents');
  }

  // relative to the target field's document
  const elemBounding = elem.getBoundingClientRect();

  const offset = {
    left: elemBounding.left,
    top: elemBounding.top,
  };

  if (elem !== otherElem) {
    const otherBounding = otherElem.getBoundingClientRect();

    offset.left -= otherBounding.left;
    offset.top -= otherBounding.top;
  }

  return offset;
}

// iOS detection from: http://stackoverflow.com/a/9039885/177710
export function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}

export function getIosVersion(): string | null {
  if (!isIos()) {
    return null;
  }

  const match = navigator.userAgent.match(/OS ((\d+_?){2,3})\s/)?.[1];
  if (match == null) {
    return null;
  }

  const out = match.replace(/_/g, '.');

  if (/^\d+\.\d+$/.test(out)) {
    return `${out}.0`;
  }

  return out;
}

export function isMacOs() {
  return navigator.platform.includes('Mac');
}

export function isAppleOs() {
  return isMacOs() || isIos();
}

// https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts instead ?
export function getBrowserId() {
  return getBrowserIdFromUserAgent(navigator.userAgent);
}

export function getBrowserIdFromUserAgent(ua: string) {
  if (ua.includes('Firefox/')) {
    if (ua.includes('Android ')) {
      return 'firefox_mobile';
    }

    return 'firefox_desktop';
  }

  if (ua.includes('CriOS/')) { // chrome ios
    return 'chrome_mobile';
  }

  if (ua.includes('Chrome/')) {
    if (ua.includes(' Android ') || ua.includes(' Mobile Safari/')) {
      return 'chrome_mobile';
    }

    return 'chrome_desktop';
  }

  if (ua.includes('Safari/')) {
    if (ua.includes('Mobile/')) {
      return 'safari_mobile';
    }

    return 'safari_desktop';
  }

  return '';
}

let scrollbarWidth: number | null = null;
export function getScrollbarWidth(): number {
  if (scrollbarWidth !== null) {
    return scrollbarWidth;
  }

  if (typeof document === 'undefined') {
    return 0;
  }

  const scrollDiv = document.createElement('div');
  scrollDiv.setAttribute('style', `width: 100px; height: 100px; overflow: scroll; position: absolute; top: -9999px;`);
  document.body.append(scrollDiv);

  // Get the scrollbar width
  scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  scrollDiv.remove();

  return scrollbarWidth;
}

type TFormElementValue = string | number | boolean | Array<string | number> | null;

export function getFormValue(e: HTMLFormElement, fieldName: string): TFormElementValue {
  return getFormElementValue(e.elements[fieldName]);
}

export function getFormValueString(e: HTMLFormElement, fieldName: string): string {
  const value = getFormValue(e, fieldName);
  assertString(value);

  return value;
}

// TODO: test me once RadioNodeList is available https://github.com/jsdom/jsdom/issues/2600
export function getFormValues(form: HTMLFormElement): {
  [key: string]: TFormElementValue,
} {

  const out = Object.create(null);

  for (const element of form.elements) {
    // @ts-expect-error
    const name = element.name;

    // some .elements don't contain any data (eg. buttons)
    if (!name) {
      continue;
    }

    // .namedItems returns an array when two elements have the same key
    // so no need to process them twice
    if (out[name]) {
      continue;
    }

    const normalizedElement = form.elements.namedItem(name);

    assert(normalizedElement != null);

    out[name] = getFormElementValue(normalizedElement);
  }

  return out;
}

export function getFormElementValue(
  element: Element | RadioNodeList,
): TFormElementValue {
  if (isRadioNodeList(element)) {
    return getRadioNodeListValue(element);
  }

  // @ts-expect-error
  const elementGroup = element.form && element.name ? element.form.elements[element.name] : null;
  if (elementGroup && isRadioNodeList(elementGroup)) {
    // don't include the value of disabled elements
    const elements = Array.from(filterOutIterator(elementGroup, isDisabledElement));
    if (elements.length === 1) {
      return getSingleFormElementValue(elements[0]);
    }

    return getRadioNodeListValue(elementGroup);
  }

  return getSingleFormElementValue(element);
}

export function isDisabledElement(element: HTMLInputElement | HTMLTextAreaElement
  | HTMLSelectElement | HTMLButtonElement): boolean {
  return element.disabled || element.getAttribute('aria-disabled') === 'true';
}

function getSingleFormElementValue(element: Node): string | number | null | boolean {
  if (isHtmlInput(element)) {
    // a checkbox will either be returned as its checked state (if .value is empty, boolean) - this is the "Switch" use case
    // or as its value (if not empty) - this is the multiple checkboxes use case
    if (element.type === 'checkbox') {
      if (element.getAttribute('value') == null || element.value === '') {
        return element.checked;
      }

      return element.checked ? element.value : '';
    }

    if (element.type === 'number') {
      if (Number.isNaN(element.valueAsNumber)) {
        return null;
      }

      return element.valueAsNumber;
    }

    return element.value;
  }

  if (isHtmlSelect(element) || isHtmlTextarea(element)) {
    return element.value;
  }

  throw new Error(`Unsupported element type ${element.nodeName}`);
}

function getRadioNodeListValue(radioList: RadioNodeList): string | number | Array<string | number> {
  // RadioNodeList of input[type="radio"]
  if (radioList.value) {
    return radioList.value;
  }

  const firstElement = radioList.item(0);
  if (isHtmlInput(firstElement) && firstElement.type === 'radio') {
    return '';
  }

  // RadioNodeList of input[type="checkbox"]
  const values: Array<string | number> = [];
  for (const checkbox of radioList) {
    const value = getSingleFormElementValue(checkbox);
    if (value && (typeof value === 'number' || typeof value === 'string')) {
      values.push(value);
    }
  }

  return values;
}

export function isHtmlInput(item: any): item is HTMLInputElement {
  return Object.prototype.toString.call(item) === '[object HTMLInputElement]' && item.nodeName === 'INPUT';
}

export function assertHtmlInputElement(input: any): asserts input is HTMLInputElement {
  if (!isHtmlInput(input)) {
    throw new Error('Assertion error: Input is not HTMLInputElement');
  }
}

export function isHtmlTextarea(item: any): item is HTMLTextAreaElement {
  return item.nodeName === 'TEXTAREA';
}

export function isHtmlSelect(item: any): item is HTMLTextAreaElement {
  return item.nodeName === 'SELECT';
}

export function isRadioNodeList(item: any): item is RadioNodeList {
  return item instanceof RadioNodeList;
}

export function scrollIntoViewIfNeeded(element, scrollBehavior) {
  if (typeof element.scrollIntoViewIfNeeded === 'function') {
    element.scrollIntoViewIfNeeded(scrollBehavior);
  } else {
    element.scrollIntoView(scrollBehavior);
    // try {
    //   element.scrollIntoView({ ...scrollBehavior, scrollMode: 'if-needed' });
    // } catch (e) {
    //   element.scrollIntoView(scrollBehavior);
    // }
  }
}

export function onEvent(
  target: EventTarget,
  eventName: string,
  callback: EventListener,
  options?: AddEventListenerOptions,
): () => void {
  target.addEventListener(eventName, callback, options);

  return () => {
    target.removeEventListener(eventName, callback, options);
  };
}

// use HTML element as key?
const loadedScriptMap = new Map();
export async function loadScript(url: string): Promise<void> {
  if (!loadedScriptMap.has(url)) {
    loadedScriptMap.set(url, new Promise<void>((resolve, reject) => {
      const script = document.createElement('script');
      const offLoad = onEvent(script, 'load', () => {
        offLoad();
        offError();

        resolve();
      });

      const offError = onEvent(script, 'error', e => {
        offLoad();
        offError();

        reject(e);
      });

      script.src = url;
      document.head.append(script);
    }));

  }

  return loadedScriptMap.get(url);
}

export function getSvgCircleTotalLength(svgCircle: SVGCircleElement) {
  // WORKAROUND - iOS (tested on safari 13):
  //   getTotalLength always returns 0 on circle SVGs, unless observed through the inspector.
  // if (svgCircle.getTotalLength) {
  //   return svgCircle.getTotalLength();
  // }

  return 2 * Math.PI * Number(svgCircle.getAttribute('r'));
}

export async function copyTextContent(element: HTMLElement): Promise<void> {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(element.textContent ?? '');

    return;
  }

  // https://stackoverflow.com/questions/985272/selecting-text-in-an-element-akin-to-highlighting-with-your-mouse
  const selection = window.getSelection();
  assert(selection != null);

  const range = document.createRange();
  range.selectNode(element);

  selection.removeAllRanges();
  selection.addRange(range);

  /* Copy the text inside the text field */
  document.execCommand('copy');
}

export function downloadBlob(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = fileName;
  document.body.append(a);
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}
