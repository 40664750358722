import type { LocationDescriptor, LocationDescriptorObject } from 'history';
import { locationsAreEqual } from 'history';
import * as React from 'react';
import type { ExtractRouteParams } from 'react-router';
import {
  Link,
  generatePath,
  useLocation,
  useHistory,
} from 'react-router-dom';
import type { ObjectMap } from './types';

/*
 * Source: https://twitter.com/danvdk/status/1301707026507198464
 */

type RouteParams = { [key: string]: string };

export function getPath<Path extends string>(path: Path, pathParameters: ExtractRouteParams<Path>): string {
  return generatePath(path, pathParameters);
}

export function buildUrl<Path extends string>(
  pathTemplate: Path,
  pathParameters: ExtractRouteParams<Path>,
  search?: ObjectMap<any>,
): string {
  let out = getPath(pathTemplate, pathParameters);

  if (search) {
    const searchStr = new URLSearchParams(search).toString();

    if (searchStr) {
      out += `?${searchStr}`;
    }
  }

  return out;
}

type RouterLinkProps = {
  onClick?(SyntheticEvent): void,
  to: LocationDescriptor,
  params?: RouteParams,

  // React.ElementType is defined as "React.ComponentType<empty> | string" :/
  component?: React.ElementType,

  pop?: boolean,
  replace?: boolean,
};

// type IRouteLink = OverridableComponent<{
//   props: RouterLinkProps,
//   defaultComponent: Link,
//   classKey: string,
// }> & { displayName?: string };

export const RouteLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((
  linkProps: RouterLinkProps,
  ref,
) => {
  const location = useLocation();
  const history = useHistory();

  const { to, onClick, params, component: Component = Link, pop, replace, ...props } = linkProps;

  const pathTemplate = isLocationDescriptionObject(to) ? to.pathname : to;
  const generatedPath = params ? getPath(pathTemplate, params) : pathTemplate;
  const currentPath = location.pathname;

  const finalTo = isLocationDescriptionObject(to) ? {
    ...to,
    pathname: generatedPath,
  } : generatedPath;

  const handleRouteChange = React.useCallback((event: React.SyntheticEvent<HTMLAnchorElement>) => {
    try {
      if (onClick) {
        onClick(event);
      }

      if (event.defaultPrevented) {
        return;
      }

      if (!pop && !replace // FIXME: this must also compare state (except previousLocation).
        && locationsAreEqual(finalTo, location)) {
        event.preventDefault();

        return;
      }

      if (pop) {
        history.goBack();
        event.preventDefault();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }, [onClick, pop, replace, generatedPath, currentPath, history]);

  return (
    <Component {...props} replace={replace} to={finalTo} onClick={handleRouteChange} ref={ref} />
  );
});

if (process.env.NODE_ENV !== 'production') {
  RouteLink.displayName = 'RouteLink';
}

function isLocationDescriptionObject(item: any): item is LocationDescriptorObject {
  if (item === null) {
    return false;
  }

  if (typeof item !== 'object') {
    return false;
  }

  if (item.pathname == null) {
    return false;
  }

  return true;
}

export type { ExtractRouteParams } from 'react-router';
