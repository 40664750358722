export class BaseError extends Error {
  code: string | symbol | null = null;
  name = 'BaseError';

  withCode(code: string): this {
    this.code = code;

    return this;
  }
}
