import loadable from '@loadable/component';
import LoadingState from '../../components/Loading/FullPageLoading';

export default {
  exact: true,
  path: '/_components/:component?',
  // loadable generates bundle names based on import so we need to not call them "index"
  component: loadable(async () => import('./components.view'), {
    fallback: <LoadingState />,
  }),
};
