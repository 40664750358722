import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function withSearchParams(Component: Function): Function {

  function WithSearchParams(props) {
    return <Component {...props} searchParams={useSearchParams()} />;
  }

  if (process.env.NODE_ENV !== 'production') {
    WithSearchParams.displayName = `withSearchParams(${Component.displayName || Component.name})`;
  }

  return WithSearchParams;
}

export function useSearchParams() {
  const location = useLocation();
  const search = location.search;

  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}
