import type { ReactNode } from 'react';
import { createContext, isValidElement, useCallback, useContext, useMemo } from 'react';
import { useFifoQueue } from '../../hooks/use-queue';
import { createTrappedCallable } from '../../utils/dev-utils';
import { createDeferred } from '../../utils/utils';
import Button2 from '../Button2';
import BlueModal from '../Dialog/BlueModal';
import css from './style.module.scss';

type Props = {
  children: ReactNode,
};

type PromptSelectOption = string | { title: string, outline?: boolean, danger?: boolean };

export type PromptSelectParams = {
  title: ReactNode,
  content: ReactNode,
  choices: {
    [key: string]: PromptSelectOption,
  },
};

export type ConfirmParams = {
  title: ReactNode,
  content: ReactNode,
  danger?: boolean,
};

export type AlertParams = {
  title: ReactNode,
  content: ReactNode,
  actions?: Array<PromptSelectOption | ReactNode>,
};

export type IPromptContext = {
  promptConfirm(params: ConfirmParams): Promise<boolean>,
  promptSelect(params: PromptSelectParams): Promise<string>,
  alert(params: AlertParams): Promise<void>,
};

export const PromptContext = createContext<IPromptContext>(createTrappedCallable());

export function usePrompt(): IPromptContext {
  return useContext(PromptContext);
}

type Prompt = PromptSelectParams & {
  resolve(value: string): void,
  type: 'choice',
  key: number,
};

let promptSequence = 0;

export function PromptProvider(props: Props) {
  const [prompts, pushPrompt, shiftPrompt] = useFifoQueue<Prompt>();

  const promptSelect = useCallback(async params => {
    const [deferred, resolve] = createDeferred<string>();

    pushPrompt({
      ...params,
      resolve,
      type: 'choice',
      key: promptSequence++,
    });

    return deferred;
  }, [pushPrompt]);

  const promptConfirm = useCallback(async (params: ConfirmParams) => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn('promptConfirm is not localized');
    }

    const choice = await promptSelect({
      ...params,
      choices: {
        ok: {
          title: 'OK',
          danger: params.danger ?? false,
        },
        nok: {
          title: 'Cancel',
          outline: true,
        },
      },
    });

    return choice === 'ok';
  }, [promptSelect]);

  const alert = useCallback(async (params: AlertParams) => {
    const choices = {};
    if (params.actions) {
      for (let i = 0; i < params.actions.length; i++){
        choices[`a${i}`] = params.actions[i];
      }
    }

    await promptSelect({
      ...params,
      choices: {
        ...choices,
        ok: {
          title: 'OK',
        },
      },
    });
  }, [promptSelect]);

  const promptContext = useMemo(() => {
    return {
      promptConfirm,
      promptSelect,
      alert,
    };
  }, [promptSelect, promptConfirm, alert]);

  const prompt = prompts[0];

  return (
    <PromptContext.Provider value={promptContext}>
      {props.children}

      {prompt && (
        <BlueModal onRequestClose={null} open title={prompt.title} key={prompt.key}>
          {prompt.content && (
            <p>{prompt.content}</p>
          )}
          <div className={css.promptChoices}>
            {Object.keys(prompt.choices).map(choiceKey => {
              const choice = prompt.choices[choiceKey];

              if (isValidElement(choice)) {
                return choice;
              }

              let title;
              let outline;
              let danger;
              if (typeof choice === 'string') {
                title = choice;
                outline = false;
                danger = false;
              } else {
                title = choice.title;
                outline = choice.outline ?? false;
                danger = choice.danger ?? false;
              }

              return (
                <Button2
                  variant={outline ? 'outlined' : 'plain'}
                  scheme={danger ? 'danger' : 'default'}
                  key={choiceKey}
                  onClick={() => {
                    shiftPrompt();
                    prompt.resolve(choiceKey);
                  }}
                >
                  {title}
                </Button2>
              );
            })}
          </div>
        </BlueModal>
      )}
    </PromptContext.Provider>
  );
}
