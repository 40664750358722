import ButtonBase from '@material-ui/core/ButtonBase';
import type { ButtonBaseProps } from '@material-ui/core/ButtonBase/ButtonBase';
import classnames from 'classnames';
import type { ElementType, MutableRefObject, ReactElement } from 'react';
import { PureComponent, useCallback, useState } from 'react';
import { forwardRefEnsure } from '../../../utils/react-utils';
import { RefPortal } from '../../ref-portal';
import css from './styles.module.scss';

type TRippleContainerRef = MutableRefObject<HTMLElement>;

type ButtonAreaProps<D extends ElementType> = ButtonBaseProps<D, { component?: D }> & {
  rippleContainerRef?: TRippleContainerRef,
};

function ButtonAreaInternal<D extends ElementType = 'button'>(props: ButtonAreaProps<D>, ref) {
  const { className, component = 'button', rippleContainerRef, ...passDown } = props;

  const type = props.type ?? (component === 'button' ? 'button' : undefined);

  const [isHovered, setIsOvered] = useState(false);

  const onOver = useCallback(() => {
    setIsOvered(true);
  }, []);

  const onUnOver = useCallback(() => {
    setIsOvered(false);
  }, []);

  const hoverContainerRef = rippleContainerRef ?? ref;

  const commonProps = {
    component,
    ...passDown,
    type,
    className: classnames(className, css.styleLessButton),
    focusRipple: true,
    onMouseEnter: onOver,
    onMouseLeave: onUnOver,
  };

  const hoverOverlay = <div className={classnames(css.hoverOverlay, isHovered && css.isHovered)} />;
  if (!rippleContainerRef) {
    return (
      <ButtonBase
        ref={ref}
        {...commonProps}
      >
        {props.children}
        {hoverOverlay}
      </ButtonBase>
    );
  }

  return (
    <>
      <ButtonBaseMovableRipple
        forwardedRef={ref}
        rippleContainerRef={rippleContainerRef}
        {...commonProps}
      >
        {props.children}
      </ButtonBaseMovableRipple>
      <RefPortal containerRef={hoverContainerRef}>
        {hoverOverlay}
      </RefPortal>
    </>
  );
}

const ButtonArea: <E extends ElementType = 'button'>(
  props: ButtonAreaProps<E>
) => ReactElement | null = forwardRefEnsure(ButtonAreaInternal);

export default ButtonArea;

type TButtonBaseMovableRippleProps<E extends ElementType = 'button'> = ButtonBaseProps<E> & {
  // this ref is not really HTMLButtonElement but it doesn't matter as this is just an internal type.
  //  ButtonArea's ref prop works properly
  forwardedRef: MutableRefObject<HTMLButtonElement>,
  rippleContainerRef: TRippleContainerRef,
};

class ButtonBaseMovableRipple extends PureComponent<TButtonBaseMovableRippleProps> {
  ripple: Element | null;
  obs: MutationObserver;

  // ==================================================================
  // Everything below this line is a massive hack
  // We want buttons to guarantee a touchable area of 48x48px
  // Even if visually they can be smaller.
  // We do this by making the button transparent and using a placing all button styling
  // on a <div> inside the button
  //
  // The only issue is the material ripple: it overlays the whole <button> but we want it to only
  // overlay the <div>.
  // The only way to do that is either
  // - reimplement ButtonBase
  // - or move the ripple span inside the <div> after render, and move it back inside <button> before
  //   the next render.
  // ==================================================================

  componentDidMount() {
    this.obs = new MutationObserver(() => {
      const button: HTMLElement = this.props.forwardedRef.current;
      if (button == null) {
        return;
      }

      const ripple = button.querySelector('.MuiTouchRipple-root');
      if (!ripple) {
        return;
      }

      this.ripple = ripple;

      const rippleContainer = this.props.rippleContainerRef.current;
      if (rippleContainer) {
        rippleContainer.append(ripple);
      }
    });

    const button: HTMLElement = this.props.forwardedRef.current;
    this.obs.observe(button, { childList: true });
  }

  UNSAFE_componentWillUpdate() {
    this.obs.disconnect();

    const button: HTMLElement = this.props.forwardedRef.current;
    if (button == null) {
      return;
    }

    if (this.ripple) {
      button.append(this.ripple);
      this.ripple = null;
    }
  }

  componentDidUpdate() {
    const button: HTMLElement = this.props.forwardedRef.current;
    if (button == null) {
      return;
    }

    const ripple = button.querySelector('.MuiTouchRipple-root');
    if (ripple) {
      this.ripple = ripple;

      const rippleContainer = this.props.rippleContainerRef.current;
      if (rippleContainer) {
        rippleContainer.append(ripple);
      }
    }

    this.obs.observe(button, { childList: true });
  }

  componentWillUnmount() {
    this.obs.disconnect();
  }

  // ==================================================================
  // end of the massive hack
  // ==================================================================

  render() {
    const {
      forwardedRef,
      rippleContainerRef: ignore,
      ...passDown
    } = this.props;

    return (
      <ButtonBase
        {...passDown}
        ref={forwardedRef}
      />
    );
  }
}
