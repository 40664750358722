import * as React from 'react';
import { getComponentName } from '../utils/react-utils';

type ExtraProps = { [key: string]: any };

export function withHooks<P, T extends ExtraProps>(callback: (props: P) => T) {

  return function wrapComponent(WrappedComponent: React.ComponentType<P & T>) {
    const WrapperComponent: React.FC = (props: P) => {
      const newProps = callback(props);

      return <WrappedComponent {...props} {...newProps} />;
    };

    WrapperComponent.displayName = `withHooks(${getComponentName(WrappedComponent)})`;

    return WrapperComponent;
  };
}
