import classNames from 'classnames';
import type { ReactNode } from 'react';
import css from './span-header.module.scss';

export function SpanHeader() {
  return <div className={classNames(css.spanHeader)} />;
}

export function CommonLayout(props: { children: ReactNode }) {
  return (
    <div className={css.commonLayout}>
      <SpanHeader />
      {props.children}
    </div>
  );
}
