import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { UserRoles } from '../../api/users';
import AppPage from '../../components/Layout/AppPage';
import { getSectionClasses } from '../../components/Layout/body-color.js';
import { useViewer } from '../../components/viewer-context';
import { PATIENT_PLANNING_PATHNAME } from '../patient-planning/patient-planning.loadable';
import { THERAPIST_PLANNING_PATHNAME } from '../therapist-planning/therapist-planning.path';
import messages from './messages';
import css from './style.module.scss';

export default function Error404View() {
  const intl = useIntl();

  return (
    <AppPage title={intl.formatMessage(messages.title)} variant="color-light">
      <Error404Display />
    </AppPage>
  );
}

export function Error404Display() {
  const { viewer } = useViewer();
  const intl = useIntl();

  const isTherapist = viewer && viewer.roles.includes(UserRoles.Therapist);

  return (
    <div className={classnames(css.display, getSectionClasses('color-light'))}>
      <h1 className={css.title}>
        {intl.formatMessage(messages.title)}
      </h1>

      <div className={css.linkWrapper}>
        <ChevronRightIcon />
        <Link to={isTherapist ? THERAPIST_PLANNING_PATHNAME : PATIENT_PLANNING_PATHNAME} className={css.link}>
          {intl.formatMessage(messages.goHome)}
        </Link>
      </div>
    </div>
  );
}
