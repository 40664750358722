import type { Location } from 'history';
import { Route, Redirect, Switch, useLocation, matchPath } from 'react-router-dom';
import MultiPageView, { MultiPageIndex, MultiPageMain } from '../components/Layout/MultiPageView';
import PageWithDock from '../components/Layout/PageWithDock';
import useMediaQuery from '../hooks/use-media-query';
import { useSearchParams } from '../hooks/use-search-params';
import { LocationLocker } from '../navigation-lib/location-locker';
import { srAndroidTransitionSlide } from '../navigation-lib/page-transitions/android-slide';
import { RouteGroup } from '../navigation-lib/route-group';
import { StackRouter } from '../navigation-lib/stack-router';
import { ParameterisedPath, TabRouter } from '../navigation-lib/tab-router';
import type { Nullish } from '../shared-library/types';
import { useIsDesktopRouting } from '../theme';
import { removeQuery } from '../utils/url';
import type { TReplaceExerciseRouteState } from './AddChallenge/activity-categories.lazy';
import {
  ActivitySearchLazy,
  ActivityCategoriesLazy,
  P_ADD_CHALLENGE_PATH,
  P_EXERCISE_CATEGORY_CONTENTS_PATH,
  P_EXERCISE_CATEGORIES_PATH,
  P_EDIT_CHALLENGE_PATH,
  T_PROPOSE_CHALLENGE_EDITION_PATH,
  T_PROPOSE_CHALLENGE_PATH,
  T_EXERCISE_CATEGORY_CONTENTS_PATH,
  T_EXERCISE_CATEGORIES_PATH,
  AddChallengeLazy,
  EditChallengeLazy,
  T_EDIT_CHALLENGE_PROPOSAL_PATH,
  CreateAddChallengeProposalView,
  UpdateChallengeProposalOrTemplateLazy,
  CreateEditChallengeProposalLazy,
  T_EDIT_CHALLENGE_TEMPLATE_PATH,
} from './AddChallenge/activity-categories.lazy';
import { ChallengePackLazy, T_CHALLENGE_PACK_PATH } from './AddChallenge/challenge-pack/challenge-pack.lazy';
import { ChallengePacksLazy, T_CHALLENGE_PACKS_PATH } from './AddChallenge/challenge-packs/challenge-packs.lazy';
import {
  SendChallengePackLazy,
  T_SEND_CHALLENGE_PACK_PATH,
} from './AddChallenge/send-challenge-pack/send-challenge-pack.lazy';
import {
  AddQuestionnaireLoadable,
  THERAPIST_ADD_QUESTIONNAIRE_PATHNAME,
} from './AddQuestionnaire/add-questionnaire.loadable';
import { ADMIN_PANEL_PATHNAME, AdminPanelLoadable } from './AdminPanel/admin-panel.loadable';
import { ChatListLoadable } from './Chat/conversation-list.loadable';
import { ChatLoadable } from './Chat/conversation-single.loadable';
import { PATIENT_CHAT_LIST_PATHNAME, PATIENT_CHAT_PATHNAME, THERAPIST_CHAT_LIST_PATHNAME } from './Chat/conversation.path';
import { CoachPatientLoadable } from './CoachPatient/coach-patient.loadable';
import {
  COACH_PATIENT_CHAT_PATHNAME,
  COACH_PATIENT_STAT_PATH_DETAILS,
  COACH_PATIENT_STAT_PATHNAME,
} from './CoachPatient/coach-patient.path';
import {
  CreateExerciseLoadable,
  THERAPIST_CREATE_EXERCISE_PATHNAME,
  THERAPIST_EDIT_EXERCISE_PATHNAME,
} from './CreateExercise/create-exercise.loadable';
import { ExerciseLoadable } from './Exercise/exercise.loadable';
import {
  CHALLENGE_PATHNAME, P_CHALLENGE_PROPOSAL_PATH,
  EXERCISE_PATHNAME, T_CHALLENGE_PROPOSAL_PATH,
  THERAPIST_CHALLENGE_PATH,
  THERAPIST_EXERCISE_PATH,
} from './Exercise/exercise.path';
import { HELP_PATHNAME, HelpLoadable } from './Help/help.loadable';
import IndexView, { INDEX_PATHNAME } from './Index/index.view';
import { InvitePatientLoadable, THERAPIST_INVITE_PATIENT_PATHNAME } from './InvitePatient/invite-patient.loadable';
import { LOGIN_PATHNAME, LoginLoadable } from './Login/login.loadable';
import { REGISTER_PATHNAME } from './Login/register.path';
import { LOGOUT_PATH, LogoutLoadable } from './Logout/logout.loadable';
import { MAGIC_LOGIN_PATHNAME, MagicLoginLoadable } from './MagicLogin/magic-login.loadable';
import { MARKETPLACE_PATHNAME, MarketplaceSearchLoadable } from './Marketplace/marketplace-search.loadable';
import {
  THERAPIST_PUBLIC_PROFILE,
  MarketplaceSellerLoadable,
} from './Marketplace/marketplace-seller.loadable';
import Error404View from './NotFoundPage/error-404.view';
import { PATIENT_QUESTION_STATS_PATHNAME, QuestionStatsLoadable } from './QuestionStats/question-stats.loadable';
import {
  MedicalLabelLoadable,

} from './Settings/MedicalDeviceLabel/medical-device-label.loadable';
import { PATIENT_MD_LABEL_PATHNAME, THERAPIST_MD_LABEL_PATHNAME } from './Settings/MedicalDeviceLabel/paths';
import {
  ORGANISATION_GROUP_PATH,
  OrganisationGroupLoadable,
} from './Settings/organisation-group/organisation-group.loadable.js';
import {
  ORGANISATION_SETTINGS_PATHNAME,
  OrganisationSettingsLoadable,
} from './Settings/organisation-settings/organisation-settings.loadable';
import {
  GENERIC_PASSWORD_RESET_PATHNAME,
  NOTIFICATION_SETTINGS_PATHNAME,
  ACTIVATION_CODES_PATHNAME,
  PATIENT_PASSWORD_RESET_PATHNAME, PATIENT_SETTINGS_PATHNAME,
  THERAPIST_NOTIFICATION_SETTINGS_PATHNAME,
  THERAPIST_ORGANISATION_LIST_PATH,
  THERAPIST_PASSWORD_RESET_PATHNAME, THERAPIST_SETTINGS_PATHNAME,
} from './Settings/paths';
import {
  AppSettingsLoadable,
  NotificationSettingsLoadable,
  ActivationCodesLoadable,
  PasswordResetLoadable, OrganisationsListLoadable,
} from './Settings/settings.loadable';
import { STAT_SINGLE_PATHNAME } from './Stats/paths';
import { STAT_LIST_PATHNAME, StatListLoadable } from './Stats/stat-list.loadable';
import { StatSingleLoadable } from './Stats/stat-single.loadable';
import { STRIPE_SETTINGS_PATHNAME, StripeOAuthLoadable } from './StripeOAuth/stripe-oauth.loadable';
import { PATIENT_PROFILE_PATHNAME, THERAPIST_PROFILE_PATHNAME, UserProfileLoadable } from './UserProfile/profile.loadable';
import {
  PATIENT_CALENDAR_PATHNAME,
  PatientCalendarLoadable,
  THERAPIST_PATIENT_CALENDAR_PATHNAME,
} from './patient-calendar/patient-calendar.loadable';
import { PATIENT_PLANNING_PATHNAME, PatientPlanningLoadable } from './patient-planning/patient-planning.loadable';
import { PROPOSE_FOLLOW_UP_PATH, ProposeFollowUpLoadable } from './propose-follow-up/propose-follow-up.loadable';
import { TherapistPlanningLoadable } from './therapist-planning/therapist-planning.loadable';
import {
  THERAPIST_PLANNING_PATHNAME,
  THERAPIST_PLANNING_PATIENT_CHAT, THERAPIST_PLANNING_PATIENT_STAT_DETAILS,
  THERAPIST_PLANNING_PATIENT_STATS,
  THERAPIST_ROUTES_PREFIX,
} from './therapist-planning/therapist-planning.path';

export default {
  path: '/',
  exact: false,
  component: LockedRouting,
};

function LockedRouting() {
  return (
    <LocationLocker>
      <Routing />
    </LocationLocker>
  );
}

function Routing() {
  // TODO: when switching from desktop to mobile & active route will lose data, ask user
  //  if they want to switch, instead of doing it for them
  const desktopNavigation = useIsDesktopRouting();

  if (desktopNavigation) {
    return <DesktopRouting />;
  }

  return <MobileRouting />;
}

type RoutingState = Nullish<{
  restrictCategory?: boolean,
} & TReplaceExerciseRouteState>;

function exerciseViewShouldHideDock(location: Location<RoutingState>, search: URLSearchParams) {
  return Boolean(search.get('u')) || matchPath(location.pathname, {
    path: P_EXERCISE_CATEGORIES_PATH,
  }) || location.state?.returnWithActivity || Boolean(search.get('p'));
}

function MobileRouting() {
  const disableAnimations = useMediaQuery('(prefers-reduced-motion: reduce)');

  const stackAnimation = srAndroidTransitionSlide;

  const search = useSearchParams();
  const location = useLocation<RoutingState>();
  const addExerciseHasKnownUser = exerciseViewShouldHideDock(location, search);

  return (
    <StackRouter transition={disableAnimations ? null : stackAnimation}>
      {/* Exercise view must be set up identically on both desktop & mobile, because it has a video in it. */}
      {/* If a user changes the screen orientation to view the video, the page should not reset */}
      {/* some phone heights are > 700px */}
      {renderCommonFirstRoutes()}

      <Route path={T_CHALLENGE_PACK_PATH} exact>
        <ChallengePackLazy />
      </Route>
      <Route path={STRIPE_SETTINGS_PATHNAME} exact>
        <StripeOAuthLoadable />
      </Route>
      <Route path={PATIENT_CHAT_PATHNAME} exact>
        <ChatLoadable />
      </Route>
      <Route path={PATIENT_QUESTION_STATS_PATHNAME} exact>
        <QuestionStatsLoadable />
      </Route>
      <Route path={STAT_SINGLE_PATHNAME} exact>
        <StatSingleLoadable />
      </Route>
      <Route path={[PATIENT_PASSWORD_RESET_PATHNAME, THERAPIST_PASSWORD_RESET_PATHNAME]} exact>
        <PasswordResetLoadable />
      </Route>
      <Route path={[NOTIFICATION_SETTINGS_PATHNAME, THERAPIST_NOTIFICATION_SETTINGS_PATHNAME]} exact>
        <NotificationSettingsLoadable />
      </Route>
      <Route path={[THERAPIST_MD_LABEL_PATHNAME, PATIENT_MD_LABEL_PATHNAME]} exact>
        <MedicalLabelLoadable />
      </Route>
      {addExerciseHasKnownUser && (
        <Route
          path={[
            P_EXERCISE_CATEGORIES_PATH,
            T_EXERCISE_CATEGORIES_PATH,
          ]}
          exact
        >
          <ActivityCategoriesLazy />
        </Route>
      )}
      {addExerciseHasKnownUser && (
        <Route path={T_CHALLENGE_PACKS_PATH} exact>
          <ChallengePacksLazy />
        </Route>
      )}
      <RouteGroup
        shouldTransitionBetween={(a: Location, b: Location) => {
          return removeQuery(a.search, 'filters') !== removeQuery(b.search, 'filters');
        }}
        path={[
          P_EXERCISE_CATEGORY_CONTENTS_PATH,
          T_EXERCISE_CATEGORY_CONTENTS_PATH,
        ]}
        exact
      >
        <ActivitySearchLazy />
      </RouteGroup>
      <Route path={ACTIVATION_CODES_PATHNAME}>
        <ActivationCodesLoadable />
      </Route>
      <Route path={THERAPIST_ORGANISATION_LIST_PATH}>
        <OrganisationsListLoadable />
      </Route>
      <Route path={ORGANISATION_SETTINGS_PATHNAME}>
        <OrganisationSettingsLoadable />
      </Route>

      <RouteGroup
        path={[
          COACH_PATIENT_CHAT_PATHNAME,
          THERAPIST_PLANNING_PATIENT_CHAT,
        ]}
      >
        <CoachPatientLoadable />
      </RouteGroup>

      <TabRouter
        index={THERAPIST_PLANNING_PATHNAME}
        path={[THERAPIST_PLANNING_PATHNAME, THERAPIST_CHAT_LIST_PATHNAME,
          T_CHALLENGE_PACKS_PATH, T_EXERCISE_CATEGORIES_PATH,
          THERAPIST_SETTINGS_PATHNAME]}
        exact
      >
        <PageWithDock>
          <Route path={THERAPIST_PLANNING_PATHNAME} exact>
            <TherapistPlanningLoadable />
          </Route>
          <Route path={[THERAPIST_CHAT_LIST_PATHNAME]} exact>
            <ChatListLoadable />
          </Route>
          {!addExerciseHasKnownUser && (
            <Route path={T_CHALLENGE_PACKS_PATH} exact>
              <ChallengePacksLazy noBack />
            </Route>
          )}
          {!addExerciseHasKnownUser && (
            <Route path={[T_EXERCISE_CATEGORIES_PATH]} exact>
              <ActivityCategoriesLazy noBack />
            </Route>
          )}
          <Route path={THERAPIST_SETTINGS_PATHNAME} exact>
            <AppSettingsLoadable />
          </Route>
        </PageWithDock>
      </TabRouter>
      <TabRouter
        index={PATIENT_PLANNING_PATHNAME}
        path={[PATIENT_PLANNING_PATHNAME, PATIENT_CHAT_LIST_PATHNAME, STAT_LIST_PATHNAME, PATIENT_SETTINGS_PATHNAME]}
        exact
      >
        <PageWithDock>
          <Route path={PATIENT_PLANNING_PATHNAME} exact>
            <PatientPlanningLoadable />
          </Route>

          <Route path={STAT_LIST_PATHNAME} exact>
            <StatListLoadable noBack />
          </Route>

          <Route path={PATIENT_CHAT_LIST_PATHNAME} exact>
            <ChatListLoadable />
          </Route>

          <Route path={PATIENT_SETTINGS_PATHNAME} exact>
            <AppSettingsLoadable />
          </Route>
        </PageWithDock>
      </TabRouter>
      {renderCommonLastRoutes()}
    </StackRouter>
  );
}

function DesktopRouting() {

  const search = useSearchParams();
  const location = useLocation<RoutingState>();
  const addExerciseHasKnownUser = exerciseViewShouldHideDock(location, search);

  const hideActivityCategories = location.state?.restrictCategory === true;

  // this hack allows to have two versions of the "Activity Library" page
  // if addExerciseHasKnownUser: it means we're trying to send an activity to a known user, we come from the chat, or we're a patient.
  //  this version should not have a Side navigation (use back button instead).
  // if !addExerciseHasKnownUser: the therapist is accessing the page from the Side navigation
  //  the side navigation should remain.
  const exerciseLibraryRoute = (
    <Route
      path={[
        P_EXERCISE_CATEGORIES_PATH,
        T_EXERCISE_CATEGORIES_PATH,
      ]}
    >
      <MultiPageView>
        {!hideActivityCategories && (
          <MultiPageIndex>
            {/* TODO: rename "ExerciseCategoryListLazy" or "ExerciseLibraryLazy" */}
            <ActivityCategoriesLazy noBack={!addExerciseHasKnownUser} />
          </MultiPageIndex>
        )}

        <Route
          path={[
            P_EXERCISE_CATEGORY_CONTENTS_PATH,
            T_EXERCISE_CATEGORY_CONTENTS_PATH,
          ]}
        >
          <MultiPageMain>
            {/* TODO: rename "ExerciseCategoryContentsLazy" */}
            <ActivitySearchLazy noBack={!hideActivityCategories} />
          </MultiPageMain>
        </Route>
      </MultiPageView>
    </Route>
  );

  const challengePacksRoute = (
    <Route path={T_CHALLENGE_PACKS_PATH}>
      <MultiPageView>
        <MultiPageIndex>
          <ChallengePacksLazy noBack={!hideActivityCategories} />
        </MultiPageIndex>
        <Route path={T_CHALLENGE_PACK_PATH} exact>
          <MultiPageMain>
            <ChallengePackLazy noBack />
          </MultiPageMain>
        </Route>
      </MultiPageView>
    </Route>
  );

  return (
    <StackRouter transition={null}>
      {/* routes that are single-view like on mobile */}
      {renderCommonFirstRoutes()}

      {/* the "find exercise" page */}
      {addExerciseHasKnownUser && exerciseLibraryRoute}
      {addExerciseHasKnownUser && challengePacksRoute}

      {/* routes that have the Side navigation */}
      <Route
        path={[
          // PATIENT
          PATIENT_PLANNING_PATHNAME,
          PATIENT_CHAT_LIST_PATHNAME,
          STAT_LIST_PATHNAME,

          PATIENT_CHAT_PATHNAME,
          PATIENT_QUESTION_STATS_PATHNAME,
          STAT_SINGLE_PATHNAME,

          P_EXERCISE_CATEGORIES_PATH,
          P_EXERCISE_CATEGORY_CONTENTS_PATH,

          // PATIENT SETTINGS
          PATIENT_SETTINGS_PATHNAME,
          PATIENT_PASSWORD_RESET_PATHNAME,
          NOTIFICATION_SETTINGS_PATHNAME,
          ACTIVATION_CODES_PATHNAME,
          PATIENT_MD_LABEL_PATHNAME,

          // THERAPIST
          THERAPIST_PLANNING_PATHNAME,
          THERAPIST_PLANNING_PATIENT_CHAT,
          THERAPIST_PLANNING_PATIENT_STATS,
          THERAPIST_PLANNING_PATIENT_STAT_DETAILS,

          THERAPIST_CHAT_LIST_PATHNAME,
          COACH_PATIENT_CHAT_PATHNAME,
          COACH_PATIENT_STAT_PATHNAME,
          COACH_PATIENT_STAT_PATH_DETAILS,

          ...(addExerciseHasKnownUser ? [] : [
            T_EXERCISE_CATEGORIES_PATH,
            T_EXERCISE_CATEGORY_CONTENTS_PATH,
            T_CHALLENGE_PACKS_PATH,
            T_CHALLENGE_PACK_PATH,
          ]),

          // THERAPIST SETTINGS
          THERAPIST_SETTINGS_PATHNAME,
          STRIPE_SETTINGS_PATHNAME,
          THERAPIST_PASSWORD_RESET_PATHNAME,
          THERAPIST_NOTIFICATION_SETTINGS_PATHNAME,
          THERAPIST_ORGANISATION_LIST_PATH,
          THERAPIST_MD_LABEL_PATHNAME,
        ]}
        exact
      >
        <PageWithDock>
          <Route path={PATIENT_PLANNING_PATHNAME} exact>
            <MultiPageView>
              <MultiPageMain>
                <PatientPlanningLoadable />
              </MultiPageMain>
            </MultiPageView>
          </Route>

          <Route path={THERAPIST_PLANNING_PATHNAME}>
            <MultiPageView>
              <MultiPageIndex>
                <TherapistPlanningLoadable />
              </MultiPageIndex>
              <Route path={THERAPIST_PLANNING_PATIENT_CHAT}>
                <MultiPageMain>
                  <CoachPatientLoadable noBack />
                </MultiPageMain>
              </Route>
            </MultiPageView>
          </Route>

          <Route path={THERAPIST_CHAT_LIST_PATHNAME}>
            <MultiPageView>
              <MultiPageIndex>
                <ChatListLoadable />
              </MultiPageIndex>
              <Route path={COACH_PATIENT_CHAT_PATHNAME}>
                <MultiPageMain>
                  <CoachPatientLoadable noBack />
                </MultiPageMain>
              </Route>
            </MultiPageView>
          </Route>

          <Route path={STAT_LIST_PATHNAME}>
            <MultiPageView>
              <MultiPageIndex>
                <StatListLoadable noBack />
              </MultiPageIndex>

              <Switch>
                <Route path={PATIENT_QUESTION_STATS_PATHNAME} exact>
                  <MultiPageMain>
                    <QuestionStatsLoadable />
                  </MultiPageMain>
                </Route>
                <Route path={STAT_SINGLE_PATHNAME} exact>
                  <MultiPageMain>
                    <StatSingleLoadable noBack />
                  </MultiPageMain>
                </Route>
              </Switch>
            </MultiPageView>
          </Route>

          {(() => {
            const liveConversationRoute = new ParameterisedPath(PATIENT_CHAT_LIST_PATHNAME).pathMatcher(path => {
              return new URLSearchParams(path.search).get('pending') !== 'y';
            });

            const pendingConversationRoute = new ParameterisedPath(PATIENT_CHAT_LIST_PATHNAME).pathMatcher(path => {
              return new URLSearchParams(path.search).get('pending') === 'y';
            });

            return (
              <TabRouter
                index={liveConversationRoute}
                path={[pendingConversationRoute, liveConversationRoute]}
              >
                <Route path={PATIENT_CHAT_LIST_PATHNAME}>
                  <MultiPageView>
                    <MultiPageIndex>
                      <ChatListLoadable />
                    </MultiPageIndex>

                    <Route path={PATIENT_CHAT_PATHNAME} exact>
                      <MultiPageMain>
                        <ChatLoadable noBack />
                      </MultiPageMain>
                    </Route>
                  </MultiPageView>
                </Route>
              </TabRouter>
            );
          })()}

          {!addExerciseHasKnownUser && exerciseLibraryRoute}
          {!addExerciseHasKnownUser && challengePacksRoute}

          <TabRouter
            index={PATIENT_SETTINGS_PATHNAME}
            path={[
              PATIENT_SETTINGS_PATHNAME,
              STRIPE_SETTINGS_PATHNAME,
              PATIENT_PASSWORD_RESET_PATHNAME,
              NOTIFICATION_SETTINGS_PATHNAME,
              ACTIVATION_CODES_PATHNAME,
              PATIENT_MD_LABEL_PATHNAME,

              THERAPIST_SETTINGS_PATHNAME,
              THERAPIST_PASSWORD_RESET_PATHNAME,
              THERAPIST_NOTIFICATION_SETTINGS_PATHNAME,
              THERAPIST_ORGANISATION_LIST_PATH,
              THERAPIST_MD_LABEL_PATHNAME,
            ]}
          >
            <MultiPageView>
              <MultiPageIndex>
                <AppSettingsLoadable noBack />
              </MultiPageIndex>

              <MultiPageMain>
                <Route path={STRIPE_SETTINGS_PATHNAME} exact>
                  <StripeOAuthLoadable noBack />
                </Route>
                <Route path={[PATIENT_PASSWORD_RESET_PATHNAME, THERAPIST_PASSWORD_RESET_PATHNAME]} exact>
                  <PasswordResetLoadable noBack />
                </Route>
                <Route path={[NOTIFICATION_SETTINGS_PATHNAME, THERAPIST_NOTIFICATION_SETTINGS_PATHNAME]} exact>
                  <NotificationSettingsLoadable noBack />
                </Route>
                <Route path={[THERAPIST_MD_LABEL_PATHNAME, PATIENT_MD_LABEL_PATHNAME]} exact>
                  <MedicalLabelLoadable noBack />
                </Route>
                <Route path={ACTIVATION_CODES_PATHNAME}>
                  <ActivationCodesLoadable noBack />
                </Route>
                <Route path={THERAPIST_ORGANISATION_LIST_PATH}>
                  <OrganisationsListLoadable noBack />
                </Route>
              </MultiPageMain>
            </MultiPageView>
          </TabRouter>
        </PageWithDock>
      </Route>
      {renderCommonLastRoutes()}
    </StackRouter>
  );
}

function renderCommonFirstRoutes() {
  return (
    <>
      <Route path={INDEX_PATHNAME} exact>
        <IndexView />
      </Route>

      <Route path={GENERIC_PASSWORD_RESET_PATHNAME} exact>
        <PasswordResetLoadable />
      </Route>

      <Route path={[LOGIN_PATHNAME, REGISTER_PATHNAME]} exact>
        <LoginLoadable />
      </Route>
      <Route path={MAGIC_LOGIN_PATHNAME} exact>
        <MagicLoginLoadable />
      </Route>

      <Route path={[PATIENT_CALENDAR_PATHNAME, THERAPIST_PATIENT_CALENDAR_PATHNAME]} exact>
        <PatientCalendarLoadable />
      </Route>
      <Route path={[PATIENT_PROFILE_PATHNAME, THERAPIST_PROFILE_PATHNAME]} exact>
        <UserProfileLoadable />
      </Route>

      <Route path={THERAPIST_INVITE_PATIENT_PATHNAME} exact>
        <InvitePatientLoadable />
      </Route>
      <Route path={PROPOSE_FOLLOW_UP_PATH} exact>
        <ProposeFollowUpLoadable />
      </Route>
      <Route path={LOGOUT_PATH} exact>
        <LogoutLoadable />
      </Route>

      <Route path={ADMIN_PANEL_PATHNAME} exact>
        <AdminPanelLoadable />
      </Route>

      <Route path={MARKETPLACE_PATHNAME} exact>
        <MarketplaceSearchLoadable />
      </Route>
      <Route path={THERAPIST_PUBLIC_PROFILE} exact>
        <MarketplaceSellerLoadable />
      </Route>
      <Route path={THERAPIST_ADD_QUESTIONNAIRE_PATHNAME} exact>
        <AddQuestionnaireLoadable />
      </Route>
      <Route path={[THERAPIST_CREATE_EXERCISE_PATHNAME, THERAPIST_EDIT_EXERCISE_PATHNAME]} exact>
        <CreateExerciseLoadable />
      </Route>
      <Route
        path={[
          EXERCISE_PATHNAME,
          CHALLENGE_PATHNAME,
          P_CHALLENGE_PROPOSAL_PATH,

          THERAPIST_EXERCISE_PATH,
          THERAPIST_CHALLENGE_PATH,
          T_CHALLENGE_PROPOSAL_PATH,
        ]}
        exact
      >
        <ExerciseLoadable />
      </Route>

      <Route path={P_ADD_CHALLENGE_PATH} exact>
        <AddChallengeLazy />
      </Route>
      <Route path={P_EDIT_CHALLENGE_PATH} exact>
        <EditChallengeLazy />
      </Route>
      <Route path={T_PROPOSE_CHALLENGE_PATH} exact>
        <CreateAddChallengeProposalView />
      </Route>
      <Route path={T_PROPOSE_CHALLENGE_EDITION_PATH} exact>
        <CreateEditChallengeProposalLazy />
      </Route>
      {/* edit both add-challenge proposal & edit-challenge proposal */}
      <Route path={[T_EDIT_CHALLENGE_PROPOSAL_PATH, T_EDIT_CHALLENGE_TEMPLATE_PATH]} exact>
        <UpdateChallengeProposalOrTemplateLazy />
      </Route>
      <Route path={T_SEND_CHALLENGE_PACK_PATH} exact>
        <SendChallengePackLazy />
      </Route>
      <Route path={ORGANISATION_SETTINGS_PATHNAME} exact>
        <OrganisationSettingsLoadable />
      </Route>
      <Route path={ORGANISATION_GROUP_PATH} exact>
        <OrganisationGroupLoadable />
      </Route>
      <Route key={8} path={HELP_PATHNAME} exact>
        <HelpLoadable />
      </Route>
    </>
  );
}

function renderCommonLastRoutes() {
  return (
    [
      <Redirect key={0} path="/.well-known/change-password" to={GENERIC_PASSWORD_RESET_PATHNAME} exact />,
      <Redirect key={3} path={THERAPIST_ROUTES_PREFIX} to={THERAPIST_PLANNING_PATHNAME} exact />,
      <Redirect key={4} path="/therapist" to={THERAPIST_PLANNING_PATHNAME} exact />,
      <Redirect key={1} path="/account/password-reset" to={PATIENT_PASSWORD_RESET_PATHNAME} exact />,
      <Redirect key={2} path="/marketplace/seller/:therapist" to={THERAPIST_PUBLIC_PROFILE} exact />,
      <Redirect key={5} path="/therapist/:route" to="/t/:route" exact />,
      <Redirect key={6} path="/exercise/:exercise" to="/exercise/physiotherapy/:exercise" exact />,
      <Redirect key={7} path="/t/exercise/:exercise" to="/t/exercise/physiotherapy/:exercise" exact />,
      <Redirect key={10} path="/add-challenge" to="/exercise" />,
      <Redirect key={10} path="/t/patient/:user/add-challenge" to="/t/chat/:user" />,
      <Route key={9}>
        <Error404View />
      </Route>,
    ]
  );
}
