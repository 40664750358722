export const MMC_STORAGE_PREFIX = 'mmc:';

export function mmcStorageKey(key: string): string {
  return MMC_STORAGE_PREFIX + key;
}

export function clearStorage(prefix: string, storage: Storage): void {
  for (let i = storage.length - 1; i >= 0; i--) {
    const key = storage.key(i)!;
    if (!key.startsWith(prefix)) {
      continue;
    }

    storage.removeItem(key);
  }
}
