import { defineMessages } from 'react-intl';

export default defineMessages({
  title: { defaultMessage: 'MyMedicoach Encountered a problem.' },
  sorry: { defaultMessage: 'We\'re very sorry about it.{br}We\'re immediately notifying our developers.' },
  reportId: { defaultMessage: 'Report ID: {reportId}' },
  retryBtn: { defaultMessage: 'Restart application' },
  infoSupport: { defaultMessage: 'If you contact the support, give them this info' },
  sentryLoadError: {
    defaultMessage: `Error: We could not notify the development team. It is possible that your adblocker prevented the crash report from being sent.{br}
    You can help us resolve this issue faster by sending us the following crash report by email.`,
  },
});
