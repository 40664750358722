import IconButton from '@material-ui/core/IconButton';
import BackButtonAndroidIcon from '@material-ui/icons/ArrowBack';
import BackButtonIosIcon from '@material-ui/icons/ArrowBackIos';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSearchParams } from '../../hooks/use-search-params';
import { BaseBackButton } from '../../navigation-lib/back-button';
import { isAppleOs } from '../../utils/dom-utils';

export type BackButtonDestination = ((e: React.MouseEvent<HTMLButtonElement>) => void) | string;

type Props = React.ComponentProps<typeof IconButton> & {
  defaultBackPath: BackButtonDestination,
};

const messages = defineMessages({
  backButton: { defaultMessage: 'Previous Page' },
});

export function BackButton(props: Props) {
  const { defaultBackPath, onClick, ...passDown } = props;

  const shouldClose = useSearchParams().get('newTab') != null;
  const intl = useIntl();
  const title = props.title || intl.formatMessage(messages.backButton);

  const Component = React.useMemo(() => {
    return typeof defaultBackPath === 'function' || shouldClose
      ? void 0
      : React.forwardRef((linkProps, ref) => {
        return <BaseBackButton {...linkProps} ref={ref} defaultBackPath={defaultBackPath} />;
      });
  }, [shouldClose, defaultBackPath]);

  const onClickWrapper = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick) {
      onClick(e);
    }

    if (e.defaultPrevented) {
      return;
    }

    if (shouldClose) {
      window.close();
    }

    if (typeof defaultBackPath === 'function') {
      defaultBackPath(e);
    }
  }, [defaultBackPath, onClick, shouldClose]);

  return (
    <IconButton
      {...passDown}
      title={title}
      onClick={onClickWrapper}
      component={Component}
    >
      {isAppleOs() ? <BackButtonIosIcon fontSize="inherit" style={{ transform: 'translateX(25%)' }} /> : <BackButtonAndroidIcon fontSize="inherit" />}
    </IconButton>
  );
}
