import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';
import { THERAPIST_EXERCISE_PATH } from '../Exercise/exercise.path';
import { THERAPIST_ROUTES_PREFIX } from '../therapist-planning/therapist-planning.path';

// export const CREATE_EXERCISE_PATHNAME = '/';
// export const EDIT_EXERCISE_PATHNAME = '/';
export const THERAPIST_CREATE_EXERCISE_PATHNAME = `${THERAPIST_ROUTES_PREFIX}/exercise/:categorySlug/create`;
export const THERAPIST_EDIT_EXERCISE_PATHNAME = `${THERAPIST_EXERCISE_PATH}/edit`;

export const CreateExerciseLoadable = loadable(async () => import('./create-exercise.view'), {
  fallback: <FullPageLoading />,
});
