import argv from '@reworkjs/core/argv';
import { assertString } from '../utils/utils';

const getHostnameProtocol = (protocol?: string): 'http:' | 'https:' => {
  switch (protocol) {
    // Android / Web App Frontend
    case 'https:': return 'https:';
    // Local development
    case 'http:': return 'http:';
    // iOS app returns 'file:'
    case 'file:': return 'https:';
    // Security first ! Shouldn' happen.
    default: return 'https:';
  }

};

if (/https?:\/\//.test(argv.apiUrl)) {
  throw new Error('\'Expected remote API URL to be scheme-free and not start with http or https scheme');
}

const protocol = getHostnameProtocol(globalThis?.location?.protocol);

export const HOSTNAME: string = `${protocol}//${argv.apiUrl}`;

assertString(HOSTNAME);
