import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';

export const AppSettingsLoadable = loadable(async () => import('./Menu/settings-menu.view'), {
  fallback: <FullPageLoading />,
});

export const PasswordResetLoadable = loadable(async () => import('./PasswordReset/password-reset.view'), {
  fallback: <FullPageLoading />,
});

export const NotificationSettingsLoadable = loadable(async () => import('./NotificationSettings/notification-settings.view'), {
  fallback: <FullPageLoading />,
});

export const ActivationCodesLoadable = loadable(async () => import('./activation-codes/use-activation-codes.view.js'), {
  fallback: <FullPageLoading />,
});

export const OrganisationsListLoadable = loadable(async () => import('./organisations/organisations.view'), {
  fallback: <FullPageLoading />,
});
