import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';
import { THERAPIST_ROUTES_PREFIX } from '../therapist-planning/therapist-planning.path';

export const PATIENT_PROFILE_PATHNAME = '/user/:user';
export const THERAPIST_PROFILE_PATHNAME = `${THERAPIST_ROUTES_PREFIX}/user/:user`;

export const UserProfileLoadable = loadable(async () => import('./profile.view'), {
  fallback: <FullPageLoading />,
});
