import type { Client, OperationContext } from '@urql/core';
import { fetchExchange, gql } from '@urql/core';
import type { Cache, Entity, Variables as GqlVariables } from '@urql/exchange-graphcache';
import { cacheExchange } from '@urql/exchange-graphcache';
import type { FieldInfo } from '@urql/exchange-graphcache/dist/types/types.js';
import { relayPagination } from '@urql/exchange-graphcache/extras';
import type { DocumentNode } from 'graphql';
import { createClient as createWSClient } from 'graphql-ws';
import type { ReactNode } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { UseQueryArgs, UseQueryState } from 'urql';
import {
  createClient,
  useQuery as useUrqlQuery,
  useMutation as useUrqlMutation,
  Provider as UrqlProvider,
  subscriptionExchange,
} from 'urql';
import { v4 as uuidV4 } from 'uuid';
import { assert } from '../../shared-library/assert';
import type { SuccessResponse, TMutationResponse } from '../../shared-library/graphql.js';
import { getPayloadError, processMutationData } from '../../shared-library/graphql.js';
import type { Nullish } from '../../shared-library/types.js';
import { createTrappedCallable } from '../../utils/dev-utils';
import { randomId } from '../../utils/utils.js';
import { HOSTNAME } from '../globals';
import { MessageHistoryDocument, ViewerDocument } from '../graphql-typings.js';
import type {
  TAddChallengeTemplateToPackMutation, TAddChallengeTemplateToPackMutationVariables,
  TAddMemberToGroupMutation,
  TArchivePackMutation,
  TArchivePackMutationVariables,
  TCreateChallengePackMutation,
  TDeleteGroupMutation,
  TGeneratePackCodesMutation,
  TGeneratePackCodesMutationVariables,
  TMutationAddMemberToGroupArgs,
  TMutationCreateChallengeTemplatePackArgs,
  TMutationDeleteGroupArgs,
  TMutationRemoveMemberFromGroupArgs,
  TRemoveChallengeFromPackMutation, TRemoveChallengeFromPackMutationVariables,
  TRemoveMemberFromGroupMutation, TToggleCodeRevokedMutation, TToggleCodeRevokedMutationVariables,
  TSendMessageMutationVariables, TSendMessageMutation, TMessage, TAnswerQuestionsPayload, TMutationAnswerQuestionsArgs,
  TConfigurePatientAlertsPayload, TMutationConfigurePatientAlertsArgs,
} from '../graphql-typings.js';
import graphqlSchema from '../graphql.json';
import { getAuthToken } from '../token';

const QUERY_ENTITY = Object.freeze({ __typename: 'Query' });

const OPTIMISTIC_MESSAGE_ID_PREFIX = 'M-optimistic-';

export function isOptimisticMessage(message: Pick<TMessage, 'id'>) {
  return message.id.startsWith(OPTIMISTIC_MESSAGE_ID_PREFIX);
}

function createUrqlClient() {
  const connectionId = uuidV4();
  // If MMC_API_URL is reachable with the http scheme we have to open an insecure (ws) connection
  // If MMC_API_URL is reachable with the https scheme we have to open an insecure (wss) connection
  const websocketScheme = HOSTNAME.startsWith('https://') ? 'wss://' : 'ws://';

  const wsClient = createWSClient({
    url: `${HOSTNAME.replace(/https?:\/\//, websocketScheme)}/graphql`,
    // webSocketImpl: ws,
    generateID: () => uuidV4(),
    // We use a function to establish connectionParams because the Auth token
    // Is not already set on first app load (concurrency ?)
    connectionParams: async () => ({
      authToken: getAuthToken()?.replace(/bearer /i, ''),
      connectionId,
    }),
  });

  const client = createClient({
    url: `${HOSTNAME}/graphql`,
    requestPolicy: 'cache-and-network',
    fetchOptions: () => {
      const token = getAuthToken();

      return {
        headers: {
          authorization: token ?? '',
          'x-mmc-connection-id': connectionId,
        },
      };
    },
    exchanges: [
      cacheExchange({
        schema: graphqlSchema,
        keys: {
          GenericUserTherapistAlert: () => null,
          TherapistPatientAlerts: () => null,
          QuestionAnswerAlert: () => null,
          FollowUpNeededAlert: () => null,
          CoachingSubscriptionRecurrence: () => null,
          QuestionLocalization: () => null,
          ExerciseLocalization: () => null,
          AssetFormat: () => null,
        },
        // don't use storage for now, urql doesn't provide a way to clear the data on logout!
        // storage: makeDefaultStorage({ idbName: 'mmc-data' }),
        resolvers: {
          Query: {
            organisations: relayPagination(),
          },
        },
        optimistic: {
          // TEMP: optimistic update is disabled because there is a conflict with cache invalidation
          // TODO : find solution to make optimistic update & cache invalidation work after mutation
          sendMessage({ input }: TSendMessageMutationVariables, cache: Cache) {
            const { content } = input;

            const viewer = cache.readQuery({ query: ViewerDocument })?.user;
            if (!viewer) {
              console.error('cache update skipped: called sendMessage, but viewer is missing from cache');

              return null;
            }

            const sentAt = (new Date()).toISOString();

            return {
              __typename: 'SendMessagePayload',
              error: null,
              node: {
                __typename: 'TextMessage',
                id: `${OPTIMISTIC_MESSAGE_ID_PREFIX}${randomId()}`,
                messageType: 'TEXT',
                sentAt,
                viewerReadAt: sentAt,
                content,
                author: viewer,
              },
            };
          },
        },
        updates: {
          Mutation: {
            sendMessage(result: TSendMessageMutation, { input }: TSendMessageMutationVariables, cache: Cache) {
              const { conversation: conversationId } = input;

              // Invalidate therapist patient alerts
              // TODO: Could be improved by modifying the cache data instead of than invalidating the query
              invalidateFieldIgnoreArgs(cache, QUERY_ENTITY, 'therapistPatientAlerts');

              const createdMessage = result.sendMessage.node;
              if (!createdMessage) {
                return;
              }

              // Update Query.messages
              for (const field of getFields(cache, QUERY_ENTITY, 'messages')) {
                if (!field.arguments) {
                  continue;
                }

                if (field.arguments.conversation !== conversationId) {
                  continue;
                }

                // 'after' is used to scroll up. We want to add this message at the very bottom of the page
                if (field.arguments.after) {
                  continue;
                }

                const pageData = cache.readQuery({
                  query: MessageHistoryDocument,
                  variables: field.arguments,
                });

                // append at the very bottom: aka the page that has no 'previous' page (first page is at the very bottom)
                if (pageData?.messages?.pageInfo.hasPreviousPage !== false) {
                  continue;
                }

                cache.updateQuery({
                  query: MessageHistoryDocument,
                  variables: field.arguments,
                }, data => {
                  if (!data?.messages?.nodes) {
                    return null;
                  }

                  data.messages.nodes.unshift(createdMessage);

                  return data;
                });
              }

              // update conversation list
              for (const entity of unsafeGetAllEntitiesOfType(cache, 'Conversation')) {
                if (entity.id !== conversationId) {
                  continue;
                }

                for (const messageField of getFields(cache, { __typename: entity.__typename, id: entity.id }, 'messages')) {
                  if (!messageField.arguments) {
                    continue;
                  }

                  if (messageField.arguments.first !== 1 || messageField.arguments.after) {
                    continue;
                  }

                  const connectionEntityId = cache.resolve(entity, messageField.fieldName, messageField.arguments);

                  cache.link(connectionEntityId, 'nodes', [createdMessage]);
                }
              }
            },
            generatePackCodes(result: TGeneratePackCodesMutation, args: TGeneratePackCodesMutationVariables, cache: Cache) {
              if (getPayloadError(result)) {
                return;
              }

              invalidateFieldIgnoreArgs(cache, {
                __typename: 'ChallengeTemplatePack',
                id: args.input.pack,
              }, 'codeFolders');

              invalidateFieldMatchArgs(cache, {
                __typename: 'Query',
              }, 'packCodeFolders', queryArgs => {
                return queryArgs != null && queryArgs.pack === args.input.pack;
              });
            },
            toggleCodeRevoked(result: TToggleCodeRevokedMutation, args: TToggleCodeRevokedMutationVariables, cache: Cache) {
              if (getPayloadError(result)) {
                return;
              }

              // when revoking a *folder*, we revoke individual codes it contains
              const nodeRes = result.toggleCodeRevoked.node;
              if (!nodeRes) {
                return;
              }

              if (nodeRes.__typename === 'ActivationCodeFolder') {
                const node = {
                  __typename: nodeRes.__typename,
                  id: nodeRes.id,
                };

                const parentPackIds = new Set<string>();
                for (const packEntity of unsafeGetAllEntitiesOfType(cache, 'ChallengeTemplatePack')) {
                  // TODO: check if ActivationCodeFolder is present in packEntity.codeFolders (discard if not)
                  // @ts-expect-error
                  parentPackIds.add(packEntity.id);
                }

                for (const field of getFields(cache, QUERY_ENTITY, 'packCodeFolders')) {
                  // @ts-expect-error
                  const packId: Nullish<string> = field.arguments?.pack;
                  if (!packId) {
                    continue;
                  }

                  // TODO: check if ActivationCodeFolder is present in this connection (discard if not)

                  parentPackIds.add(packId);
                }

                // invalide Query.packCodeFolders(onlyValid: true)
                invalidateFieldMatchArgs(cache, QUERY_ENTITY, 'packCodeFolders', arg => {
                  if (arg == null) {
                    return false;
                  }

                  // @ts-expect-error
                  return arg.onlyActive === true && parentPackIds.has(packId);
                });

                // invalidate ChallengeTemplatePack.codeFolders(onlyValid: true)
                for (const packId of parentPackIds) {
                  invalidateFieldMatchArgs(cache, {
                    __typename: 'ChallengeTemplatePack',
                    id: packId,
                  }, 'codeFolders', arg => {
                    return arg != null && arg.onlyActive === true;
                  });
                }

                // set ".revoked" property on ActivationCodeFolder.codes.nodes[x]
                for (const field of getFields(cache, node, 'codes')) {
                  // get ActivationCodeFolder.codes entity ID
                  const connectionEntityId = cache.resolve(node, field.fieldName, field.arguments);

                  // get ActivationCodeFolder.codes contents
                  const data = cache.readFragment(gql`
                    fragment _ on ActivationCodeConnection {
                      nodes {
                        id
                        revoked
                      }
                    }
                  `, connectionEntityId);

                  if (data == null) {
                    return;
                  }

                  // update ActivationCodeFolder.codes.nodes[x]
                  for (const codeNode of data.nodes) {
                    cache.writeFragment(gql`
                      fragment _ on ActivationCode {
                        id
                        revoked
                      }
                    `, {
                      id: codeNode.id,
                      revoked: args.input.revoked,
                    });
                  }
                }
              }
            },
            createChallengeTemplatePack(
              result: TCreateChallengePackMutation,
              _args: TMutationCreateChallengeTemplatePackArgs,
              cache: Cache,
            ) {
              if (getPayloadError(result)) {
                return;
              }

              cache.invalidate(QUERY_ENTITY, 'challengeTemplatePacks', { archived: false });
            },
            toggleChallengePackArchived(result: TArchivePackMutation, _args: TArchivePackMutationVariables, cache: Cache) {
              if (result.toggleChallengePackArchived.error != null) {
                return;
              }

              // invalidate both archived and unarchived
              invalidateFieldIgnoreArgs(cache, QUERY_ENTITY, 'challengeTemplatePacks');
            },
            addChallengeTemplateToPack(
              result: TAddChallengeTemplateToPackMutation,
              args: TAddChallengeTemplateToPackMutationVariables,
              cache: Cache,
            ) {
              if (getPayloadError(result)) {
                return;
              }

              cache.invalidate({
                __typename: 'ChallengeTemplatePack',
                id: args.input.pack,
              }, 'challenges');
            },
            removeChallengeTemplateFromPack(
              result: TRemoveChallengeFromPackMutation,
              args: TRemoveChallengeFromPackMutationVariables,
              cache: Cache,
            ) {
              if (getPayloadError(result)) {
                return;
              }

              cache.invalidate({
                __typename: 'ChallengeTemplate',
                id: args.input.challengeTemplate,
              });
            },
            removeMemberFromGroup(
              result: TRemoveMemberFromGroupMutation,
              args: TMutationRemoveMemberFromGroupArgs,
              cache: Cache,
            ) {
              // mutation failed
              if (getPayloadError(result)) {
                return;
              }

              invalidateFieldIgnoreArgs(cache, {
                __typename: 'Group',
                id: args.input.group,
              }, 'members');
            },
            addMemberToGroup(result: TAddMemberToGroupMutation, args: TMutationAddMemberToGroupArgs, cache: Cache) {
              // mutation failed
              if (getPayloadError(result)) {
                return;
              }

              invalidateFieldIgnoreArgs(cache, {
                __typename: 'Group',
                id: args.input.group,
              }, 'members');
            },
            deleteGroup(result: TDeleteGroupMutation, args: TMutationDeleteGroupArgs, cache) {
              // mutation failed
              if (getPayloadError(result)) {
                return;
              }

              cache.invalidate({
                __typename: 'Group',
                id: args.input.group,
              });
            },
            answerQuestions(result: TAnswerQuestionsPayload, args: TMutationAnswerQuestionsArgs, cache) {
              invalidateFieldIgnoreArgs(cache, QUERY_ENTITY, 'assignedQuestions');
            },
            configurePatientAlerts(
              result: TConfigurePatientAlertsPayload,
              args: TMutationConfigurePatientAlertsArgs,
              cache,
            ) {
              invalidateFieldMatchArgs(cache, QUERY_ENTITY, 'user', arg => {
                return Boolean(arg && arg.id === args.input?.patient);
              });
            },
          },
        },
      }),
      // *must* be placed after cacheExchange
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: operation => ({
          subscribe: sink => ({
            unsubscribe: wsClient.subscribe(operation, sink),
          }),
        }),
      }),
    ],
  });

  return { client, id: connectionId };
}

type TExtendedUrqlClient = {
  id: string,
  client: Client,
  reset(clearCache: boolean): void,
};

const UrqlClientContext = createContext<TExtendedUrqlClient>(createTrappedCallable('UrqlClientContext'));

type TUrqlClientProviderProps = {
  children: ReactNode,
};

export function UrqlClientProvider(props: TUrqlClientProviderProps) {

  const [clientInfo, setClientInfo] = useState(() => createUrqlClient());

  const resetClient = useCallback((_clearCache: boolean) => {
    // TODO: clear storage if _clearCache is set
    setClientInfo(createUrqlClient());
  }, []);

  const clientContextData = useMemo(() => {
    return {
      ...clientInfo,
      reset: resetClient,
    };
  }, [clientInfo, resetClient]);

  return (
    <UrqlClientContext.Provider value={clientContextData}>
      <UrqlProvider value={clientInfo.client}>
        {props.children}
      </UrqlProvider>
    </UrqlClientContext.Provider>
  );
}

export function useUrqlClientInfo(): TExtendedUrqlClient {
  return useContext(UrqlClientContext);
}

function *unsafeGetAllEntitiesOfType(cache: Cache, typeName: string): IterableIterator<{ __typename: string }> {
  //  https://github.com/FormidableLabs/urql/discussions/1251
  // @ts-expect-error using an undocumented API, this could break
  const recordsMap: Map<string, { __typename: string }> = cache.data.records.base;
  for (const value of recordsMap.values()) {
    if (value.__typename === typeName) {
      yield value;
    }
  }
}

function *getFields(cache: Cache, entity: Entity, fieldName: string): IterableIterator<FieldInfo> {
  for (const field of cache.inspectFields(entity)) {
    if (field.fieldName === fieldName) {
      yield field;
    }
  }
}

function invalidateFieldMatchArgs(
  cache: Cache,
  entity: Entity,
  fieldName: string,
  argMatcher: ((arg: GqlVariables | null) => boolean),
): void {
  for (const field of getFields(cache, entity, fieldName)) {
    if (argMatcher(field.arguments)) {
      cache.invalidate(entity, fieldName, field.arguments);
    }
  }
}

const returnTrue = () => true;

function invalidateFieldIgnoreArgs(cache: Cache, entity: Entity, fieldName: string): void {
  invalidateFieldMatchArgs(cache, entity, fieldName, returnTrue);
}

export type TRevalidate = (opts?: Partial<OperationContext>) => void;
export type TUseQueryOutput<Data, Variables> = UseQueryState<Data, Variables> & { revalidate: TRevalidate };

export function useQuery<Data = any, Vars = object>(args: UseQueryArgs<Vars, Data>): TUseQueryOutput<Data, Vars> {
  const [res, revalidate] = useUrqlQuery(args);

  // @ts-expect-error
  return useMemo(() => {
    // @ts-expect-error
    res.revalidate = revalidate;

    return res;
  }, [res, revalidate]);
}

type TMutationVariables = {
  input: object,
};

export type TCallMutation<Variables, Payload> = (
  variables?: Variables,
  context?: Partial<OperationContext>,
) => Promise<Payload>;

export function useMutation<Response extends TMutationResponse,
  Variables extends TMutationVariables,
  >(documentNode: DocumentNode): TCallMutation<Variables['input'], SuccessResponse<Response>> {
  const [, callMutation] = useUrqlMutation<Response, { input: Variables['input'] }>(documentNode);

  return useCallback(async (input: Variables['input']): Promise<SuccessResponse<Response>> => {
    const result = await callMutation({ input });

    if (result.error) {
      throw result.error;
    }

    assert(result.data != null);

    return processMutationData(result.data);
  }, [callMutation]);
}

export { useSubscription, useClient } from 'urql';
export type { UseSubscriptionArgs, SubscriptionHandler, UseQueryArgs } from 'urql';
