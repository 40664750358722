import { createContext, useRef, useContext } from 'react';
import { createTrappedCallable } from '../utils/dev-utils';

const SsrGeneratedIdContext = createContext(createTrappedCallable());

export function SsrGeneratedIdProvider(props: Props) {

  const context = useRef(null);
  if (context.current == null) {
    const newContext = {
      _lastId: 0,
      nextId() {
        return newContext._lastId++;
      },
    };

    context.current = newContext;
  }

  return (
    <SsrGeneratedIdContext.Provider value={context.current}>
      {props.children}
    </SsrGeneratedIdContext.Provider>
  );
}

export default function useId(override) {
  const context = useContext(SsrGeneratedIdContext);
  const id = useRef(null);
  if (id.current == null) {
    id.current = context.nextId();
  }

  if (override) {
    return override;
  }

  return `gen91f229-${id.current}`;
}
