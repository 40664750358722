import type { ComponentProps } from 'react';
import { defineMessage, FormattedMessage } from 'react-intl';

export const unexpectedErrorMessage = defineMessage({ defaultMessage: 'An unexpected error occurred.' });

type Props = Partial<ComponentProps<typeof FormattedMessage>>;

/**
 * This component returns the localized error message descriptor.
 * If no message descriptor is passed, a localized "Unexpected error" message is rendered.
 *
 * This components prevents the app from crashing if there is no message descriptor for a given error (in such cases as the server throwing a new type of error).
 */
export default function ErrorMessage(props: Props) {
  // TODO in case of unexpected error, send data to error tracker.

  return <FormattedMessage {...unexpectedErrorMessage} {...props} />;
}
