import { createTheme as createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { ActiveLocaleContext } from '@reworkjs/core/active-locale-context';
import { isTranslationSupported } from '@reworkjs/core/i18n';
import bind from 'lodash-decorators/bind';
import type { ReactNode } from 'react';
import { Component, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import type { IntlShape } from 'react-intl';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import type { TViewerFragment } from '../../api/graphql-typings.js';
import { UrqlClientProvider } from '../../api/urql/urql';
import { SsrGeneratedIdProvider } from '../../hooks/use-id';
import { useIsOnline } from '../../hooks/use-is-online';
import { RenderViewportHeight } from '../../hooks/viewport-height';
import { withHooks } from '../../hooks/with-hooks';
import { HistoryTrackerProvider } from '../../navigation-lib/history-tracker';
import { SITE_NAME } from '../../services/app-globals.js';
import { initPushNotifications } from '../../services/push-manager';
import { DEFAULT_FONTS } from '../../theme';
import { getScrollbarWidth } from '../../utils/dom-utils';
import { watchProp } from '../../utils/prop-watcher';
import type { IActiveLocaleContext } from '../../utils/types';
import { categoryClasses } from '../CategoryColors';
import CrashHandler from '../CrashHandler';
import { ErrorDisplay } from '../ErrorDisplay/error-display';
import GoogleTagManager from '../GoogleTagManager';
import LongPressTitleHandler from '../LongPressTitleHandler';
import { PromptProvider } from '../Prompts/use-prompt.js';
import { NotificationProvider, useLiveNotificationCount } from '../notification-manager.js';
import { SnackbarProvider } from '../snackbar/snackbar';
import type { IViewerContext } from '../viewer-context';
import { useViewer, ViewerProvider } from '../viewer-context';
import { WeightedHelmetProvider } from '../weighted-helmet/weighted-helmet';
import messages from './messages';
import colorPalette from './palette.module.scss';
import './app.global.scss';

type Props = {
  children: ReactNode,
  online: boolean,
  intl: IntlShape,
  locale: IActiveLocaleContext,
  viewer: IViewerContext,
};

void initPushNotifications();

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: DEFAULT_FONTS,
  },
  palette: {
    primary: {
      main: colorPalette.primaryDark,
    },
    secondary: {
      main: colorPalette.primaryLight,
    },
    error: {
      main: colorPalette.errorDark,
    },
    warning: {
      main: colorPalette.warningDark,
    },
    info: {
      main: colorPalette.infoDark,
    },
    success: {
      main: colorPalette.successDark,
    },
  },
});

@withHooks(() => ({
  locale: useContext(ActiveLocaleContext),
  online: useIsOnline(),
  intl: useIntl(),
  viewer: useViewer(),
}))
class App extends Component<Props> {

  componentDidMount() {
    this.propWatcher = watchProp({
      prop: () => this.props.viewer.viewer,
      onChange: () => this.updateActiveLocale(),
    });

    if (typeof matchMedia !== 'undefined' && typeof document !== 'undefined') {

      const body = document.body;
      if (body) {
        const noHover = window.matchMedia('(any-hover: none)').matches;

        if (noHover) {
          body.classList.add('no-hover');
        } else {
          body.classList.remove('no-hover');
        }
      }
    }
  }

  componentDidUpdate(): void {
    this.propWatcher.check();
  }

  @bind
  updateActiveLocale() {
    const viewer = this.props.viewer.viewer;
    if (!viewer || !viewer.isoLanguage) {
      return;
    }

    const locale = `${viewer.isoLanguage || 'en'}${viewer.isoCountry ? `-${viewer.isoCountry}` : ''}`;

    if (isTranslationSupported(locale)) {
      this.props.locale.setActiveLocale(locale);
    }
  }

  renderOfflineAlert() {
    return (
      <ErrorDisplay>
        <h2><FormattedMessage {...messages.errorBannerOffline} /></h2>
      </ErrorDisplay>
    );
  }

  renderContent() {
    if (!this.props.online) {
      return this.renderOfflineAlert();
    }

    return this.props.children;
  }

  render() {
    return (
      <MuiThemeProvider theme={muiTheme}>
        <HistoryTrackerProvider>
          <ScrollOnHashChange />
          <GoogleTagManager>
            <NotificationProvider>
              <SsrGeneratedIdProvider>
                <SnackbarProvider>
                  <PromptProvider>
                    <RenderViewportHeight>
                      {height => {
                        const badgeCount = useLiveNotificationCount().unreadConversations;

                        const htmlAttributes = {};
                        if (getScrollbarWidth() > 0) {
                          htmlAttributes['data-visible-scrollbar'] = '';
                        }

                        return (
                          <div style={{ height }}>
                            <Helmet
                              titleTemplate={`${badgeCount > 0 ? `(${badgeCount}) ` : ''}%s | ${SITE_NAME}`}
                              htmlAttributes={htmlAttributes}
                            >
                              <title>{this.props.intl.formatMessage(messages.genericPageTitle)}</title>

                              <meta name="viewport" content="initial-scale=1, width=device-width, height=device-height, viewport-fit=cover" />
                              <meta name="mobile-web-app-capable" content="yes" />
                              <meta name="apple-mobile-web-app-capable" content="yes" />

                              {/* these are links because these files are in the /public folder */}
                              <link rel="shortcut icon" href={badgeCount > 0 ? '/favicon-badged.png' : '/favicon.ico'} />
                            </Helmet>

                            {this.renderContent()}

                            <LongPressTitleHandler />
                          </div>
                        );
                      }}
                    </RenderViewportHeight>
                  </PromptProvider>
                </SnackbarProvider>
              </SsrGeneratedIdProvider>
            </NotificationProvider>
          </GoogleTagManager>
        </HistoryTrackerProvider>
      </MuiThemeProvider>
    );
  }
}

function ScrollOnHashChange() {
  const history = useHistory();

  useEffect(() => {
    return history.listen(change => {
      if (!change.hash) {
        return;
      }

      const target = document.querySelector(change.hash);
      if (!target) {
        return;
      }

      target.scrollIntoView();
    });
  }, [history]);

  return null;
}

function SafeApp(props: { children: ReactNode }) {

  const [viewer, setViewer] = useState<TViewerFragment | null>(null);

  return (
    <WeightedHelmetProvider>
      {/* Default color scheme when no other is provided */}
      <Helmet htmlAttributes={{ class: categoryClasses.physiotherapy }} />
      <CrashHandler viewer={viewer}>
        <UrqlClientProvider>
          <ViewerProvider onViewerChange={_viewer => setViewer(_viewer)}>
            <App {...props} />
          </ViewerProvider>
        </UrqlClientProvider>
      </CrashHandler>
    </WeightedHelmetProvider>
  );
}

export default SafeApp;
