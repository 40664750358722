import type { ExerciseCategory } from '../../shared-library/exercise-categories';
import { slugifyCategory } from '../../shared-library/exercise-categories';
import { getPath } from '../../utils/routing-utils';
import { AppSide, THERAPIST_ROUTES_PREFIX } from '../therapist-planning/therapist-planning.path';

export const EXERCISE_PATHNAME = '/exercise/:categorySlug/:exerciseId';
export const THERAPIST_EXERCISE_PATH = THERAPIST_ROUTES_PREFIX + EXERCISE_PATHNAME;

export const CHALLENGE_PATHNAME = '/challenge/:challengeId';
export const THERAPIST_CHALLENGE_PATH = THERAPIST_ROUTES_PREFIX + CHALLENGE_PATHNAME;

export const P_CHALLENGE_PROPOSAL_PATH = '/challenge-proposal/:proposalId';
export const T_CHALLENGE_PROPOSAL_PATH = THERAPIST_ROUTES_PREFIX + P_CHALLENGE_PROPOSAL_PATH;

export function getExerciseUrl(side: AppSide, exerciseId: string, category: ExerciseCategory) {
  const pattern = side === AppSide.Patient ? EXERCISE_PATHNAME : THERAPIST_EXERCISE_PATH;

  return getPath(pattern, { exerciseId, categorySlug: slugifyCategory(category) });
}
