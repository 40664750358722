import { useState, useEffect } from 'react';

// \\ // \\ // \\ // \\ // \\ // \\
// safari resize support
const listeners = new Set<() => void>();

function dispatchResize() {
  for (const listener of listeners) {
    listener();
  }
}

export function onViewportResize(cb) {
  listeners.add(cb);

  return () => {
    listeners.delete(cb);
  };
}

// eslint-disable-next-line -- at time of writing, onresize behaves differently in safari
document.body.onresize = dispatchResize;
// \\ // \\ // \\ // \\ // \\ // \\

export function useViewportHeight() {
  if (typeof document === 'undefined') {
    return '100vh';
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [height, setHeight] = useState(window.innerHeight);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {

    function updateHeight() {
      setHeight(window.innerHeight);
    }

    const cleanup = onViewportResize(updateHeight);
    document.body.addEventListener('resize', updateHeight);

    return () => {
      cleanup();
      document.body.removeEventListener('resize', updateHeight);
    };
  }, [setHeight]);

  return height;
}

export function RenderViewportHeight(props) {
  return props.children(useViewportHeight());
}
