import { ActiveLocaleContext } from '@reworkjs/core/active-locale-context';
import preval from 'babel-plugin-preval/macro';
import * as React from 'react';
import type { TRestUser } from './api/users';
import { useViewer } from './components/viewer-context';

export const BUILD_NUMBER: string = preval`
  try {
    module.exports = require('child_process').execSync('git rev-parse HEAD').toString().trim();
  } catch (e) {
    module.exports = '';
  }
`;

export const SHORT_BUILD_NUMBER: string = preval`
  try {
    module.exports = require('child_process').execSync('git rev-parse --short HEAD').toString().trim();
  } catch (e) {
    module.exports = '';
  }
`;

export const BUILD_DATE: string = preval`module.exports = new Date().toISOString();`;

export function useCordovaInfo() {
  const [info, setInfo] = React.useState({
    versionNumber: '',
    versionCode: '',
    packageName: '',
  });

  React.useEffect(() => {
    // TODO: handle errors
    void getCordovaInfo().then(v => setInfo(v));
  }, []);

  return info;
}

export async function getCordovaInfo() {
  if (typeof cordova === 'undefined') {
    return {};
  }

  if (!cordova.getAppVersion) {
    return {};
  }

  const versionNum = cordova.getAppVersion.getVersionNumber();
  const versionCode = cordova.getAppVersion.getVersionCode();
  const pkgName = cordova.getAppVersion.getPackageName();

  return {
    versionNumber: await versionNum,
    versionCode: await versionCode,
    packageName: await pkgName,
  };
}

export function useDebugInformation(includeViewer: boolean | TRestUser = true) {
  const viewerContext = useViewer();
  const viewer = includeViewer === true ? viewerContext.viewer
    : includeViewer === false ? null
    : includeViewer;

  const { activeLocale } = React.useContext(ActiveLocaleContext);
  const cordovaInfo = useCordovaInfo();

  return `=== System Information ===

Date: ${new Date().toISOString()}
User Id: ${viewer?.id ?? 'Not signed in'}
Build Number: ${BUILD_NUMBER}
Build Date: ${BUILD_DATE}
Native Version: ${cordovaInfo.versionNumber ?? 'N/A (Web)'}
Native Code: ${cordovaInfo.versionCode ?? 'N/A'}
Native PKG: ${cordovaInfo.packageName ?? 'N/A'}
User-Agent: ${navigator.userAgent}
App Dims: ${window.outerWidth}w / ${window.outerHeight}h
Screen Dims: ${window.screen.width}w / ${window.screen.height}h
DPR: ${window.devicePixelRatio}
Root Font Size: ${getComputedStyle(document.documentElement).fontSize}
Current Interface Language: ${activeLocale}
User Profile Language: ${viewer?.isoLanguage} ${viewer?.isoCountry ?? ''}
System Language: ${navigator.language}
System Languages: ${navigator.languages.join(', ')}
Cookies: ${document.cookie}
Location: ${window.location.href}`;
}

export type TRenderDebugInfoProps = {
  children(debug: string): React.ReactNode,
  includeViewer: boolean | TRestUser,
};

export function RenderDebugInfo(props: TRenderDebugInfoProps): React.ReactNode {
  return props.children(useDebugInformation(props.includeViewer));
}
