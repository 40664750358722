import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { isValidElement, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';
import AnyLink from '../../navigation-lib/any-link';
import { isMessageDescriptor } from '../../utils/react-utils';
import type { Action } from '../ActionMenu';
import { ActionMenu } from '../ActionMenu';

export const openMenuMessage = defineMessage({ defaultMessage: 'Open menu' });

type Props = {
  actions: Action[],
  className?: string,
};

export default function MoreMenu(props: Props) {
  const [moreMenu, setMoreMenu] = useState(null);
  const intl = useIntl();

  function closeMoreMenu(event) {
    event?.stopPropagation();
    setMoreMenu(null);
  }

  function openMoreMenu(event) {
    event.stopPropagation();
    setMoreMenu(event.currentTarget);
  }

  return (
    <>
      <IconButton
        aria-haspopup="true"
        onClick={openMoreMenu}
        className={props.className}
        title={intl.formatMessage(openMenuMessage)}
        color="inherit"
      >
        <MoreVertIcon color="inherit" />
      </IconButton>
      <ActionMenu actions={props.actions} onRequestClose={closeMoreMenu} anchorEl={moreMenu} />
    </>
  );
}

type TActionListProps = {
  actions?: Action[],
  className?: string,
};

export function ActionList(props: TActionListProps) {
  const intl = useIntl();

  if (!props.actions || !props.actions.length) {
    return null;
  }

  if (props.actions.length === 1) {
    const action = props.actions[0];
    const Icon = action.icon;

    return (
      <IconButton
        className={props.className}
        onClick={action.onClick}
        component={action.href ? AnyLink : undefined}
        to={action.href}
        target={action.target}
        role={undefined}
        title={isMessageDescriptor(action.title) ? intl.formatMessage(action.title) : action.title}
      >
        {isValidElement(Icon) ? Icon : <Icon color="inherit" />}
      </IconButton>
    );
  }

  return <MoreMenu actions={props.actions} className={props.className} />;
}
