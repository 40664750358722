export function removeQuery(str: string, key: string) {
  const usp = new URLSearchParams(str);

  usp.delete(key);

  return usp.toString();
}

export function addQuery(str: string, data: { [key: string]: string | number | boolean | null | undefined }): string {
  const usp = new URLSearchParams(str);

  for (const key of Object.keys(data)) {
    const value = data[key];
    if (value == null || value === false) {
      continue;
    }

    if (value === true) {
      usp.set(key, '');
      continue;
    }

    usp.set(key, String(value));
  }

  return usp.toString();
}

export function buildQuery(data: { [key: string]: string | number | boolean | null | undefined }): string {
  return addQuery('', data);
}

export function searchEqual(a: string, b: string): boolean {
  if (a.startsWith('?')) {
    a = a.slice(1);
  }

  if (b.startsWith('?')) {
    b = b.slice(1);
  }

  return a === b;
}

export function modifySearch(
  search: URLSearchParams | string,
  newData: { [key: string]: string | number | null | undefined },
): URLSearchParams {
  search = new URLSearchParams(search);

  for (const key of Object.keys(newData)) {
    if (newData[key] == null) {
      search.delete(key);
    } else {
      search.set(key, String(newData[key]));
    }
  }

  return search;
}

export function parseUrl(url: string): URL | null {
  try {
    return new URL(url);
  } catch {
    return null;
  }
}

export function uriTag(strings: TemplateStringsArray, ...parameters) {
  let out = '';
  let i = 0;

  for (const string of strings) {
    out += string;
    if (parameters[i]) {
      out += encodeURIComponent(String(parameters[i]));
      i++;
    }
  }

  return out;
}

type MailtoOpts = {
  body?: string,
  subject?: string,
};

export function mailto(email: string, opts?: MailtoOpts): string {
  const uri = `mailto:${encodeURIComponent(email)}`;
  if (!opts) {
    return uri;
  }

  const query = new URLSearchParams();
  for (const key of Object.keys(opts)) {
    query.append(key, opts[key]);
  }

  // we replace + with %20 because some email clients don't understand that + = space
  return `${uri}?${query.toString()}`.replace(/\+/g, '%20');
}
