import { useRef } from 'react';
import { ExerciseCategory } from '../../shared-library/exercise-categories';
import type { TCategoryClasses } from '../CategoryColors';
import { categoryClasses, categoryColorsDark } from '../CategoryColors';
import { WeightedHelmet } from '../weighted-helmet/weighted-helmet';
import './styles.scss';

type Props = {
  background?: 'white' | 'color-light' | 'color-dark',
  theme?: keyof TCategoryClasses | undefined | null,
  weight?: number,
};

export function BodyColor(props: Props) {
  const theme = props.theme === undefined ? ExerciseCategory.physiotherapy : props.theme;
  const previousThemeRef = useRef<NonNullable<Props['theme']>>(theme ?? ExerciseCategory.physiotherapy);

  if (theme !== null) {
    previousThemeRef.current = theme;
  }

  const attrs = {
    class: getSectionClasses(props.background, previousThemeRef.current),
  };

  const nativeThemeColor = categoryColorsDark[previousThemeRef.current] || '#000000';

  return (
    <WeightedHelmet bodyAttributes={attrs} weight={props.weight}>
      <meta name="theme-color" content={nativeThemeColor} />
    </WeightedHelmet>
  );
}

export function getSectionClasses(background: Props['background'] = 'white', theme: Props['theme'] | 'inherit' = null) {
  const bgClass = `section--${background}`;
  if (theme == null || theme === 'inherit') {
    return bgClass;
  }

  return `${bgClass} ${categoryClasses[theme]}`;
}
