import { useRef } from 'react';
import { useRouteMatch } from 'react-router-dom';

export function useExactRouteMatch(path: string): boolean {
  return useRouteMatch({ path, exact: true });
}

export function useAnyRouteMatch(...paths: string[]): boolean {
  const length = useRef(paths.length);
  if (length.current !== paths.length) {
    throw new Error('Length of paths array must stay constant between renders.');
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const matches = paths.map(path => useRouteMatch(path));

  for (const match of matches) {
    if (match) {
      return true;
    }
  }

  return false;
}
