import * as React from 'react';
import type { HeaderAction } from '../Header';

type IRemoveActions = () => void;

export type IPageActionContext = {
  currentActions: readonly HeaderAction[],
  pushActions(actions: HeaderAction | HeaderAction[]): (IRemoveActions),
};

export const PageActionContext = React.createContext<IPageActionContext>({
  currentActions: [],
  pushActions: () => {
    return () => {};
  },
});

type OnActionsChange = (actions: HeaderAction[]) => void;

type PageActionProviderProps = {
  children: React.ReactNode,
  onActionsChange?: OnActionsChange,
};

export function PageActionProvider(props: PageActionProviderProps) {
  const nextKeyRef = React.useRef(0);
  const [actions, setActions] = React.useState<{ [key: string]: HeaderAction | HeaderAction[] }>({});

  const pushActions = React.useCallback((newActions: HeaderAction | HeaderAction[]) => {
    const newKey = nextKeyRef.current++;

    setActions(currentActions => {
      return {
        ...currentActions,
        [newKey]: newActions,
      };
    });

    return function removeActions() {
      setActions(currentActions => {
        const actionsClone = { ...currentActions };
        delete actionsClone[newKey];

        return actionsClone;
      });
    };
  }, []);

  const context = React.useMemo(() => {
    return {
      // TODO: if currentActions.length > 1 && has Node in it, throw
      currentActions: Object.freeze(Object.values(actions).flat()),
      pushActions,
    };
  }, [actions, pushActions]);

  return (
    <PageActionContext.Provider value={context}>
      {props.children}
    </PageActionContext.Provider>
  );
}

export function usePageAction(actions: HeaderAction | HeaderAction[] | null) {
  const { pushActions } = React.useContext(PageActionContext);

  React.useEffect(() => {
    if (!actions) {
      return;
    }

    const removeActions = pushActions(actions);

    // eslint-disable-next-line consistent-return
    return () => {
      removeActions();
    };
  }, [actions, pushActions]);
}

type AddPageActionProps = {
  actions?: HeaderAction | HeaderAction[],
};

export function PageActions(props: AddPageActionProps) {
  usePageAction(props.actions);

  return null;
}
