import classnames from 'classnames';
import type { ReactElement, ReactNode } from 'react';
import { getSectionClasses } from '../Layout/body-color.js';
import css from './styles.module.scss';

type TCardProps = {
  children: ReactNode,
  className?: string,
};

export function Card(props: TCardProps): ReactElement {

  return (
    <div className={classnames(getSectionClasses('white'), props.className, css.card)}>
      {props.children}
    </div>
  );
}
