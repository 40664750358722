import classnames from 'classnames';
import bind from 'lodash-decorators/bind';
import type { MouseEvent, MutableRefObject, ReactNode, SyntheticEvent } from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import { RenderViewportHeight } from '../../../hooks/viewport-height';
import { registerDialog } from '../../../polyfills/dialog';
import { CHARCODES } from '../../../utils/dom-utils';
import { forwardRefEnsure } from '../../../utils/react-utils';
import styles from './styles.module.scss';

export type Props = {
  onRequestClose: null | ((reason?: SyntheticEvent | KeyboardEvent | undefined) => any),
  open: boolean,
  children?: ReactNode,
  backdropChildren?: ReactNode,
  className?: string,
  closingClassName?: string,
  contentsClassName?: string,
};

type State = {
  wasOpen: boolean,
  closing: boolean,
};

class Dialog extends Component<Props & { forwardedRef: MutableRefObject<HTMLDialogElement> }, State> {

  static defaultProps = {
    open: false,
  };

  state = {
    wasOpen: false,
    closing: false,
  };

  static getDerivedStateFromProps(newProps, oldState) {
    if (newProps.open) {
      return { closing: false, wasOpen: true };
    }

    if (oldState.wasOpen && !newProps.open) {
      return { closing: true, wasOpen: false };
    }

    return null;
  }

  componentDidMount() {
    const body = document.body;
    if (body) {
      body.addEventListener('keyup', this.watchEscape);
    }

    registerDialog(this.props.forwardedRef.current);
  }

  componentWillUnmount() {
    const body = document.body;
    if (body) {
      body.removeEventListener('keyup', this.watchEscape);
    }
  }

  close(reason: SyntheticEvent | KeyboardEvent | null = null) {
    if (this.props.onRequestClose) {
      this.props.onRequestClose(reason);
    }
  }

  @bind()
  watchEscape(e: KeyboardEvent) {
    if (!this.props.open) {
      return;
    }

    if (e.keyCode !== CHARCODES.ESCAPE) {
      return;
    }

    // check if this modal is the top most one
    const topMostElement = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
    if (topMostElement.closest('dialog') !== this.props.forwardedRef.current) {
      return;
    }

    this.close(e);
  }

  @bind()
  onModalClick(e: MouseEvent) {
    if (!this.props.open) {
      return;
    }

    if (e.target === this.props.forwardedRef.current) {
      this.close(e);
    }
  }

  renderContents(): React.ReactNode {
    const className = classnames(
      this.props.className,
      styles.dialog,
      this.state.closing ? this.props.closingClassName : null,
    );

    return (
      <RenderViewportHeight>
        {height => (
          <dialog
            ref={this.props.forwardedRef}
            className={className}
            role="dialog"
            open={this.props.open}
            onClick={this.onModalClick}
            style={{ maxHeight: height }}
          >
            {this.props.backdropChildren}
            <div className={this.props.contentsClassName}>
              {this.props.children}
            </div>
          </dialog>
        )}
      </RenderViewportHeight>
    );
  }

  render() {
    if (typeof document === 'undefined') {
      return null;
    }

    const body = document.body;
    if (!body) {
      return null;
    }

    return ReactDOM.createPortal(
      this.renderContents(),
      body,
    );
  }
}

export default forwardRefEnsure((props: Props, ref: MutableRefObject<HTMLDialogElement>) => {
  return <Dialog {...props} forwardedRef={ref} />;
});
