import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';
import type { ExtractRouteParams } from '../../utils/routing-utils';

export const THERAPIST_PUBLIC_PROFILE = '/therapist-profile/:therapist';

export type TTherapistPublicProfilePathParams = ExtractRouteParams<typeof THERAPIST_PUBLIC_PROFILE, string>;

export const MarketplaceSellerLoadable = loadable(async () => import('./TherapistProfile'), {
  fallback: <FullPageLoading />,
});
