import { useCallback, useEffect, useRef, useState } from 'react';
import { cache, mutate } from 'swr';
import type { keyInterface as KeyInterface, keyType } from 'swr/dist/types';
import { immutSetVal } from '../../utils/immutable';
import type { TNode } from '../node';

function getSwrKey(key: KeyInterface) {
  return cache.serializeKey(key)[0];
}

function getSwrData(key: KeyInterface) {
  return cache.get(getSwrKey(key));
}

export function getInfiniteSwrPagesKey(firstPageKey: keyType): keyType {
  return ['many', firstPageKey];
}

export function optimisticUpdate(callback) {

  const oldData: { [key: string]: any } = {};

  async function cancellableMutate(key, value) {
    const transformedKey = getSwrKey(key);

    oldData[transformedKey] = oldData[transformedKey] || { key, value: getSwrData(key) };

    return saneMutate(key, value);
  }

  callback({ mutate: cancellableMutate, getData: getSwrData, keys: () => cache.keys() });

  return async function rollback() {
    const promises = [];
    for (const { key, value } of Object.values(oldData)) {
      promises.push(saneMutate(key, value));
    }

    return Promise.all(promises);
  };
}

async function saneMutate(key, newValue, forceRefetch = false) {
  return mutate(key, newValue, forceRefetch);
}

export function update(callback) {
  callback({ mutate: saneMutate, getData: getSwrData, keys: () => cache.keys() });
}

export function useSwrCache(key, defaultValue) {
  const [, setForceRefresh] = useState(0);
  const latestValue = cache.get(key);
  const previousValue = useRef(latestValue);

  useEffect(() => {
    // cause re-render if data changes
    return cache.subscribe(() => {
      const newValue = cache.get(key);

      if (newValue !== previousValue.current) {
        previousValue.current = newValue;
        setForceRefresh(id => id + 1);
      }
    });
  }, [key]);

  const setData = useCallback(data => {
    if (typeof data === 'function') {
      data = data(cache.get(key));
    }

    return cache.set(key, data);
  }, [key]);

  return [
    latestValue ?? defaultValue,
    setData,
  ];
}

if (process.env.NODE_ENV !== 'production') {
  // @ts-expect-error
  window.swrCache = cache;
}

export function swrConnectionPrepend(cacheWrapper, key: keyType, newNode: TNode) {
  const allPagesCacheKey = getInfiniteSwrPagesKey(key);
  const allPages = cacheWrapper.getData(allPagesCacheKey);

  const newPages = immutSetVal(allPages, [0, 'nodes'], oldVal => {
    return [newNode, ...oldVal];
  });

  cacheWrapper.mutate(allPagesCacheKey, newPages, false);
}

type TNodeFinder = (input: any) => boolean;
type TNodeReplacer = (input: any) => any;

export function swrConnectionReplace(
  cacheWrapper,
  key: keyType,
  oldIdOrFinder: string | TNodeFinder,
  newNodeOrReplacer: TNode | TNodeReplacer,
) {
  const allPagesCacheKey = getInfiniteSwrPagesKey(key);
  const allPages = cacheWrapper.getData(allPagesCacheKey);

  if (!allPages) {
    return;
  }

  const newPages = [];

  const nodeFinder = typeof oldIdOrFinder === 'function' ? oldIdOrFinder : (node => node.id === oldIdOrFinder);

  for (const page of allPages) {
    const index = page.nodes.findIndex(nodeFinder);

    if (index === -1) {
      newPages.push(page);
      continue;
    }

    newPages.push(immutSetVal(page, ['nodes', index], newNodeOrReplacer));
  }

  cacheWrapper.mutate(allPagesCacheKey, newPages, false);
}
