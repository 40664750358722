import type { ComponentProps } from 'react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { SwrRes } from '../../api/swr/swr';
import type { TUseQueryOutput } from '../../api/urql/urql';
import { InternalServerError } from '../../api/util';
import type { TRenderDebugInfoProps } from '../../system-information';
import { RenderDebugInfo } from '../../system-information';
import ApiErrorMessage from '../ApiErrorMessage';
import Button2 from '../Button2';
import type { TCrashReportData } from '../CrashHandler/support';
import { generateCrashReport, generateSupportEmail } from '../CrashHandler/support';
import { LinkButton } from '../LinkButton';
import NoData from '../NoData';
import { retryMessage } from '../common-messages';
import css from './styles.module.scss';

const messages = defineMessages({
  contactSupport: { defaultMessage: 'Contact our support' },
  apiErrorTitle: { defaultMessage: 'Loading Error' },
});

type Props = {
  retry?(): void,
  retrying?: boolean,
  error?: any,
  variant?: 'light' | 'dark',
};

export default function ApiErrorDisplay(props: Props) {

  const intl = useIntl();

  const actions = [];

  const inInternalError = props.error instanceof InternalServerError;
  if (props.retry) {
    actions.push(
      <Button2 key={1} loading={props.retrying} onClick={props.retry} variant={inInternalError ? 'outlined' : undefined}>
        {intl.formatMessage(retryMessage)}
      </Button2>,
    );
  }

  if (props.error instanceof InternalServerError) {
    actions.push(
      <ContactSupportButton key={0} includeViewer error={props.error} />,
    );
  }

  return (
    <div className={css.errorDisplay}>
      <NoData
        inset
        variant={props.variant}
        actions={actions}
      >
        <h3>{intl.formatMessage(messages.apiErrorTitle)}</h3>
        <p><ApiErrorMessage error={props.error} /></p>
      </NoData>
    </div>
  );
}

type TCrashReportProps = {
  includeViewer: TRenderDebugInfoProps['includeViewer'],
} & Omit<TCrashReportData, 'systemInfo'>;

type TContactSupportActionProps = TCrashReportProps & Omit<ComponentProps<typeof LinkButton>, 'to' | 'children' | 'target'>;

export function CrashReport(props: TContactSupportActionProps) {
  const { includeViewer, ...passDown } = props;

  return (
    <div className={css.crashReport}>
      <RenderDebugInfo includeViewer={includeViewer}>
        {systemInfo => generateCrashReport({ ...passDown, systemInfo })}
      </RenderDebugInfo>
    </div>
  );
}

export function ContactSupportButton(props: TContactSupportActionProps) {
  const intl = useIntl();

  const { error, errorInfo, reportId, includeViewer, ...passDown } = props;

  return (
    <RenderDebugInfo includeViewer={includeViewer}>
      {systemInfo => {
        return (
          <LinkButton {...passDown} target="_blank" to={generateSupportEmail(intl, { reportId, systemInfo, error, errorInfo })}>
            {intl.formatMessage(messages.contactSupport)}
          </LinkButton>
        );
      }}
    </RenderDebugInfo>
  );
}

type UrqlErrorDisplayProps = {
  urql: TUseQueryOutput<any, any>,
};

/*
// TODO: use
export declare class CombinedError extends Error {
    name: string;
    message: string;
    graphQLErrors: GraphQLError[];
    networkError?: Error;
    response: any
}
 */

export function UrqlErrorDisplay(props: UrqlErrorDisplayProps) {
  return (
    <ApiErrorDisplay
      error={props.urql.error}
      retry={props.urql.revalidate}
      retrying={props.urql.fetching}
    />
  );
}

type SwrErrorDisplayProps = {
  swr: SwrRes<any>,
  variant?: 'light' | 'dark',
};

export function SwrErrorDisplay(props: SwrErrorDisplayProps) {
  return (
    <ApiErrorDisplay
      variant={props.variant}
      error={props.swr.error}
      retry={props.swr.revalidate}
      retrying={props.swr.isValidating}
    />
  );
}

export function SwrErrorOverlay(props: SwrErrorDisplayProps) {
  return (
    <div className={css.swrErrorOverlay}>
      <SwrErrorDisplay {...props} />
    </div>
  );
}
