import loadable from '@loadable/component';
import FullPageLoading from '../../../components/Loading/FullPageLoading';
import { getPath } from '../../../utils/routing-utils.js';
import { THERAPIST_ROUTES_PREFIX } from '../../therapist-planning/therapist-planning.path';

export const T_CHALLENGE_PACK_PATH = `${THERAPIST_ROUTES_PREFIX}/challenge-packs/:packId`;

export const ChallengePackLazy = loadable(async () => import('./challenge-pack.view'), {
  fallback: <FullPageLoading />,
});

export function getChallengePackPath(packId: string) {
  return getPath(T_CHALLENGE_PACK_PATH, { packId });
}
