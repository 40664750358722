import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Divider from '@material-ui/core/Divider';
import MuiList from '@material-ui/core/List';
import MuiListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TaskIcon from '@material-ui/icons/Assignment';
import TaskIconOutlined from '@material-ui/icons/AssignmentOutlined';
import ChatIcon from '@material-ui/icons/Chat';
import ChatIconOutlined from '@material-ui/icons/ChatOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsIcon from '@material-ui/icons/Settings';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import DashboardIcon from '@material-ui/icons/ViewAgenda';
import DashboardIconOutlined from '@material-ui/icons/ViewAgendaOutlined';
import WorkOffOutlined from '@material-ui/icons/WorkOffOutlined';
import WorkOutline from '@material-ui/icons/WorkOutline';
import { useRestartRequired as useServiceWorkerUpdate } from '@reworkjs/core/service-worker';
import classnames from 'classnames';
import throttle from 'lodash/throttle';
import { useRef, useEffect, memo, useMemo } from 'react';
import { defineMessage, defineMessages, useIntl } from 'react-intl';
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom';
import { isTherapist } from '../../api/users';
import { useAnyRouteMatch, useExactRouteMatch } from '../../hooks/routing';
import { onViewportResize } from '../../hooks/viewport-height';
import { TabLink } from '../../navigation-lib/tab-router';
import type { Nullish } from '../../shared-library/types';
import { T_EXERCISE_CATEGORIES_PATH } from '../../views/AddChallenge/activity-categories.lazy';
import { T_CHALLENGE_PACK_PATH } from '../../views/AddChallenge/challenge-pack/challenge-pack.lazy';
import { T_CHALLENGE_PACKS_PATH } from '../../views/AddChallenge/challenge-packs/challenge-packs.lazy';
import { PATIENT_CHAT_LIST_PATHNAME, THERAPIST_CHAT_LIST_PATHNAME } from '../../views/Chat/conversation.path';
import {
  COACH_PATIENT_CHAT_PATHNAME,
  COACH_PATIENT_STAT_PATH_DETAILS,
  COACH_PATIENT_STAT_PATHNAME,
} from '../../views/CoachPatient/coach-patient.path';
import { PATIENT_SETTINGS_PATHNAME, THERAPIST_SETTINGS_PATHNAME } from '../../views/Settings/paths';
import { STAT_LIST_PATHNAME } from '../../views/Stats/stat-list.loadable';
import { PATIENT_PLANNING_PATHNAME } from '../../views/patient-planning/patient-planning.loadable';
import { isTherapistRouteActive, THERAPIST_PLANNING_PATHNAME } from '../../views/therapist-planning/therapist-planning.path';
import BlueModal from '../Dialog/BlueModal';
import List from '../List/List';
import ListItem from '../List/ListItem';
import StatsIcon from '../icons/stats-icon';
import { useLiveNotificationCount } from '../notification-manager.js';
import { useViewer } from '../viewer-context';
import css from './styles.module.scss';

// FIXME: clicking nav link to active page does execute the action when it should abort

type Props = {
  className?: string,
  vertical: boolean,
  large: boolean,
};

const messages = defineMessages({
  patientHome: { defaultMessage: 'Today' },
  patientStats: { defaultMessage: 'Progression' },
  patientChat: { defaultMessage: 'Conversations' },

  therapistHome: { defaultMessage: 'Schedule' },
  therapistChat: { defaultMessage: 'Conversations' },
  therapistActivities: { defaultMessage: 'Activities' },
  settings: { defaultMessage: 'Settings' },

  therapistApp: { defaultMessage: 'View as Therapist' },
  patientApp: { defaultMessage: 'View as Patient' },
});

const challengesMessage = defineMessage({
  defaultMessage: 'Challenges',
  description: `See "Challenge" in Lexicon Tab.`,
});

type TLocationState = Nullish<{ dockActivities?: true }>;

function Dock(props: Props) {

  const intl = useIntl();
  const location = useLocation<TLocationState>();
  const settingsOpen: boolean = Boolean(useAnyRouteMatch(PATIENT_SETTINGS_PATHNAME, THERAPIST_SETTINGS_PATHNAME));
  const unreadConvCount = useLiveNotificationCount().unreadConversations;
  const showLabels = false; // useMediaQuery('(min-width: 370px)');
  const restartRequired = useServiceWorkerUpdate();
  const { viewer } = useViewer();

  const routeMatches = {
    patientHome: useExactRouteMatch(PATIENT_PLANNING_PATHNAME),
    patientStats: useRouteMatch(STAT_LIST_PATHNAME),
    patientChat: useRouteMatch(PATIENT_CHAT_LIST_PATHNAME),

    therapistHome: useRouteMatch(THERAPIST_PLANNING_PATHNAME),
    therapistChat: useAnyRouteMatch(
      THERAPIST_CHAT_LIST_PATHNAME,
      COACH_PATIENT_CHAT_PATHNAME,
      COACH_PATIENT_STAT_PATHNAME,
      COACH_PATIENT_STAT_PATH_DETAILS,
    ),
    therapistActivities: useAnyRouteMatch(
      T_EXERCISE_CATEGORIES_PATH,
      T_CHALLENGE_PACKS_PATH,
      T_CHALLENGE_PACK_PATH,
    ),
  };

  let activeRouteName = settingsOpen ? 'settings' : null;
  if (!activeRouteName) {
    for (const key of Object.keys(routeMatches)) {
      if (routeMatches[key]) {
        activeRouteName = key;
        break;
      }
    }
  }

  const isTherapistSide = isTherapistRouteActive(location);

  const routeData = useMemo(() => {
    return {
      patientHome: {
        icon: DashboardIconOutlined,
        iconActive: DashboardIcon,
        to: PATIENT_PLANNING_PATHNAME,
      },
      patientStats: {
        icon: StatsIcon,
        iconActive: StatsIcon,
        to: STAT_LIST_PATHNAME,
      },
      patientChat: {
        badge: unreadConvCount,
        icon: ChatIconOutlined,
        iconActive: ChatIcon,
        to: PATIENT_CHAT_LIST_PATHNAME,
      },

      therapistHome: {
        icon: DashboardIconOutlined,
        iconActive: DashboardIcon,
        to: THERAPIST_PLANNING_PATHNAME,
      },
      therapistChat: {
        badge: unreadConvCount,
        icon: ChatIconOutlined,
        iconActive: ChatIcon,
        to: THERAPIST_CHAT_LIST_PATHNAME,
      },
      therapistActivities: {
        icon: TaskIconOutlined,
        iconActive: TaskIcon,
        to: {
          state: {
            ...location.state,
            dockActivities: true,
          },
        },
      },
      settings: {
        badge: restartRequired ? 1 : 0,
        icon: SettingsOutlinedIcon,
        iconActive: SettingsIcon,
        to: isTherapistSide ? THERAPIST_SETTINGS_PATHNAME : PATIENT_SETTINGS_PATHNAME,
      },
    };
  }, [isTherapistSide, location.state, restartRequired, unreadConvCount]);

  const routes = isTherapistSide ? [
    'therapistHome',
    'therapistChat',
    'therapistActivities',
    '-',
    'settings',
  ] : [
    'patientHome',
    'patientStats',
    'patientChat',
    '-',
    'settings',
  ];

  const bottomNavRef = useRef<HTMLDivElement | null>(null);

  // some users have their font-size increased, which cause issues with the font-size of the menu
  // this piece of code ensures the text is at least readable by reducing the font-size of the labels
  // so they can be fully visible
  // https://mymedicoach.atlassian.net/browse/MMC-1108
  useEffect(() => {
    if (!bottomNavRef.current) {
      return;
    }

    const maxFontSize = 0.75; // rem

    function recomputeIdealSize() {
      const dock = bottomNavRef.current;
      if (!dock) {
        return;
      }

      const dockWidth = dock.offsetWidth;
      const maxItemWidth = dockWidth / dock.childElementCount;

      let largestLabelWidth = 0;
      const labels = dock.querySelectorAll<HTMLSpanElement>('.MuiBottomNavigationAction-label');
      for (const label of labels) {
        label.style.fontSize = `${maxFontSize}rem`;

        if (label.offsetWidth > largestLabelWidth) {
          largestLabelWidth = label.offsetWidth;
        }
      }

      const scaleFactor = Math.min(maxItemWidth / largestLabelWidth, 1);

      for (const label of labels) {
        label.style.fontSize = `${maxFontSize * scaleFactor}rem`;
      }
    }

    recomputeIdealSize();

    // eslint-disable-next-line consistent-return
    return onViewportResize(throttle(recomputeIdealSize, 100));
  }, [bottomNavRef]);

  if (props.vertical) {

    return (
      <nav className={css.drawer}>
        <div className={css.drawerToolbar}>
          <img src="/monochrome-512x512.png" width="512" height="512" alt="" title="MyMedicoach" />
        </div>
        <MuiList component="div">
          {routes.map((routeName, i) => {
            if (routeName === '-') {
              return <Divider key={i} />;
            }

            const isActive = activeRouteName === routeName;
            const data = routeData[routeName];
            const Icon = isActive ? data.iconActive : data.icon;

            const title = intl.formatMessage(messages[routeName]);

            const iconComponent = (
              <div className={css.navIcon}>
                <Icon />
              </div>
            );

            return (
              <MuiListItem
                button
                key={routeName}
                title={title}
                component={TabLink}
                to={data.to}
                className={classnames(css.navItem, isActive ? css.active : '')}
                id={`dock__${routeName}`}
              >
                <ListItemIcon className={css.icon}>
                  {data.badge ? (
                    <Badge badgeContent={data.badge} max={9} color="secondary" classes={{ colorSecondary: css.badge }}>
                      {iconComponent}
                    </Badge>
                  ) : iconComponent}
                </ListItemIcon>
                <ListItemText primary={title} />
              </MuiListItem>
            );
          })}
        </MuiList>
        {isTherapist(viewer) && (
          <MuiList className={css.dashboardSwitchList} component="div">
            <MuiListItem
              button
              component={TabLink}
              to={isTherapistSide ? PATIENT_PLANNING_PATHNAME : THERAPIST_PLANNING_PATHNAME}
              title={intl.formatMessage(isTherapistSide ? messages.patientApp : messages.therapistApp)}
              className={css.navItem}
            >
              <ListItemIcon className={css.icon}>
                {isTherapistSide ? <WorkOffOutlined /> : <WorkOutline />}
              </ListItemIcon>
              <ListItemText primary={intl.formatMessage(isTherapistSide ? messages.patientApp : messages.therapistApp)} />
            </MuiListItem>
          </MuiList>
        )}
        {location.state?.dockActivities && (
          <ActivitiesPopup />
        )}
      </nav>
    );
  }

  return (
    <nav className={css.bottomNavWrapper}>
      <BottomNavigation
        className={css.bottomNav}
        value={activeRouteName}
        showLabels={showLabels}
        ref={bottomNavRef}
      >
        {routes.map(routeName => {
          if (routeName === '-') {
            return null;
          }

          const isActive = activeRouteName === routeName;

          const data = routeData[routeName];
          const Icon = isActive ? data.iconActive : data.icon;

          const iconComponent = (
            <div className={css.navIcon}>
              <Icon />
            </div>
          );

          const title = intl.formatMessage(messages[routeName]);

          return (
            <BottomNavigationAction
              key={routeName}
              value={routeName}
              label={title}
              title={title}
              id={`dock__${routeName}`}
              icon={data.badge ? (
                <Badge badgeContent={data.badge} max={9} color="secondary" classes={{ colorSecondary: css.badge }}>
                  {iconComponent}
                </Badge>
              ) : iconComponent}
              component={TabLink}
              to={data.to}
              className={classnames(css.navItem, css.bottomNavItem, isActive ? css.active : '')}
              classes={{
                selected: css.bottomNavItemSelected,
                label: css.bottomNavItemLabel,
                iconOnly: css.bottomNavItemInactive,
              }}
            />
          );
        })}
      </BottomNavigation>
      {location.state?.dockActivities && (
        <ActivitiesPopup />
      )}
    </nav>
  );
}

function ActivitiesPopup() {
  const intl = useIntl();
  const history = useHistory();

  return (
    <BlueModal onRequestClose={() => history.goBack()} open title={intl.formatMessage(messages.therapistActivities)}>
      <List>
        <ListItem
          title={intl.formatMessage(challengesMessage)}
          rightIcon={<ChevronRightIcon />}
          href={T_EXERCISE_CATEGORIES_PATH}
          replace
        >
          {intl.formatMessage({ defaultMessage: 'Exercises & actions that you can propose to your patients.' })}
        </ListItem>
        <ListItem title={intl.formatMessage({ defaultMessage: 'Challenge Packs' })} rightIcon={<ChevronRightIcon />} href={T_CHALLENGE_PACKS_PATH} replace>
          {intl.formatMessage({ defaultMessage: 'Pre-program reusable lists of challenges.' })}
        </ListItem>
        {/* TODO: questionnaires - Envoyez des questions auxquelles votre patient répondra périodiquement. */}
      </List>
    </BlueModal>
  );
}

export default memo(Dock);
