import { useEffect, useState } from 'react';
import {
  deletePushSubscription,
  getPushSubscriptionConfig,
  updatePushSubscription,
} from '../api/push-notifications';
import { getAuthToken, onTokenChange } from '../api/token';
import type { TStorageSetValue } from '../hooks/use-local-storage';
import { useLocalStorage } from '../hooks/use-local-storage';
import { createListener } from '../utils/utils';
import { MMC_STORAGE_PREFIX } from './storage';

// TODO: migrate to a react effect

/*
 * This code is quite complex, apologies to whoever will maintain this in the future.
 * Here is what it does:
 *
 * - Tells the rest of the application when it can display a button to trigger
 * a notification permission prompt. ({@link onMayAskForPushNotifications}).
 *
 * - Exposes a method to prompt the permission and upload the PushSubscription to the user profile.
 * ({@link promptEnablePushNotifications})
 *
 * - Automatically destroys the subscription when a user logs out
 * - Tries to create a new subscription when the user logs in without triggering a permission prompt.
 * (these two are the complicated part which makes up the rest of the code).
 *
 * Right now it seems to work as intended but might not be efficient. Might need to revisit later.
 */

// TODO: On service worker side, handle pushsubscriptionchange event.
// - delete oldSubscription.
// - install newSubscription.

/**
 * Prompts the user for permissions to use push notifications and uploads
 * the result to the server.
 *
 * @returns {Promise<void>}
 */
export async function requestPushSubscription(): Promise<void> {
  await requestNewSubscription();
  await refreshPushPermissionState();
  await syncCurrentSubscription();
}

export async function clearPushSubscription(userAuthToken = getAuthToken()): Promise<void> {
  await Promise.all([
    deletePushSubscription(currentSubscription, userAuthToken),
    currentSubscription.unsubscribe(),
  ]);

  currentSubscription = null;
  subscriptionListener.dispatch(currentSubscription);
}

/**
 * @returns {boolean} Whether the environment supports push notifications.
 */
export function supportsPushNotifications() {
  return typeof navigator !== 'undefined'
    && typeof navigator.serviceWorker !== 'undefined'
    && typeof window !== 'undefined'
    && typeof window.PushManager !== 'undefined';
}

/**
 * @returns {Promise<PushManager>} the push manager.
 */
async function getPushManager(): Promise<PushManager> {
  const serviceWorker = navigator.serviceWorker;
  if (!serviceWorker) {
    throw new Error('Service worker is not supported');
  }

  await serviceWorker.ready;
  const controller = await serviceWorker.getRegistration();

  return controller.pushManager;
}

let currentSubscription: PushSubscription | null = null;

const subscriptionListener = createListener();

let viewerToken = getAuthToken();

/**
 * Called when the user changes to either:
 * - delete the existing subscription if the user logs out
 * - create a new subscription if the user logs in.
 * @param {!string} newToken
 * @param {!string} oldToken
 */
function viewerChangeListener(newToken: string | null, oldToken: string | null): void {

  // user logging out: unsubscribe & delete existing subscription
  if (newToken == null && oldToken != null && currentSubscription != null) {
    void clearPushSubscription(oldToken);

    return;
  }

  // user logged in: add existing subscription
  if (newToken != null) {
    void syncCurrentSubscription();
  }

  viewerToken = newToken;
}

/**
 * Requests a new push subscription (may cause a permission prompt).
 */
export async function requestNewSubscription(): Promise<PushSubscription | null> {
  const pushManager = await getPushManager();

  try {
    return await pushManager.subscribe(await getPushSubscriptionConfig());
  } catch {
    return null;
  }
}

/**
 * Tries to find a valid subscription (without causing a permission prompt to the user) and uploads it to the server.
 * @returns {Promise<*>}
 */
async function syncCurrentSubscription(): Promise<PushSubscription | null> {
  if (viewerToken == null) {
    return null;
  }

  const pushManager = await getPushManager();
  let subscription = await pushManager.getSubscription();

  if (subscription == null) {
    if (await getPermissionState() !== 'granted') {
      return null;
    }

    subscription = await requestNewSubscription();
  }

  currentSubscription = subscription;

  subscriptionListener.dispatch(currentSubscription);

  if (subscription != null) {
    await updatePushSubscription(subscription);
  }

  return currentSubscription;
}

async function getPermissionState(): Promise<string | null> {
  if (!supportsPushNotifications()) {
    return null;
  }

  const [pushManager, config] = await Promise.all([
    getPushManager(),
    getPushSubscriptionConfig(),
  ]);

  return pushManager.permissionState(config);
}

const permissionStateListener = createListener();

export async function refreshPushPermissionState() {
  const permissionState = await getPermissionState();

  permissionStateListener.dispatch(permissionState);
}

let didInit = false;

/**
 * Loads everything necessary for push notifications.
 * @returns {Promise<void>}
 */
export async function initPushNotifications() {
  if (didInit) {
    return;
  }

  didInit = true;

  if (!supportsPushNotifications()) {
    return;
  }

  // on browsers that support permission, this will update the state when
  // changed from outside the website (eg. browser settings)
  void navigator.permissions?.query({ name: 'push', userVisibleOnly: true }).then(permission => {
    permission.addEventListener('change', refreshPushPermissionState);
  });

  onTokenChange(viewerChangeListener);

  await Promise.all([
    syncCurrentSubscription(),
    refreshPushPermissionState(),
  ]);
}

export function usePushSubscription(): PushSubscription | null {
  const [subscription, setSubscription] = useState(currentSubscription);

  useEffect(() => {
    void initPushNotifications();

    subscriptionListener.on(setSubscription);

    return () => {
      subscriptionListener.off(setSubscription);
    };
  }, []);

  const [prefersDisabled] = usePrefersPushDisabled();
  if (prefersDisabled) {
    return null;
  }

  return subscription;
}

/**
 * @returns {PushPermissionState | Error | null} the current Push permission state.
 * Can also return `null` if the permission state is still loading or an instance of `Error` if
 * loading the permission failed.
 */
export function usePushPermissionState(): PushPermissionState | Error | null {
  const [state, setState] = useState(null);

  useEffect(() => {
    getPermissionState().then(setState, setState);
    permissionStateListener.on(setState);

    return () => {
      permissionStateListener.off(setState);
    };
  }, []);

  return state;
}

export function usePrefersPushDisabled(): [boolean, TStorageSetValue<boolean>] {
  return useLocalStorage(`${MMC_STORAGE_PREFIX}prefers-push-disabled`, false);
}
