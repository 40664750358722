import { Redirect } from 'react-router-dom';
import { getPreferredDashboard } from '../../api/users';
import { ensureViewer } from '../../components/AccessControl';
import { useViewerEnsured } from '../../components/viewer-context';

export const INDEX_PATHNAME = '/';

function IndexView() {
  const { viewer } = useViewerEnsured();

  return <Redirect to={getPreferredDashboard(viewer)} />;
}

export default ensureViewer(IndexView);
