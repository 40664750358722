import { useMemo, useRef } from 'react';
import type { ConfigInterface, responseInterface as ResponseInterface } from 'swr';
import useSWR, { cache } from 'swr';
import { callApi, getDataOrThrow } from '../util';
import type { PagedSwrRes } from './swr-paged';

// TODO:
//  - replace SWR with react-query
//  - provide own cache implementation in react-query which deduplicates nodes based on their ID, and provides getNodeById(), setNodeById()

export async function swrDefaultFetch(path: string, token: string) {
  // TODO: if callApi receives they right key itself, don't transform
  return getDataOrThrow(callApi.get(path, token && { token }));
}

// inputs
export type SwrOptions<Data, E = Error> = ConfigInterface<Data, E> & {
  disabled?: boolean,
  discardStale?: boolean,
};

export type SwrFetch = (key: string, token?: string) => any;
export type SwrKey = string | null | any[];

// outputs
export type SwrRes<T, E = Error> = ResponseInterface<T, E>;

export function useQuery<Data, Error>({
  fetch = swrDefaultFetch,
  key,
  options,
}: { fetch?: SwrFetch, key: SwrKey, options?: SwrOptions<Data, Error> }) {
  const discardedStale = useRef(false);
  if (key && options?.discardStale && !discardedStale.current) {
    discardedStale.current = true;
    cache.delete(key);
  }

  const res: SwrRes<Data, Error> = useSWR(
    // key
    options && options.disabled
      ? null
      : key,

    // fetch
    fetch,

    // options
    {
      errorRetryInterval: 10_000,
      revalidateOnFocus: false,
      ...options,
    },
  );

  return useMemo(() => {

    return {
      ...res,
      get data() {
        return res.data;
      },
      get error() {
        return res.error;
      },
      get isValidating() {
        return res.isValidating;
      },
      get loaded() {
        return isLoadedSwr(res);
      },
    };
  }, [res]);
}

export function isLoadedSwr<T = any, Error = any>(
  swr: SwrRes<T, Error> | PagedSwrRes<T, Error>,
) {
  if (!swr) {
    return false;
  }

  return swr.data !== undefined || swr.error !== undefined;
}
