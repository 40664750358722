import type { Location } from 'history';
import bind from 'lodash-decorators/bind';
import type { ReactNode } from 'react';
import { Children, Component, createContext, Fragment, useContext } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { matchPath, Switch, withRouter } from 'react-router-dom';
import CSSTransition from 'react-transition-group/CSSTransition';
import TransitionGroup from 'react-transition-group/TransitionGroup';
import type { Nullish } from '../shared-library/types';
import { isRouteGroup } from './route-group';
import css from './styles.module.scss';

function routeElementMatchesPath(child, pathname) {
  // FIXME: TabRouter has a custom ParameterisedPath, we could call child.type.matchPath when available
  if (!child.props.path) {
    return true;
  }

  return matchPath(pathname, child.props);
}

export type StackRouterTransitionProps = {
  classNames: string,
  timeout: {
    exit: number,
    enter: number,
  },
};

export type StackRouterGetTransition = (isNextPage: boolean) => StackRouterTransitionProps;

type Props = {
  children: ReactNode[],
  transition?: Nullish<StackRouterGetTransition>,
} & RouteComponentProps<any>;

type State = {
  isNextPage: boolean,

  // data that will be out-of-sync from props for one update so we can update their CSS Transition
  renderPreviousData: boolean,
  previousLocation: Location | null,

  keyOverride: string | null,
  previousKey: string | null,

  // data that contains the old props so they can be put in previousData
  oldPropsLocation: Location | null,

  // whether the page transition animation is currently running
  transitioning: boolean,
};

function areLocationsEqual(a: Location, b: Location): boolean {
  // state is always ignored according to react-router spec
  return a.pathname === b.pathname && a.search === b.search;
}

const StackRouterTransitioningContext = createContext<boolean>(false);

export function useStackRouterTransitioning(): boolean {
  return useContext(StackRouterTransitioningContext);
}

class StackRouterImpl extends Component<Props, State> {

  state: State = {
    isNextPage: false,

    renderPreviousData: false,
    previousLocation: null,

    keyOverride: null,
    previousKey: null,

    oldPropsLocation: null,

    transitioning: false,
  };

  static getDerivedStateFromProps(newProps: Props, oldState: State) {

    const { oldPropsLocation, keyOverride } = oldState;
    const newLocation = newProps.location;

    if (oldPropsLocation != null && newLocation.key === oldPropsLocation.key) {
      return null;
    }

    const allTransitionsDisabled = newProps.transition == null;

    // location.state.transition is used to override the default transition logic
    // by default it transitions except for replacement actions
    // setting .transition to true will force a transition for history replacements
    // setting it to false will disable this route change transition
    const shouldTransition = !allTransitionsDisabled && (newLocation.state?.transition ?? (newProps.history.action !== 'REPLACE'));

    if (!shouldTransition) {

      return {
        keyOverride: keyOverride || (oldPropsLocation ? oldPropsLocation.key : newLocation.key),
        oldPropsLocation: newLocation,
      };
    }

    const newChildren = Children.toArray(newProps.children);

    if (!oldPropsLocation || shouldTransitionBetween(oldPropsLocation, newLocation, newChildren)) {
      return {
        renderPreviousData: oldPropsLocation != null,
        previousLocation: oldPropsLocation,
        previousKey: keyOverride,

        oldPropsLocation: newLocation,
        keyOverride: newLocation.key,

        isNextPage: isNextPage(oldPropsLocation, newLocation),

        transitioning: oldPropsLocation != null && newProps.transition != null,
      };
    }

    return null;
  }

  componentDidUpdate(): void {
    this.setState(state => {
      if (state.renderPreviousData) {
        return { renderPreviousData: false, previousLocation: null, previousKey: null };
      }

      return null;
    });
  }

  renderChildren(children: ReactNode[], location: Location, key: string) {
    const activeRoute = getActiveRoute(location, children);

    if (activeRoute == null) {
      console.error('No route matches the current location. Consider adding a catch-all route');

      return null;
    }

    const animationParams = this.props.transition
      ? this.props.transition(this.state.isNextPage)
      : {};

    // TODO: find a way to propagate whether the page is transitioning
    //  so pages can adapt their rendering (and reduce load during transition).
    //  tried using onEnter / onEntered / etc... hooks but it causes a serious delay.
    return (
      <CSSTransition
        timeout={1}
        {...animationParams}
        key={key}
        className={css.transitionGroup}
        onExited={this.onExited}
      >
        <div>
          <Switch location={location}>
            {activeRoute}
          </Switch>
        </div>
      </CSSTransition>
    );
  }

  @bind
  onExited() {
    this.setState({ transitioning: false });
  }

  render() {
    const currentChildren = Children.toArray(this.props.children);
    const location = this.state.renderPreviousData ? this.state.previousLocation : this.props.location;
    const locationKey = this.state.renderPreviousData ? this.state.previousKey : this.state.keyOverride;

    return (
      <StackRouterTransitioningContext.Provider value={this.state.transitioning}>
        <TransitionGroup className={css.transitionGroupWrapper}>
          {this.renderChildren(currentChildren, location, locationKey)}
        </TransitionGroup>
      </StackRouterTransitioningContext.Provider>
    );
  }
}

function getActiveRoute(location: Location, children: ReactNode[]): ReactNode | null {
  for (const child of children) {
    if (child.type === Fragment) {
      const match = getActiveRoute(location, Children.toArray(child.props.children));

      if (match) {
        return match;
      }

      continue;
    }

    if (routeElementMatchesPath(child, location.pathname)) {
      return child;
    }
  }

  return null;
}

function shouldTransitionBetween(from: Location, to: Location, routeElements: ReactNode[]) {
  const fromGroup = getLocationRouteGroup(from, routeElements);
  if (fromGroup == null) {
    return !areLocationsEqual(from, to);
  }

  const toGroup = getLocationRouteGroup(to, routeElements);
  if (toGroup == null) {
    return !areLocationsEqual(from, to);
  }

  if (fromGroup !== toGroup) {
    return !areLocationsEqual(from, to);
  }

  // @ts-expect-error
  const shouldTransitionCb = fromGroup.props?.shouldTransitionBetween;
  if (shouldTransitionCb) {
    return shouldTransitionCb(from, to);
  }

  return false;
}

function getLocationRouteGroup(location: Location, children: ReactNode[]): ReactNode | null {
  const activeChild = getActiveRoute(location, children);

  if (activeChild == null) {
    return null;
  }

  if (isRouteGroup(activeChild)) {
    return activeChild;
  }

  return null;
}

function isNextPage(from: Location | null, to: Location) {
  if (!from) {
    return false;
  }

  return !(from.state && from.state.previousLoc && from.state.previousLoc.key === to.key);
}

export const StackRouter = withRouter(StackRouterImpl);
