
let dialogPolyfill;
export async function installDialogPolyfill() {
  if (window.HTMLDialogElement && window.HTMLDialogElement.prototype.showModal) {
    return;
  }

  return import('dialog-polyfill').then(polyfill => {
    dialogPolyfill = polyfill.default;
  });
}

export function registerDialog(element) {
  if (dialogPolyfill) {
    dialogPolyfill.registerDialog(element);
  }
}
