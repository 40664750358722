import preval from 'preval.macro';
import { LATEST_COMMIT_HASH } from './app-globals.js';

// language=JavaScript
export const DSN = preval`
  if (process.env.STAGING === 'true') {
    // staging
    // https://sentry.io/organizations/mad-kings/issues/?project=2062769
    module.exports = 'https://527b119d7d7e4ade83ce16027af0a6c0@o4504237804290048.ingest.sentry.io/4504237827751936';
  } else {
    // production
    // https://sentry.io/organizations/mad-kings/issues/?project=1193128
    module.exports = 'https://fa4eb77257ee46fbadab480c926ecb98@o4504237804290048.ingest.sentry.io/4504237828866049';
  }
`;

export function getRaven() {
  if (typeof document === 'undefined' || process.env.NODE_ENV !== 'production') {
    return null;
  }

  const Sentry = window.Sentry;
  if (!Sentry) {
    console.error('Unable to load Sentry');

    return null;
  }

  Sentry.init({
    dsn: DSN,
    release: LATEST_COMMIT_HASH,
    environment: process.env.NODE_ENV,
  });

  return Sentry;
}
