import type { Location } from 'history';
import type { match as Match } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

export const THERAPIST_ROUTES_PREFIX = '/t';

export const THERAPIST_PLANNING_PATHNAME = `${THERAPIST_ROUTES_PREFIX}/planning`;

export const THERAPIST_PLANNING_PATIENT_CHAT = `${THERAPIST_ROUTES_PREFIX}/planning/:user`;
export const THERAPIST_PLANNING_PATIENT_STATS = `${THERAPIST_ROUTES_PREFIX}/planning/:user/stats`;
export const THERAPIST_PLANNING_PATIENT_STAT_DETAILS = `${THERAPIST_ROUTES_PREFIX}/planning/:user/stats/:challengeId`;

/** @deprecated use routeMatchesTherapist */
export function isTherapistRouteActive(location: Location) {

  return location.pathname === THERAPIST_ROUTES_PREFIX
    || location.pathname.startsWith(`${THERAPIST_ROUTES_PREFIX}/`);
}

export function routeMatchesTherapist(match: Match): boolean {

  return match.path === THERAPIST_ROUTES_PREFIX
    || match.path.startsWith(`${THERAPIST_ROUTES_PREFIX}/`);
}

export function getAppSide(match: Match): AppSide {
  if (routeMatchesTherapist(match)) {
    return AppSide.Therapist;
  }

  return AppSide.Patient;
}

export function useIsTherapistSide() {
  const match = useRouteMatch();

  return routeMatchesTherapist(match);
}

export enum AppSide {
  Patient = 0,
  Therapist = 1,
}
