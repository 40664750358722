import classNames from 'classnames';
import type { ReactNode } from 'react';
import useMediaQuery from '../../../hooks/use-media-query';
import Dock from '../../Dock';
import { CommonLayout } from '../span-header';
import css from './styles.module.scss';

type Props = {
  children: ReactNode,
};

export default function PageWithDock(props: Props) {

  const vertical = useMediaQuery('(min-width: 700px)');

  return (
    <CommonLayout>
      <div
        className={classNames(css.container, {
          [css.vertical]: vertical,
        })}
      >
        <div className={css.childrenWithDock}>
          {props.children}
        </div>
        <Dock vertical={vertical} large={vertical} />
      </div>
    </CommonLayout>
  );
}
