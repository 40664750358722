import css from './styles.module.scss';

export default function DotLoading() {
  return (
    <div className={css.spinner}>
      <div className={css.dot1} />
      <div className={css.dot2} />
    </div>
  );
}
