import { useRef } from 'react';
import type { UseQueryState } from 'urql/dist/types/hooks/useQuery';
import { isObject } from '../../shared-library/object-utils.js';
import type { NonUndefined } from '../../shared-library/types';
import type { TUseQueryOutput } from './urql.js';

export function useStaledUrql<T extends UseQueryState<any, any>>(state: T): T {
  const ref = useRef(state);

  if (ref.current === state) {
    return state;
  }

  if (!state.fetching && isLoadedUrql(state)) {
    ref.current = state;
  }

  return ref.current;
}

export function isLoadedUrql<Data, Error>(state: UseQueryState<Data, Error>): state is (
  Omit<UseQueryState<Data, Error>, 'data' | 'error'>
  & (
    { data: NonUndefined<UseQueryState<Data, Error>['data']>, error: undefined }
    | { data: undefined, error: NonNullable<UseQueryState<Data, Error>['error']> })
) {

  return state.data !== undefined || state.error != null;
}

/**
 * @param value is anything
 * @returns true if the value is an urql result object
 * @returns false otherwise
 */
export function isUrqlQueryOutput(value: any): value is (TUseQueryOutput<unknown, unknown>) {
  // Early exit if value is not an object
  if (!isObject(value)) {
    return false;
  }

  // Those two properties are always set to a boolean
  if (('fetching' in value && 'stale' in value)) {
    return true;
  }

  return false;
}
