/**
 * Creates a read-only view of an object. Source object can still be mutated.
 *
 * @param obj
 * @returns {Proxy<*>|*}
 */
export function createImmutableView(obj: any): Proxy<any> {
  if (process.env.NODE_ENV === 'development') {
    const throwImmutable = () => {
      throw new Error('This object is immutable');
    };

    return new Proxy(obj, {
      set: throwImmutable,
      deleteProperty: throwImmutable,
      defineProperty: throwImmutable,
      preventExtensions: throwImmutable,
      setPrototypeOf: throwImmutable,
    });
  }

  return obj;
}

/**
 * Creates an object that throws when used. Can be used as the default value of something that should be provided.
 *
 * @returns {Function}
 */
export function createTrappedCallable<T>(providerName?: string): T {

  function triggerTrap() {
    if (providerName) {
      throw new Error(`This object should not be used. Make sure provider ${providerName} is available in react tree`);
    }

    throw new Error('This object should not be used. It is possible you received this object instead of another because it has not been initialized');
  }

  return new Proxy(triggerTrap, {
    getPrototypeOf: triggerTrap,
    setPrototypeOf: triggerTrap,
    isExtensible: triggerTrap,
    preventExtensions: triggerTrap,
    getOwnPropertyDescriptor: triggerTrap,
    defineProperty: triggerTrap,
    has: triggerTrap,
    get: triggerTrap,
    set: triggerTrap,
    deleteProperty: triggerTrap,
    ownKeys: triggerTrap,
    apply: triggerTrap,
    construct: triggerTrap,
  });
}

export function setFunctionName(afunction: Function, name: string): void {
  Object.defineProperty(afunction, 'name', { value: name });
}
