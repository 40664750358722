import ButtonBase from '@material-ui/core/ButtonBase';
import classnames from 'classnames';
import type {
  ComponentProps,
  ElementType,
  MutableRefObject,
} from 'react';
import {
  forwardRef,
  PureComponent,
  useCallback,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { forwardRefEnsure } from '../../utils/react-utils';
import Loading from '../Loading/Loading';
import { useSnackbar } from '../snackbar/snackbar';
import css from './style.module.scss';

type InternalProps = Omit<ComponentProps<typeof ButtonBase>, 'disableRipple' | 'focusRipple' | 'aria-disabled' | 'disabled'> & {
  scheme?: 'default' | 'danger',
  variant?: 'borderless' | 'outlined' | 'plain',
  size?: 'small' | 'medium' | 'large',
  floating?: boolean,
  disabled?: string,

  /**
   * If a non-falsy value is provided, the button will be temporarily disabled (aria-disabled, clicking will be ignored)
   * and an activity indicator will be displayed.
   *
   * This property should only be used for loading caused by user interaction (pressing the button and waiting for the action to complete).
   *
   * If a non-falsy string is provided, it will be read to screen readers.
   * If true is provided, the text "Loading, wait." will be read to screen readers.
   *
   * Only one button should use this property at a time. (Ideally the one the user clicked).
   * Other buttons that should also be disabled while this button loads should use the "disabled" property instead.
   */
  loading?: boolean | string,
  component?: ElementType,
  icon?: ElementType,

  forwardedRef: MutableRefObject<HTMLButtonElement>,
};

const loadingSizes = {
  circle: {
    small: 32,
    medium: 48,
    large: 58.7,
  },
  rect: {
    small: 24,
    medium: 32,
    large: 48,
  },
};

class InternalButtonWithRef extends PureComponent<InternalProps> {
  static defaultProps = {
    scheme: 'default',
    variant: 'plain',
    size: 'medium',
    component: 'button',
  };

  ripple: Element;
  obs: MutationObserver;

  // ==================================================================
  // Everything below this line is a massive hack
  // We want buttons to guarantee a touchable area of 48x48px
  // Even if visually they can be smaller.
  // We do this by making the button transparent and using a placing all button styling
  // on a <div> inside the button
  //
  // The only issue is the material ripple: it overlays the whole <button> but we want it to only
  // overlay the <div>.
  // The only way to do that is either
  // - reimplement ButtonBase
  // - or move the ripple span inside the <div> after render, and move it back inside <button> before
  //   the next render.
  // ==================================================================

  componentDidMount() {
    const button: HTMLElement = this.props.forwardedRef.current;
    if (button == null) {
      return;
    }

    this.obs = new MutationObserver(() => {
      if (button.children[1] && button.children[1].classList.contains('MuiTouchRipple-root')) {
        this.ripple = button.children[1];
        button.children[0].append(this.ripple);
      }
    });

    this.obs.observe(button, { childList: true });
  }

  UNSAFE_componentWillUpdate() {
    this.obs.disconnect();

    const button: HTMLElement = this.props.forwardedRef.current;
    if (button == null) {
      return;
    }

    if (this.ripple) {
      button.append(this.ripple);
      this.ripple = null;
    }
  }

  componentDidUpdate() {
    const button: HTMLElement = this.props.forwardedRef.current;
    if (button == null) {
      return;
    }

    if (button.children[1] && button.children[1].classList.contains('MuiTouchRipple-root')) {
      this.ripple = button.children[1];
      button.children[0].append(this.ripple);
    }

    this.obs.observe(button, { childList: true });
  }

  componentWillUnmount() {
    this.obs.disconnect();
  }

  // ==================================================================
  // end of the massive hack
  // ==================================================================

  render() {
    const {
      scheme,
      variant,
      size,
      component,
      floating,
      className,
      children,
      disabled,
      icon: Icon,
      loading,
      ...passDown
    } = this.props;

    delete passDown.forwardedRef;

    const classes = classnames(
      className,
      css.button,
      css[scheme],
      css[variant],
      css[size],
      !children && css.noChildren,
      Icon && css.hasIcon,
      floating && css.floating,
      disabled && css.disabled,
      loading && css.loading,
    );

    const type = this.props.type ?? (component === 'button' ? 'button' : undefined);

    const ariaDisabled = Boolean(loading) || Boolean(disabled);
    const loadingMessage = !loading ? ''
      : loading === true ? 'Loading, wait.'
      : loading;

    const isRound = Icon && !children;
    const sizeCat = isRound ? loadingSizes.circle : loadingSizes.rect;
    const loadingIconSize = sizeCat[size] ?? 32;

    return (
      <ButtonBase
        {...passDown}
        aria-disabled={String(ariaDisabled)}
        component={component}
        type={type}
        className={classes}
        focusRipple
        ref={this.props.forwardedRef}
        disableRipple={ariaDisabled}
      >
        <span className={css.visualButton}>
          {children}

          {/* "loading" message read by screen readers */}
          <span aria-live="assertive" className="visually-hidden">
            {loadingMessage}
          </span>

          {Icon && (
            <Icon className={css.icon} />
          )}

          {loading && (
            <span className={css.loadingIndicator}>
              <Loading inheritColor size={loadingIconSize} />
            </span>
          )}
        </span>
        {disabled ? (
          <span className={css.popper} aria-hidden="true">
            <span>{disabled}</span>
          </span>
        ) : null}
      </ButtonBase>
    );
  }
}

const InternalButton = forwardRefEnsure<HTMLButtonElement, Omit<InternalProps, 'forwardedRef'>>((props, ref) => {
  return <InternalButtonWithRef {...props} forwardedRef={ref} />;
});

type Props = Omit<InternalProps, 'disabled' | 'forwardedRef'> & {
  disabled?: boolean | string,
};

const defaultMessages = defineMessages({
  disabled: { defaultMessage: 'This action is disabled.' },
  loading: {
    defaultMessage: 'Loading, wait.',
    description: `This message is read by screen readers when a user presses a button that causes a loading indicator to appear.`,
  },
});

const Button2 = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {

  // This component is a wrapper around InternalButton that adds
  // MyMedicoach-specific features (that depend on intl and the Snackbar system)

  const { disabled, loading, onClick, ...passDown } = props;

  const addSnack = useSnackbar();
  const intl = useIntl();

  const disabledMsg = typeof disabled === 'string' ? disabled
    : disabled ? intl.formatMessage(defaultMessages.disabled)
    : '';

  const disableableOnClick = useCallback(e => {
    if (disabledMsg) {
      addSnack(disabledMsg, { type: 'warn' });
    }

    if (e.currentTarget.getAttribute('aria-disabled') === 'true') {
      e.preventDefault();

      return;
    }

    if (onClick) {
      onClick(e);
    }
  }, [disabledMsg, onClick, addSnack]);

  return (
    <InternalButton
      {...passDown}
      onClick={disableableOnClick}
      disabled={disabledMsg}
      loading={loading === true ? intl.formatMessage(defaultMessages.loading) : loading}
      ref={ref}
    />
  );
});

export default Button2;
