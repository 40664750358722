import * as React from 'react';
import { useBadgeCounts } from '../api/conversations';
import { ONE_SECOND_MS } from '../utils/dates';
import { createTrappedCallable } from '../utils/dev-utils';
import { useAuthToken } from './viewer-context';

export type INotificationContext = {
  unreadConversations: number,
  forceRefresh(): void,
  refreshMsgCount(repeat: boolean): (() => void),
};

export const NotificationContext = React.createContext<INotificationContext>(createTrappedCallable());

export function useLiveNotificationCount(): INotificationContext {
  const notificationContext: INotificationContext = React.useContext(NotificationContext);

  React.useEffect(() => {
    return notificationContext.refreshMsgCount(true);
  }, [notificationContext, notificationContext.refreshMsgCount]);

  return notificationContext;
}

type Props = {
  children: React.ReactNode,
};

// refresh every 15 seconds
const REFRESH_INTERVAL = 15 * ONE_SECOND_MS;

export function NotificationProvider(props: Props) {

  const viewerToken = useAuthToken();
  const [refreshRequests, setRefreshRequests] = React.useState([]);
  const refreshRequestsLive = React.useRef(refreshRequests);

  // refreshRequests contain all requests for refresh made by children
  // one component could request a refresh every 10 seconds,
  // another could request a refresh every 4 seconds,
  // one could request a single refresh (0 seconds)
  // if all entries in refreshRequests are undefined -> disable
  // if all non-undefined entries are 0 -> load without refresh
  // else load with smallest refresh interval
  let theInterval = Number.POSITIVE_INFINITY;
  let disabled = true;
  for (const anInterval of refreshRequests) {
    if (anInterval == null) {
      continue;
    }

    disabled = false;
    if (anInterval < theInterval) {
      theInterval = anInterval;
    }
  }

  const options = {
    disabled: viewerToken == null || disabled,
    refreshInterval: theInterval === Number.POSITIVE_INFINITY ? 0 : theInterval,
  };

  // TODO split patient / therapist sides?
  const badgesResponse = useBadgeCounts(viewerToken, options);
  const unreadConversations = badgesResponse.data?.unreadConversations ?? 0;

  const revalidateBadges = badgesResponse.revalidate;
  const forceRefresh = React.useCallback(() => {
    if (disabled) {
      return;
    }

    void revalidateBadges();
  }, [disabled, revalidateBadges]);

  const refreshMsgCount = React.useCallback((refresh: boolean) => {
    let availableIndex;
    // eslint-disable-next-line no-constant-condition
    for (let i = 0; true; i++) {
      if (refreshRequestsLive.current[i] == null) {
        availableIndex = i;
        break;
      }
    }

    {
      const clone = [...refreshRequestsLive.current];

      // currently interval is not configurable
      // but you can just change this number
      clone[availableIndex] = refresh ? REFRESH_INTERVAL : Number.POSITIVE_INFINITY;
      setRefreshRequests(clone);
    }

    return () => {
      const clone = [...refreshRequestsLive.current];
      clone[availableIndex] = void 0;
      setRefreshRequests(clone);
    };
  }, []);

  const context = React.useMemo(() => ({
    unreadConversations,
    forceRefresh,
    refreshMsgCount,
  }), [forceRefresh, unreadConversations, refreshMsgCount]);

  return (
    <NotificationContext.Provider value={context}>
      {props.children}
    </NotificationContext.Provider>
  );
}
