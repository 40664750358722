import loadable from '@loadable/component';
import type { match as Match } from 'react-router-dom';
import FullPageLoading from '../../components/Loading/FullPageLoading';

export function buildQuestionStatsPathname(match: Match) {
  return `${match.path}/questionnaires`;
}

export const PATIENT_QUESTION_STATS_PATHNAME = '/stats/questionnaires';

export const QuestionStatsLoadable = loadable(async () => import('./question-stats.view'), {
  fallback: <FullPageLoading />,
});
