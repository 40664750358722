import { installDialogPolyfill } from './polyfills/dialog';
import { getRaven } from './services/error-reporting';
import { getPlatform } from './services/platform';
import { hookPwa } from './services/pwa';

// get raven to init it
getRaven();

if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ platform: getPlatform() });
}

if (typeof window !== 'undefined' && window.__cordova_www_path__ != null) {
  // eslint-disable-next-line no-undef,camelcase
  __webpack_public_path__ = window.__cordova_www_path__;
}

// prevent "install app" prompt, display a menu option instead
// Better play it safe so browsers don't block us.
hookPwa();

if (typeof document !== 'undefined' && document.body) {
  document.body.classList.add('js');
}

export default function preInit() {

  const promises = [
    installDialogPolyfill(),
  ];

  if (typeof Object.values !== 'function') {
    promises.push(import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.object.values'));
  }

  if (typeof Object.entries !== 'function') {
    promises.push(import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.object.entries'));
  }

  if (typeof String.prototype.padStart === 'undefined') {
    promises.push(
      import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.string.pad-start'),
      import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.string.pad-end'),
    );
  }

  if (typeof Array.prototype.flat === 'undefined') {
    promises.push(import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.array.flat'));
  }

  if (typeof Array.prototype.flatMap === 'undefined') {
    promises.push(import(/* webpackChunkName: "old-browsers-polyfill" */ 'core-js/modules/es.array.flat-map'));
  }

  if (typeof AbortController === 'undefined') {
    promises.push(import(/* webpackChunkName: "old-browsers-polyfill" */ 'abortcontroller-polyfill/dist/polyfill-patch-fetch'));
  }

  if (typeof document !== 'undefined' && !supportsPseudoClass(':focus-visible')) {
    promises.push(import('focus-visible'));
  }

  if (typeof globalThis.screen !== 'undefined' && globalThis.screen.orientation?.lock) {
    try {
      globalThis.screen.orientation.lock('portrait')
        .catch(console.error);
    } catch (error) {
      console.error(error);
    }
  }

  return Promise.all(promises);
}

function supportsPseudoClass(pseudo) {
  const docHead = document.head;
  if (!docHead) {
    return false;
  }

  const style = document.createElement('style');
  docHead.append(style);
  const sheet = style.sheet;

  if (!(sheet instanceof CSSStyleSheet)) {
    return false;
  }

  try {
    sheet.insertRule(`${pseudo}{}`, 0);
    sheet.deleteRule(0);

    return true;
  } catch {
    return false;
  } finally {
    style.remove();
  }
}
