import { useAuthToken } from '../components/viewer-context';
import type { Nullish } from '../shared-library/types';
import { PATIENT_PLANNING_PATHNAME } from '../views/patient-planning/patient-planning.loadable';
import { THERAPIST_PLANNING_PATHNAME } from '../views/therapist-planning/therapist-planning.path';
import type { TUser, TViewerFragment } from './graphql-typings';
import type { TOrganisation } from './organisations';
import { update } from './swr/mutation';
import type { SwrOptions, SwrRes } from './swr/swr';
import { useQuery } from './swr/swr';
import { getAuthToken } from './token';
import { buildApiEndpoint, callApi, getDataOrThrow } from './util';

export enum UserRoles {
  Patient = 'PATIENT',
  Therapist = 'THERAPIST',
  Admin = 'ADMIN',
}

Object.freeze(UserRoles);

export type TRestUser = {
  id: string,
  avatar: string,
  slug: string,
  email: string | null,
  joined: boolean,
  firstName: string,
  lastName: string,
  isoLanguage: string | null,

  roles: UserRoles[],
  verified: boolean,

  // private fields
  createdAt?: string,
  birthday?: string | null,
  isoCountry?: string | null,
  stripeConnected?: boolean,
  displayPainIndicator?: boolean,

  organisations: Array<{
    role: 'ADMIN' | 'PATIENT',
    organisation: TOrganisation,
  }>,
};

export async function isEmailInUse(email: string): Promise<boolean> {
  return getDataOrThrow(callApi.post('/users/email', {
    body: { email },
  }));
}

export async function changePassword(
  user: UserIdentifier,
  newPassword: string,
  oldPassword: Nullish<string>,
  temporaryToken: Nullish<string>,
): Promise<TRestUser> {
  return getDataOrThrow(callApi.put(`/users/${normalizeUserId(user)}/password`, {
    body: {
      newPassword,
      oldPassword: oldPassword || void 0,
      temporaryResetToken: temporaryToken || void 0,
    },
  }));
}

export function useUserSearch(s: string, options) {
  return useQuery({
    key: buildApiEndpoint('/users', { query: { s } }),
    options,
  });
}

export type RegisterUserInput = {
  email: string,
  password: string,
  firstName: string,
  isoLanguage: string,
  isoCountry: string | null,
  isHealthCareProvider: boolean,
  timeZone: string,
};

export async function register(body: RegisterUserInput): Promise<TRestUser | null> {
  return getDataOrThrow(callApi.post('/users', { body }));
}

export async function login(email: string, password: string): Promise<TRestUser | null> {
  return getDataOrThrow(callApi.post('/users/authenticate', {
    body: { email, password },
  }));
}

export async function requestMagicEmail(email: string): Promise<void> {
  await getDataOrThrow(callApi.post('/users/magic-email', {
    body: { email },
  }));
}

export async function authenticateWithMagicEmailToken(token: string): Promise<TRestUser> {
  return getDataOrThrow(callApi.post('/users/magic-email-authenticate', {
    body: { token },
  }));
}

function getUserEndpoint(userId: UserIdentifier) {
  return `/users/${normalizeUserId(userId)}`;
}

export function useUser(id: UserIdentifier, options?: SwrOptions<TRestUser>): SwrRes<TRestUser> {
  const token = useAuthToken();

  return useQuery({
    key: getUserCacheKey(id, token),
    options,
  });
}

export function getUserCacheKey(id: UserIdentifier, token: string | null) {
  id = normalizeUserId(id);

  return (id === 'me' || id === 'viewer') && !token ? null : [getUserEndpoint(id), token || ''];
}

// TODO set data typings
export async function updateUser(userId: string, data: { [key: string]: string }): Promise<TRestUser> {
  // TODO optimistic update

  const updatedUser = await getDataOrThrow(callApi.put(`/users/${userId}`, {
    body: data,
  }));

  update(cache => {
    const cacheKey = getUserCacheKey(userId, getAuthToken());
    const user = cache.getData(cacheKey);

    cache.mutate(cacheKey, {
      ...user,
      ...updatedUser,
    }, false);
  });

  return updatedUser;
}

type TIsOfRoleUser = TRestUser | { roles: TUser['roles'] };

export function isAdmin(user: Nullish<TIsOfRoleUser>): boolean {
  return user != null && isOfRole(user, UserRoles.Admin);
}

export function isTherapist(user: Nullish<TIsOfRoleUser>): boolean {
  if (!user) {
    return false;
  }

  return isOfRole(user, UserRoles.Therapist);
}

export function isOfRole(user: TIsOfRoleUser | null, role: UserRoles): boolean {
  if (!user) {
    return false;
  }

  return user.roles.includes(role);
}

export function identifierMatchesUser(user: TRestUser, identifier: string): boolean {
  return user.id === identifier || user.slug === identifier;
}

export type TUserIdentifiersSubset = {
  id: TUser['id'],
  slug: TUser['slug'],
};

export function getUserIdentifiers(user: TUserIdentifiersSubset): string[] {
  const out: string[] = [];

  if (user.slug) {
    out.push(user.slug);
  }

  out.push(user.id);

  return out;
}

export function getUserIdentifier(user: TRestUser | { slug: TUser['slug'], id: TUser['id'] }): string {
  if (user.slug) {
    return user.slug;
  }

  return user.id;
}

export type UserIdentifier = TRestUser | string;

export function normalizeUserId(user: UserIdentifier): string {
  if (typeof user === 'object') {
    return user.id;
  }

  if (user === 'me') {
    return 'viewer';
  }

  return user;
}

export function getPreferredDashboard(user: TRestUser | TViewerFragment) {
  if (isTherapist(user)) {
    return THERAPIST_PLANNING_PATHNAME;
  }

  return PATIENT_PLANNING_PATHNAME;
}
