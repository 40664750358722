import type { ReactNode } from 'react';
import type { IntlShape, MessageDescriptor } from 'react-intl';
import { toArray } from '../shared-library/array-utils';
import { isMessageDescriptor } from './react-utils';

export type TMatchLocaleArgs = {
  requested: string | string[],
  available: string | string[],
  default: string,
};

// rudimentary algorithm, hopefully https://github.com/longlho/proposal-intl-localematcher arrives
export function matchLocale(options: TMatchLocaleArgs) {
  const requestedLocales = toArray(options.requested);
  const availableLocales = toArray(options.available);

  for (const available of availableLocales) {
    const lang = getLocaleLanguage(available);

    for (const requested of requestedLocales) {
      if (available === requested || lang === requested) {
        return requested;
      }
    }
  }

  return options.default;
}

export function getLocaleLanguage(locale: string): string {
  return locale.split('-')[0];
}

export function formatList(intl: IntlShape, items: string[]): string {
  if (!items) {
    return '';
  }

  return intl.formatList(items);
}

export function formatMessageOrString(intl: IntlShape, message: MessageDescriptor | ReactNode) {
  if (isMessageDescriptor(message)) {
    return intl.formatMessage(message);
  }

  return message;
}
