import * as React from 'react';
import { createContext, useContext } from 'react';
import { CommonLayout } from '../span-header';
import css from './styles.module.scss';

const IsMultiPageViewContext = createContext<boolean>(false);

export function useIsMultiPageView(): boolean {
  return useContext(IsMultiPageViewContext);
}

const MultiPageView = React.forwardRef<HTMLDivElement, React.ComponentProps<'div'>>((props, ref) => {

  return (
    <IsMultiPageViewContext.Provider value>
      <CommonLayout>
        <div {...props} className={`${css.mpl} ${props.className}`} ref={ref} />
      </CommonLayout>
    </IsMultiPageViewContext.Provider>
  );
});

export function MultiPageIndex(props: { children: React.ReactNode }) {

  return (
    <div className={css.index}>
      {props.children}
    </div>
  );
}

export function MultiPageMain(props: { children: React.ReactNode }) {

  return (
    <div className={css.main}>
      {props.children}
    </div>
  );
}

export default MultiPageView;
