import * as React from 'react';
import { useHistory } from 'react-router-dom';

// @ts-expect-error
window.dataLayer = window.dataLayer || [];

export default function GoogleTagManager(props: { children: React.ReactNode }) {
  const history = useHistory();

  React.useEffect(() => {
    getDataLayer().push({
      event: 'virtual-pageview',
      path: history.location.pathname,
    });

    return history.listen(location => {
      getDataLayer().push({
        event: 'virtual-pageview',
        path: location.pathname,
      });
    });
  }, [history]);

  return props.children;
}

function getDataLayer(): object[] {
  // @ts-expect-error
  return window.dataLayer;
}
