import { useEffect } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { defineMessages, FormattedMessage } from 'react-intl';
import {
  getErrorCode,
  InternalServerError,
  InternalServerErrorSymbol,
  ServerUnreachableSymbol,
} from '../../api/util';
import ErrorMessage from '../ErrorMessage';
import css from './style.module.scss';

const msgs = defineMessages({
  [ServerUnreachableSymbol]: { defaultMessage: 'Our servers could not be reached, please try again in a minute.' },
  [InternalServerErrorSymbol]: {
    defaultMessage: 'Our servers encountered an error. Please reach out to our support for assistance <err>(Error {reportId})</err>.',
    description: `
Variables:
  <err>: adds styling around the error ID.
`,
  },
});

type Props = {
  error: any,
  messages?: { [key: string | symbol]: MessageDescriptor },
};

/**
 * This component formats common API errors.
 */
export default function ApiErrorMessage(props: Props) {
  const { error, messages } = props;

  const code = getErrorCode(error);
  const isUnreachable = code === ServerUnreachableSymbol;

  // these are messages provided by parent component.
  const isKnownError = code && messages && messages[code];

  useEffect(() => {
    if (!error || isUnreachable || isKnownError) {
      return;
    }

    // TODO: report to sentry if it's unexpected error and client-side
    console.error('Unexpected Error Details:');
    console.error(error);
    console.error('Code:', code);
  }, [error, code, isUnreachable, isKnownError]);

  if (error instanceof InternalServerError) {
    return (
      <FormattedMessage
        {...msgs[InternalServerErrorSymbol]}
        values={{
          reportId: error.reportId,
          err: val => <span className={css.errorId}>{val}</span>,
        }}
      />
    );
  }

  const message = code ? (messages?.[code] ?? msgs[code]) : null;
  if (message) {
    return <ErrorMessage {...message} />;
  }

  return <ErrorMessage />;
}
