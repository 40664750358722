import loadable from '@loadable/component';
import FullPageLoading from '../../components/Loading/FullPageLoading';

export const PATIENT_PLANNING_PATHNAME = '/p';

export const PatientPlanningLoadable = loadable(async () => import('./patient-planning.view'), {
  fallback: <FullPageLoading />,
});

export const PatientPlanningOnboardingLoadable = loadable(async () => import('./patient-planning.onboarding'));
