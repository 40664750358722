import { createListener } from '../utils/utils';

const TOKEN_KEY = 'auth-token';
const hasLocalStorage = typeof localStorage !== 'undefined';

const { on: onTokenChange, off: offTokenChange, dispatch: dispatchTokenChange } = createListener<[string, string]>();

function setAuthToken(token: string | null): boolean {
  if (!hasLocalStorage) {
    return false;
  }

  const oldToken = getAuthToken();
  if (token === oldToken) {
    return false;
  }

  if (!token) {
    localStorage.removeItem(TOKEN_KEY);
  } else {
    localStorage.setItem(TOKEN_KEY, token);
  }

  dispatchTokenChange(token, oldToken);

  return true;
}

function getAuthToken(): string | null {
  if (!hasLocalStorage) {
    return null;
  }

  return localStorage.getItem(TOKEN_KEY);
}

function hasAuthToken(): boolean {
  return getAuthToken() != null;
}

export {
  onTokenChange,
  offTokenChange,
  setAuthToken,
  getAuthToken,
  hasAuthToken,
};
