import { THERAPIST_ROUTES_PREFIX } from '../therapist-planning/therapist-planning.path';

export const PATIENT_SETTINGS_PATHNAME = '/settings';
export const THERAPIST_SETTINGS_PATHNAME = THERAPIST_ROUTES_PREFIX + PATIENT_SETTINGS_PATHNAME;
export const GENERIC_PASSWORD_RESET_PATHNAME = `/password-reset`;
export const PATIENT_PASSWORD_RESET_PATHNAME = `/settings/password-reset`;
export const THERAPIST_PASSWORD_RESET_PATHNAME = THERAPIST_ROUTES_PREFIX + PATIENT_PASSWORD_RESET_PATHNAME;
export const NOTIFICATION_SETTINGS_PATHNAME = `/settings/notifications`;
export const THERAPIST_NOTIFICATION_SETTINGS_PATHNAME = THERAPIST_ROUTES_PREFIX + NOTIFICATION_SETTINGS_PATHNAME;
export const ACTIVATION_CODES_PATHNAME = `/settings/codes`;
export const THERAPIST_ORGANISATION_LIST_PATH = `${THERAPIST_ROUTES_PREFIX}/settings/organisations`;
