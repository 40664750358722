import classNames from 'classnames';
import type { ComponentProps, ReactNode, RefObject } from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';
import { ExerciseCategory } from '../../../shared-library/exercise-categories';
import { isAppleOs, onEvent } from '../../../utils/dom-utils';
import { isMessageDescriptor } from '../../../utils/react-utils';
import DesktopContainer from '../../DesktopContainer';
import Header from '../../Header';
import { WeightedHelmet } from '../../weighted-helmet/weighted-helmet';
import { useIsMultiPageView } from '../MultiPageView';
import { BodyColor, getSectionClasses } from '../body-color.js';
import { PageActionContext, PageActionProvider } from '../page-actions.js';
import css from './styles.module.scss';

type Props = {
  children: ReactNode,
  className?: string,
  variant?: ComponentProps<typeof BodyColor>['background'],
  theme?: ComponentProps<typeof BodyColor>['theme'],
  weight?: PageWeight,
  backPath?: null | string | (() => void),
  notched?: boolean,
  classes?: {
    page?: string,
    content?: string,
  },
  subtitle?: string | null,
} & (
  {
    title: MessageDescriptor | string,
    /** Used for <title>, will fallback to $title if not provided. */
    htmlTitle?: MessageDescriptor | string | null,
  } | {
    title: ReactNode,
    /** Used for <title>. Required since $title is not a string */
    htmlTitle: MessageDescriptor | string,
  }
);

export enum PageWeight {
  INDEX = 1,
  MAIN = 100,
}

function BaseAppPage(props: Props) {
  const intl = useIntl();
  const pageActionCtx = useContext(PageActionContext);

  // distinguish two layouts:
  //  - standalone page (= !fullWidth): a single page is displayed and the scrollbar should be on the wrapper above <header>+<main>.
  //     This layout is constrained horizontally by <AppPage>
  //  - multi-page layout (= fullWidth): multiple pages are displayed, there is more than one scrollbar (one on every <main>)
  //     This layout is constrained horizontally by <MultiPageView>

  // TODO: rename fullWidth -> "isMultiPageView"
  const isMultiPageView = useIsMultiPageView();

  const ContentContainer = isMultiPageView ? 'div' : DesktopContainer;

  const title: string | ReactNode = isMessageDescriptor(props.title) ? intl.formatMessage(props.title) : props.title;
  const htmlTitleRaw = props.htmlTitle || (typeof title === 'string' ? title : '');
  const htmlTitle = isMessageDescriptor(htmlTitleRaw) ? intl.formatMessage(htmlTitleRaw) : htmlTitleRaw;

  const scrollableMainRef = useRef<HTMLDivElement | null>(null);
  const isScrolled = useIsVerticallyScrolled(scrollableMainRef);

  const weight = props.weight ?? PageWeight.INDEX;

  return (
    <div
      className={classNames(
        css.appPage,
        props.classes?.page,
        getSectionClasses(
          props.variant,
          isMultiPageView ? null : (props.theme ?? ExerciseCategory.physiotherapy),
        ),
        isMultiPageView ? css.fullWidth : css.notFullWidth,
      )}
      ref={isMultiPageView ? undefined : scrollableMainRef}
    >
      <BodyColor theme={props.theme} background={props.variant} weight={weight} />
      <WeightedHelmet weight={weight} title={htmlTitle} />
      <Header
        className={classNames(css.header, isScrolled && css.scrolled)}
        backPath={props.backPath}
        title={title}
        subtitle={props.subtitle}
        actions={pageActionCtx.currentActions}
        fullWidth={isMultiPageView}
        appleOs={isAppleOs()}
      />
      <div className={classNames(props.className, css.main)} ref={isMultiPageView ? scrollableMainRef : undefined}>
        <ContentContainer className={classNames(css.contentContainer, props.classes?.content)}>
          {props.children}
        </ContentContainer>

        {props.notched && <div className={css.notchSpacer} />}
      </div>
    </div>
  );
}

export default function AppPage(props: Props) {

  return (
    <PageActionProvider>
      <BaseAppPage {...props} />
    </PageActionProvider>
  );
}

function useIsVerticallyScrolled(ref: RefObject<HTMLElement>) {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const mainElem = ref.current;

    if (mainElem == null) {
      return;
    }

    const updateScroll = () => {
      setIsScrolled(mainElem.scrollTop > 0);
    };

    updateScroll();

    // eslint-disable-next-line consistent-return
    return onEvent(mainElem, 'scroll', updateScroll);
  }, [ref]);

  return isScrolled;
}
