import type { MutableRefObject, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

type TRefPortalProps = {
  children: ReactNode,
  containerRef: MutableRefObject<Element | null>,
};

export function RefPortal(props: TRefPortalProps) {
  const { children, containerRef } = props;

  const container = containerRef.current;
  const [, setReady] = useState(false);

  useEffect(() => {
    setReady(containerRef.current != null);
  });

  if (!container) {
    return null;
  }

  return createPortal(children, container);
}
