/**
 * @returns {"android" | "ios" | "web"} the ID of the platform this code is running on.
 */
export function getPlatform(): 'android' | 'ios' | 'web' {
  // @ts-expect-error
  if (typeof cordova !== 'undefined' && cordova.platformId) {
    // @ts-expect-error
    return cordova.platformId;
  }

  return 'web';
}

/**
 * Cordova Hook to get actual, stable device ID.
 *
 * Returns null on web.
 */
export function getCordovaDeviceId() {
  // TODO: hook deviceready for cordova
  // @ts-expect-error
  if (typeof device !== 'undefined' && device.uuid) {
    // @ts-expect-error
    return device.uuid;
  }

  return null;
}
