import type { categoryClasses } from '../../CategoryColors';
import { getSectionClasses } from '../../Layout/body-color.js';
import SiteLogo from '../../SiteLogo';
import DotLoading from '../DotLoading';
import css from './styles.module.scss';

type Props = {
  background?: 'white' | 'color-light' | 'color-dark',
  theme?: keyof typeof categoryClasses,
};

export default function FullPageLoading(props: Props) {
  const { background = 'white', theme = 'physiotherapy' } = props;

  const logoStyle = background === 'white' ? 'color'
    : background === 'color-light' ? 'black'
    : 'white';

  return (
    <div className={`${css.fullpageLoading} ${getSectionClasses(background, theme)}`}>
      <SiteLogo style={logoStyle} className={css.logo} />
      <DotLoading />
    </div>
  );
}

FullPageLoading.defaultProps = {
  color: 'white',
};
