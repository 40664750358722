import IconButton from '@material-ui/core/IconButton';
import classnames from 'classnames';
import bind from 'lodash-decorators/bind';
import flowRight from 'lodash/flowRight';
import * as React from 'react';
import type { IntlShape } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { getScrollbarWidth, isIos } from '../../utils/dom-utils';
import { isMessageDescriptor } from '../../utils/react-utils';
import type { RouterHistory, RoutingLocation } from '../../utils/types';
import { PATIENT_PLANNING_PATHNAME } from '../../views/patient-planning/patient-planning.loadable';
import { isTherapistRouteActive, THERAPIST_PLANNING_PATHNAME } from '../../views/therapist-planning/therapist-planning.path';
import type { Action } from '../ActionMenu';
import { BackButton } from '../BackButton';
import DesktopContainer from '../DesktopContainer';
import MoreMenu from '../MoreMenu';
import SiteLogo from '../SiteLogo';
import styles from './styles.module.scss';

export type HeaderAction = Action;

type Props = {
  title: React.ReactNode | string | null,
  subtitle?: string | null,
  actions: HeaderAction[],
  className?: string,

  // either no backPath, or a clickable destination, or a function to call
  backPath: null | string | (() => void),

  appleOs: boolean,

  intl: IntlShape,
  history: RouterHistory,
  location: RoutingLocation,
  fullWidth?: boolean,
};

type State = {
  backHovered: boolean,
};

class Header extends React.Component<Props, State> {

  state = {
    backHovered: false,
  };

  @bind()
  onBackHover() {
    // WORKAROUND: Either iOS is firing mouseenter events, or
    //  react is firing mouseenter events from mouseover on iOS.
    //  either way - this back hovered sometimes (inconsistent) breaks the back button on iOS so I have to disable it
    if (isIos()) {
      return;
    }

    this.setState({ backHovered: true });
  }

  @bind()
  onBackUnhover() {
    this.setState({ backHovered: false });
  }

  renderBackButton() {

    if (this.props.backPath === null) {
      return null;
    }

    const defaultBackPath = this.props.backPath
      || (isTherapistRouteActive(this.props.location) ? THERAPIST_PLANNING_PATHNAME : PATIENT_PLANNING_PATHNAME);

    return (
      <BackButton
        defaultBackPath={defaultBackPath}
        className={styles.leftButton}
        onMouseEnter={this.onBackHover}
        onMouseLeave={this.onBackUnhover}
      />
    );
  }

  renderSingleAction(action): React.ReactNode {

    if (React.isValidElement(action)) {

      return (
        <div className={styles.rightButton} data-key="main-header-action">
          {action}
        </div>
      );
    }

    const { href, icon, title, ...passDown } = action;

    const extraProps = href ? {
      component: Link,
      to: href,
    } : null;

    const formattedTitle = isMessageDescriptor(title) ? this.props.intl.formatMessage(title) : title;
    let formattedIcon = icon;
    if (icon != null && !React.isValidElement(icon)) {
      const IconComp = icon;
      formattedIcon = <IconComp />;
    }

    return (
      <div className={styles.rightButton} data-key="main-header-action">
        <IconButton {...passDown} {...extraProps} color="inherit" title={formattedTitle}>
          {formattedIcon}
        </IconButton>
      </div>
    );
  }

  renderRightAction(): React.ReactNode {

    const actions = this.props.actions;
    if (!actions || actions.length === 0) {
      return null;
    }

    const [firstAction, ...otherActions] = actions;

    return (
      <div className={styles.headerActions}>
        {firstAction && this.renderSingleAction(firstAction)}
        {otherActions.length === 0 ? null
        : otherActions.length === 1 ? this.renderSingleAction(otherActions[0])
        : <MoreMenu actions={otherActions} />}
      </div>
    );
  }

  render() {
    const ContentWrapper = this.props.fullWidth ? 'div' : DesktopContainer;

    return (
      <header
        className={classnames(
          this.props.className,
          getScrollbarWidth() === 0 ? styles.overlayScrollbar : '',
          styles.header,
          this.state.backHovered ? styles.backButtonHovered : '',
          this.props.title ? styles.hasTitle : '',
          this.props.backPath !== null ? styles.hasBackButton : '',
          this.props.appleOs ? styles.ios : '',
        )}
        style={{
          '--action-count': Math.min(this.props.actions?.length ?? 0, 2),
        }}
      >
        <ContentWrapper className={styles.headerContent}>
          {this.renderBackButton()}

          {this.props.title
            ? (
              <div className={classnames(styles.titleWrapper, this.props.appleOs && styles.ios)}>
                <h1 className={styles.title}>
                  <span>{this.props.title}</span>
                  {this.props.subtitle && <small className={styles.subtitle}>{this.props.subtitle}</small>}
                </h1>
              </div>
            ) : <SiteLogo className={styles.logo} style="white" />}

          {this.renderRightAction()}
        </ContentWrapper>
      </header>
    );
  }
}

export default flowRight([
  injectIntl,
  withRouter,
])(Header);
