import { useState, useCallback } from 'react';

export function useFifoQueue<T>(): [T[], (T) => void, () => T] {
  const [items, setItems] = useState([]);

  const push = useCallback(snack => {
    setItems(oldSnacks => [...oldSnacks, snack]);
  }, []);

  const next = useCallback(() => {
    let item;
    setItems(oldSnacks => {
      const newSnacks = [...oldSnacks];
      item = newSnacks.shift();

      return newSnacks;
    });

    return item;
  }, []);

  return [items, push, next];
}
