import { useState, useEffect } from 'react';

// inspired from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/useMediaQuery/useMediaQuery.js (at least a version that works is).

/**
 * React Hook variant of {@see withMediaQueries}.
 * Matches one query at a time.
 *
 * @param {!string} mediaQuery - The media query to †est
 * @returns {!boolean} whether the media query matches.
 */
function useMediaQuery(mediaQuery: string): boolean {

  /* eslint-disable react-hooks/rules-of-hooks */
  if (typeof window === 'undefined' || !window.matchMedia) {
    return false;
  }

  const [matches, setMatches] = useState(() => {
    const queryMatcher = window.matchMedia(mediaQuery);

    return queryMatcher.matches;
  });

  useEffect(() => {
    const listener = e => {
      setMatches(e.matches);
    };

    const queryMatcher = window.matchMedia(mediaQuery);
    queryMatcher.addListener(listener);

    return () => {
      queryMatcher.removeListener(listener);
    };
  }, [mediaQuery]);

  return matches;
}

export default useMediaQuery;

export function WithMediaQuery(props) {
  const mq = useMediaQuery(props.mq);

  return props.children(mq);
}
