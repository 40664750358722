import type * as React from 'react';
import { assert } from '../shared-library/assert';
import { isObject } from '../shared-library/object-utils';
import { getFormElementValue } from './dom-utils';

export function upperFirst(string: string, locales?: string | string[]): string {
  return string.charAt(0).toLocaleUpperCase(locales) + string.slice(1);
}

export function toBase64(data: string): string {
  return btoa(data);
}

export function fromBase64(data: string): string | null {

  try {
    return atob(data);
  } catch {
    return null;
  }
}

export function *mapIterator<I, O>(iterator: Iterable<I>, cb: (val: I) => O): Generator<O> {
  for (const number of iterator) {
    yield cb(number);
  }
}

export function *filterOutIterator<I>(iterator: Iterable<I>, cb: (val: I) => boolean): Generator<I> {
  for (const item of iterator) {
    if (!cb(item)) {
      yield item;
    }
  }
}

export function createListener<Args extends any[]>() {
  type Listener = (...args: Args) => void;

  const listeners: Set<Listener> = new Set();

  function dispatch(...args: Args) {
    for (const listener of listeners) {
      listener(...args);
    }
  }

  function on(cb: Listener) {
    listeners.add(cb);

    return () => off(cb);
  }

  function off(cb: Listener) {
    listeners.delete(cb);
  }

  return { dispatch, on, off };
}

type FormChangeEvents = React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>;

export type GenericChangeEvent<T = any> = FormChangeEvents | { name: string, value: T };

export function getChangeValue<T>(e: GenericChangeEvent<T>): { name: string, value: T } {
  // @ts-expect-error
  if (!e.currentTarget && !e.target) {
    // @ts-expect-error
    return e;
  }

  // @ts-expect-error
  const target = (!e.currentTarget?.name && e.target?.name) ? e.target : e.currentTarget;
  const name = target.name;
  // @ts-expect-error
  const value = getFormElementValue(target) as T;

  return {
    name,
    value,
  };
}

export function createDeferred<T>(): [Promise<T>, (T) => void] {
  let resolve;
  const promise = new Promise<T>(_resolve => {
    resolve = _resolve;
  });

  return [promise, resolve];
}

/**
 * Merges two objects up to a certain depth
 */
export function depthMerge(depth, object, source) {
  const out = { ...object };

  for (const key of Object.keys(source)) {
    if (isObject(out[key]) && isObject(source[key]) && hasOwnProperty(out, key) && depth > 1) {
      out[key] = depthMerge(depth - 1, object[key], source[key]);
    } else {
      out[key] = source[key];
    }
  }

  return out;
}

export function isPromise<T>(val: any): val is Promise<T> {
  return typeof val.then === 'function';
}

export function hasOwnProperty(obj: any, key: PropertyKey) {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

const GOLDEN_RATIO = (1 + Math.sqrt(5)) / 2;

/**
 * Generated a random nice looking color in a CSS HSL format.
 */
export function getRandomPleasantColor(nextFloat: () => number = Math.random, offset: number = 1) {
  let hue = nextFloat() + (GOLDEN_RATIO * (offset / (5 * nextFloat())));
  hue %= 1;

  return `hsl(${hue * 360}, 75%, 77%)`;
}

// https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript
export function seededRandom(str) {
  let h = 1_779_033_703 ^ str.length;
  for (let i = 0; i < str.length; i++) {
    h = Math.imul(h ^ str.codePointAt(i), 3_432_918_353);
    h = h << 13 | h >>> 19;
  }

  function nextInt() {
    h = Math.imul(h ^ h >>> 16, 2_246_822_507);
    h = Math.imul(h ^ h >>> 13, 3_266_489_909);

    return (h ^= h >>> 16) >>> 0;
  }

  return {
    nextInt,
    nextFloat() {
      return nextInt() / 4_294_967_295;
    },
  };
}

export function assertString(val: any): asserts val is string {
  assert(typeof val === 'string', `Expected ${val} to be a string`);
}

export function assertBoolean(val: any): asserts val is boolean {
  assert(typeof val === 'boolean', `Expected ${val} to be a boolean`);
}

export function looksLikeEmail(email: string): boolean {
  const re = /\S+@\S+\.\S+/;

  return re.test(email);
}

export function removeSameValues(input, comparedTo) {
  input = { ...input };
  for (const key of Object.keys(input)) {
    if (input[key] === comparedTo[key]) {
      delete input[key];
    }
  }

  return input;
}

let lastRandomId = 0;
export function randomId(): string {
  if (typeof crypto !== 'undefined' && 'randomUUID' in crypto) {
    return crypto.randomUUID();
  }

  return `${lastRandomId++}`;
}
