export function watchProp({ prop, onChange }) {

  let oldValue = prop();
  onChange(oldValue);

  return {
    check() {
      const newValue = prop();

      if (!Object.is(oldValue, newValue)) {
        oldValue = newValue;
        onChange(newValue, oldValue);
      }
    },
  };
}
